import {
	ROUTE_ERROR,
	ROUTE_FORGOT_PASSWORD,
	ROUTE_HOME,
	ROUTE_LOGIN,
	ROUTE_LOGOUT,
	ROUTE_MESSAGES,
	ROUTE_NEW_PASSWORD,
	ROUTE_NPL,
	ROUTE_VRIENDENLOTERIJ,
	ROUTE_EFTELING,
	ROUTE_REGISTER,
	ROUTE_TIER_OPTIONS,
	ROUTE_TIER_OPTIONS_WINBACK,
	ROUTE_TRIAL_EXPIRED,
	ROUTE_MESSAGES_ERROR_CANCELLATION_PERIOD,
	ROUTE_MESSAGES_ERROR_TERMED,
	ROUTE_MESSAGES_ERROR_SUBSCRIPTION_INCOMPATIBILITY,
	ROUTE_MESSAGES_ERROR_SUBSCRIPTION_PAUSED,
	ROUTE_MESSAGES_ERROR_MULTIPLE_SUBSCRIPTION,
	ROUTE_MESSAGES_ERROR_ALREADY_ACTIVATED,
	ROUTE_MESSAGES_ERROR_ALREADY_TRUSTED_PARTY,
	ROUTE_MESSAGES_ERROR_INVALID_ENTITLEMENT,
	ROUTE_MESSAGES_ERROR_CANNOT_ACTIVATE,
	ROUTE_MESSAGES_ERROR_SUBSCRIPTION_CANCELLED_PARTNER,
	ROUTE_MESSAGES_NEW_PLATFORM,
	ROUTE_EMAIL_VERIFIED,
	ROUTE_PPE,
	ROUTE_PPE_PAID,
	ROUTE_GEO_CHECK,
	ROUTE_MESSAGES_ERROR_BLACK_FRIDAY,
	ROUTE_NPL_SHORT,
	ROUTE_CONTACT,
	ROUTE_COMPANY_INFORMATION,
	ROUTE_MESSAGES_RESUME_PENDING_PAYMENT,
	ROUTE_PAY_BAD_DEBT,
} from './Routes';
import { HOME, TIERS, ONBOARDING_TIER_OPTIONS_META_DESCRIPTION } from './Copy';
import _ from 'lodash';

export const NAVIGATION = [
	{
		title: HOME,
		route: ROUTE_HOME,
		icon: 'home',
		section: 'home',
	},
];

export const SECTION_ONBOARDING = 'onboarding';

const NAVIGATION_PAGES = [
	...NAVIGATION,
	{
		route: ROUTE_REGISTER,
		section: SECTION_ONBOARDING,
	},
	{
		route: ROUTE_LOGIN,
		section: SECTION_ONBOARDING,
	},
	{
		route: ROUTE_MESSAGES,
		section: SECTION_ONBOARDING,
	},
	{
		route: ROUTE_FORGOT_PASSWORD,
		section: SECTION_ONBOARDING,
	},
	{
		route: ROUTE_NEW_PASSWORD,
		section: SECTION_ONBOARDING,
	},
	{
		title: TIERS,
		route: ROUTE_TIER_OPTIONS,
		canonicalUrl: ROUTE_TIER_OPTIONS,
		pageDescription: ONBOARDING_TIER_OPTIONS_META_DESCRIPTION,
	},
	{
		title: TIERS,
		route: ROUTE_TIER_OPTIONS_WINBACK,
		canonicalUrl: ROUTE_TIER_OPTIONS,
		pageDescription: ONBOARDING_TIER_OPTIONS_META_DESCRIPTION,
	},
];

export const PAGES = _.keyBy(NAVIGATION_PAGES, 'route');

export const ALWAYS_AVAILABLE_ROUTES = [
	ROUTE_ERROR,
	ROUTE_LOGOUT,
	ROUTE_CONTACT,
	ROUTE_COMPANY_INFORMATION,
	ROUTE_NPL,
	ROUTE_NPL_SHORT,
	ROUTE_VRIENDENLOTERIJ,
	ROUTE_EFTELING,
	ROUTE_TRIAL_EXPIRED,
	ROUTE_TIER_OPTIONS,
	ROUTE_TIER_OPTIONS_WINBACK,
	ROUTE_EMAIL_VERIFIED,
	ROUTE_MESSAGES_ERROR_CANCELLATION_PERIOD,
	ROUTE_MESSAGES_ERROR_TERMED,
	ROUTE_MESSAGES_ERROR_SUBSCRIPTION_INCOMPATIBILITY,
	ROUTE_MESSAGES_ERROR_SUBSCRIPTION_PAUSED,
	ROUTE_MESSAGES_ERROR_MULTIPLE_SUBSCRIPTION,
	ROUTE_MESSAGES_ERROR_ALREADY_ACTIVATED,
	ROUTE_MESSAGES_ERROR_ALREADY_TRUSTED_PARTY,
	ROUTE_MESSAGES_ERROR_INVALID_ENTITLEMENT,
	ROUTE_MESSAGES_ERROR_CANNOT_ACTIVATE,
	ROUTE_MESSAGES_ERROR_SUBSCRIPTION_CANCELLED_PARTNER,
	ROUTE_MESSAGES_ERROR_BLACK_FRIDAY,
	ROUTE_MESSAGES_NEW_PLATFORM,
	ROUTE_PPE,
	ROUTE_PPE_PAID,
	ROUTE_PAY_BAD_DEBT,
	ROUTE_GEO_CHECK,
];

export const AVAILABLE_ROUTES_WITH_BAD_DEBT = [
	ROUTE_PPE,
	ROUTE_PPE_PAID,
	ROUTE_GEO_CHECK,
	ROUTE_ERROR,
	ROUTE_LOGOUT,
	ROUTE_CONTACT,
	ROUTE_PAY_BAD_DEBT,
	ROUTE_MESSAGES_RESUME_PENDING_PAYMENT,
];
