import { analyticsOnboardingByRoute } from '../helpers/registration';
import {
	initatePageViewTracking,
	addToGlobalContexts,
	removeFromGlobalContext,
} from '../components/analytics/Snowplow';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { ROUTE_ACTIVATE_PLAN, ROUTE_ACTIVATED_PLAN } from '../constants/Routes';

export default function useOnboardingTracking({
	tierId,
	isVoucherValid,
	isOnPollingPath,
	subscriptionNumber,
	voucherCodeQueryParam,
	loadingSubs,
} = {}) {
	const gigyaUser = useSelector((state) => state.authentication.gigyaUser);
	const registerState = useSelector((state) => state.form.register);
	const location = useLocation();

	const isActivateOrFinalize = matchPath(location.pathname, {
		path: [ROUTE_ACTIVATE_PLAN, ROUTE_ACTIVATED_PLAN],
		exact: false,
		strict: false,
	});

	const locationToMap = isActivateOrFinalize?.path ?? location.pathname;
	const userData = registerState?.values ?? gigyaUser?.profile;
	const data = analyticsOnboardingByRoute[locationToMap.replace(/\/+$/, '')];

	useEffect(() => {
		async function onboardingTracking() {
			const { birthdate, birthYear, gender } = userData || {};
			const isActivate = !!matchPath(location.pathname, ROUTE_ACTIVATE_PLAN);

			if (
				!data ||
				loadingSubs ||
				(isActivate && isVoucherValid === false) ||
				isOnPollingPath ||
				(voucherCodeQueryParam && isVoucherValid === null)
			)
				return;

			data.funnelData = {
				...data.funnelData,
				funnelName: 'vl_onboarding',
				...(gender && { gender }),
				...((birthYear || birthdate) && {
					birthYear: birthdate?.substring(0, birthdate.indexOf('-')) || birthYear.toString(),
				}),
				...(tierId && { funnelSubscriptionType: tierId.toLowerCase() }),
			};

			if (isVoucherValid) {
				data.pageName = 'videoland.onboarding.voucher.success';
			}

			addToGlobalContexts({ rtlFunnel: data.funnelData });
			await initatePageViewTracking({
				pageNamePlatform: data.pageName,
				...(matchPath(location.pathname, ROUTE_ACTIVATED_PLAN) && {
					rtlUser: { rtlUserId: gigyaUser.UID },
				}),
				...(subscriptionNumber && {
					rtlPurchase: {
						subscriptionNumber,
					},
				}),
			});
		}

		onboardingTracking();

		return () => {
			removeFromGlobalContext({ rtlFunnel: data?.funnelData });
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, isVoucherValid, loadingSubs]);
}
