import { ROUTE_CONTACT } from '../constants/Routes';
import { NPL } from '../constants/ResellersAndPartners';

export function getCurrrentPlan(state) {
	return state.subscription.tier?.data?.currentPlan;
}
export function hasSubscription(state) {
	return !!(state.subscription.details.data?.status?.toLowerCase() === 'active');
}

export function isAtContactPage(pathname) {
	return pathname.indexOf(ROUTE_CONTACT) === 0;
}

export function hasPendingSuspension(subscriptions) {
	if (!subscriptions) return false;
	return subscriptions.nextStatus?.toLowerCase() === 'suspended';
}

export function hasSuspendedSubscription(subscriptions) {
	if (!subscriptions) return false;
	return subscriptions.status.toLowerCase() === 'suspended';
}

export function isActiveNPLSubscription(subscriptions) {
	if (!subscriptions) return false;
	return subscriptions.reseller === NPL.id && isActiveSubscription(subscriptions);
}

export function isConvertedToTermedUser(subscriptions) {
	if (!subscriptions) return false;
	return hasPendingSuspension(subscriptions) || hasSuspendedSubscription(subscriptions);
}

function isActiveSubscription(subscription) {
	return subscription.status.toLowerCase() === 'active';
}

export function isNotificationActive(state, name) {
	return state.notification.active.includes(name);
}

export function getTransactionState(payload) {
	return {
		voucherRedemptionState: payload?.me?.voucherRedemptionState || {},
		purchaseState: payload?.me?.purchaseState || {},
	};
}

function getSlugFromReturnUrlString(state) {
	if (!state?.returnUrl) return;
	const url = new URL(state.returnUrl);
	const searchParams = new URLSearchParams(url.search);
	return searchParams.get('slug');
}

export function getTransactionPlanSlug(state) {
	return state?.clientData?.slug || getSlugFromReturnUrlString(state);
}
