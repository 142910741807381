import { useIsMounted } from './useIsMounted';

export const usePromiseIfMounted = () => {
	const isMounted = useIsMounted();

	return (promise) => {
		return new Promise((resolve, reject) => {
			promise
				.then((value) => {
					if (isMounted()) {
						resolve(value);
					} else {
						reject({ promiseIsCancelled: true });
					}
				})
				.catch((error) => {
					if (!isMounted()) {
						reject({ promiseIsCancelled: true });
					} else {
						reject(error);
					}
				});
		});
	};
};

export default usePromiseIfMounted;
