export const SITESPECT_EXPERIMENT = {
	VARIANT: 'variant',
	CONTROL: 'control',
};

export const SITESPECT_EXPERIMENT_NAMES = {
	TRIAL_EXPERIMENT: 'trialExperiment',
	CANCELLATION_FORM_EXPERIMENT: 'cancellationFormExperiment',
	CANCELLATION_FORM_EXPERIMENT_PRICE: 'cancellationFormExperimentPrice',
	CHANGE_TIER_EXPERIMENT: 'changeTierExperiment',
};
