import reduce from '../../helpers/redux';
import { NOTIFICATION_SHOW, NOTIFICATION_HIDE } from '../../constants/ActionTypes';

const initialState = {
	active: [],
};

const actionsMap = {
	[NOTIFICATION_SHOW]: (state, { name }) => ({ active: [...state.active, name] }),
	[NOTIFICATION_HIDE]: (state, { name }) => ({ active: state.active.filter((n) => n !== name) }),
};

export default reduce(initialState, actionsMap);
