import React from 'react';
import { getImageUrl } from '../../../../config';

import {
	ONBOARDING_HELP_TELEPHONE,
	ONBOARDING_HELP_TELEPHONE_TIME,
	ONBOARDING_HELP_WHATSAPP,
	ONBOARDING_HELP_WHATSAPP_TIME,
} from '../../../../constants/Copy';

import './HelpList.scss';

const whatsappIcon = getImageUrl('/images/onboarding/whatsapp.svg');
const phoneIcon = getImageUrl('/images/onboarding/smartphone.svg');

export default () => (
	<ul className="help-list">
		<li className="help-list__list-item">
			<figure className="help-list__list-icon">
				<img alt="whatsapp" src={whatsappIcon} />
			</figure>
			<a
				target="_blank"
				id="whatsapp"
				className="help-list__channel-text"
				href="https://wa.me/31614459277"
				rel="noopener"
			>
				{ONBOARDING_HELP_WHATSAPP}
			</a>
			<span className="help-list__time">{ONBOARDING_HELP_WHATSAPP_TIME}</span>
		</li>
		<li className="help-list__list-item">
			<figure className="help-list__list-icon">
				<img alt="phone" src={phoneIcon} />
			</figure>
			<a
				target="_blank"
				id="telefoon"
				className="help-list__channel-text"
				href="tel:0881232456"
				rel="noopener"
			>
				{ONBOARDING_HELP_TELEPHONE}
			</a>
			<span className="help-list__time">{ONBOARDING_HELP_TELEPHONE_TIME}</span>
		</li>
	</ul>
);
