import React, { Component } from 'react';
import { connect } from 'react-redux';
import { analyticsPush } from '../../../actions/applicationActions';
import { openHelp } from '../../../actions/helpActions';
import { ROUTE_REGISTER, ROUTE_LOGIN } from '../../../constants/Routes';
import {
	ONBOARDING_ERROR_TITLE,
	ONBOARDING_ERROR_BUTTON_REGISTER,
	ONBOARDING_ERROR_BUTTON_SIGNIN,
	ONBOARDING_ERROR_HELP_CTA,
} from '../../../constants/Copy';
import { EVT_ERROR_PAGE } from '../../../constants/EventTypes';
import Heading, { HEADING_SIZE } from '../../common/heading/Heading';
import Button, { BUTTON_KIND_SECOND } from '../../common/buttons/onboarding/OnboardingButton';
import './Error.scss';
import { reportCustomError } from '../../../helpers/realUserMonitoring';

@connect(
	(state) => {
		return {
			errorCode: state.authentication.errorCode,
			gigyaErrorCode: state.authentication.gigyaErrorCode,
			loginProvider: state.authentication.loginProvider,
		};
	},
	{ openHelp, analyticsPush }
)
export default class OnboardingError extends Component {
	componentDidMount() {
		const { analyticsPush, errorCode, gigyaErrorCode, loginProvider } = this.props;

		analyticsPush({
			event: EVT_ERROR_PAGE,
			errorCode,
			gigyaErrorCode,
			loginProvider,
		});

		reportCustomError({ name: 'view_error_page', error: errorCode || gigyaErrorCode });
	}

	render() {
		return (
			<div className="onboarding-generic-error onboarding__start">
				<Heading size={HEADING_SIZE.LARGE}>{ONBOARDING_ERROR_TITLE}</Heading>
				<Button
					className="onboarding-generic-error__button"
					kind={BUTTON_KIND_SECOND}
					onClick={() => {
						this.props.history.push(ROUTE_REGISTER);
					}}
				>
					{ONBOARDING_ERROR_BUTTON_REGISTER}
				</Button>
				<Button
					className="onboarding-generic-error__button"
					kind={BUTTON_KIND_SECOND}
					onClick={() => {
						this.props.history.push(ROUTE_LOGIN);
					}}
				>
					{ONBOARDING_ERROR_BUTTON_SIGNIN}
				</Button>
				<div className="onboarding__text onboarding__text--gray onboarding-generic-error__text">
					Of bekijk onze{' '}
					<a
						className="onboarding__link onboarding-generic-error__link"
						tabIndex="0"
						onClick={this.props.openHelp}
					>
						{ONBOARDING_ERROR_HELP_CTA}
					</a>
				</div>
			</div>
		);
	}
}
