// TODO: refactor this to a global Lodash import when tree-shaking is fixed
import _startCase from 'lodash/startCase';
import { EPISODE, EPISODE_SHORT } from '../constants/Copy';

export const trimDescription = (description, maxTextLength, omissionText) => {
	if (!description) {
		return null;
	}
	let trimmedDescription = description.substring(0, maxTextLength);
	if (trimmedDescription.indexOf('.') === -1) {
		return (
			trimmedDescription.substring(0, trimmedDescription.lastIndexOf(' ')) + (omissionText || '')
		);
	} else {
		return trimmedDescription.substring(0, trimmedDescription.lastIndexOf('.') + 1);
	}
};

export const titleWithFallback = (title, position) =>
	title ? `${EPISODE_SHORT} ${position}: ${title}` : `${EPISODE} ${position}`;

export const slugToTitle = (slug) => _startCase(slug);

export const pad = (value) => ('0' + value).substr(-2);

export const template = (template, replacements) => {
	for (let key in replacements) {
		template = template.replace('${' + key + '}', replacements[key]);
	}
	return template;
};
