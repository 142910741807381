import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import usePlan from '../hooks/usePlan';
import useSearchParams from '../hooks/useSearchParams';
import OnboardingContainer from '../components/onboarding/Onboarding.container';
import { ROUTE_NOT_FOUND } from '../constants/Routes';
import { reportCustomError } from '../helpers/realUserMonitoring';
import Layout from '../components/layout/Layout';
import { useVideolandAccountState } from '../hooks/useVideolandAccountState';
import { stateRender } from '../helpers/react';
import { TIER_IDS } from '../constants/Tiers';
import { matchPath } from 'react-router';
import { ROUTE_ACTIVATED_PLAN } from '../constants/Routes';
import { getExperiment } from '../helpers/sitespect';
import { SITESPECT_EXPERIMENT, SITESPECT_EXPERIMENT_NAMES } from '../constants/Sitespect';
import { cleanMalformedQueryString } from '../helpers/url';

const REDIRECT_NOT_FOUND_ERROR_KEY = 'REDIRECT_NOT_FOUND';
const REDIRECT_NOT_FOUND_REASON_MAP = {
	PLAN_ERROR: 'An error occurred while loading the plan',
	PLAN_NOT_FOUND: 'No plan found for this route',
	PLAN_SLUG: 'No plan slug provided',
	FEATURE_FLAG_DISALBED: 'Feature flag %s is disabled',
	NO_ENTITLEMENT_ID: 'No entitlement id provided',
	IS_THIRD_PARTY: 'Third party plans are not accessible outside partner routes',
};

const mapBlackFridayPlanSlug = (planSlug) => {
	switch (planSlug) {
		case 'videoland-basis-2-weken':
			return 'videoland-basis-2-weken-black-friday';
		case 'videoland-basis':
			return 'videoland-basis-black-friday';
		case 'videoland-plus-2-weken':
			return 'videoland-plus-2-weken-black-friday';
		case 'videoland-plus':
			return 'videoland-plus-black-friday';
		default:
			return planSlug;
	}
};

const mapNoPromoAllowed = (planSlug) => {
	switch (planSlug) {
		case 'videoland-premium-2-weken':
			return 'videoland-premium';
		case 'videoland-basis-2-weken':
			return 'videoland-basis';
		case 'videoland-plus-2-weken':
			return 'videoland-plus';
		default:
			return planSlug;
	}
};

export default ({
	component: Component,
	path,
	exact,
	isThirdPartyVendorRoute = false,
	...restProps
}) => {
	const history = useHistory();
	const { getSearchParam } = useSearchParams();
	const { plan: planSlug } = restProps.computedMatch.params;
	const { isBlackFriday } = useVideolandAccountState();
	const trialExperimentValue = getExperiment(SITESPECT_EXPERIMENT_NAMES.TRIAL_EXPERIMENT);
	const isPromoAllowed = trialExperimentValue
		? trialExperimentValue === SITESPECT_EXPERIMENT.CONTROL
		: true;

	const {
		plan,
		isNotFound: planNotFound,
		isLoading: planIsLoading,
		isPristine: planIsPristine,
		isError: planIsError,
	} = usePlan(
		isBlackFriday
			? mapBlackFridayPlanSlug(planSlug)
			: isPromoAllowed
			? planSlug
			: mapNoPromoAllowed(planSlug)
	);

	const entitlementId = cleanMalformedQueryString(getSearchParam('entitlementId'));

	const isFinalizePage = !!matchPath(history.location.pathname, ROUTE_ACTIVATED_PLAN);
	const tvGemistFinalizePage = isFinalizePage && plan.tier === TIER_IDS.FREE;

	useEffect(() => {
		if (redirectToNotFoundWhenTriggered()) {
			history.replace({ pathname: ROUTE_NOT_FOUND });
		}

		function redirectToNotFoundWhenTriggered() {
			let triggerNotFound = false;

			if (!planSlug) {
				triggerNotFound = true;
				reportCustomError({
					name: REDIRECT_NOT_FOUND_ERROR_KEY,
					error: REDIRECT_NOT_FOUND_REASON_MAP.PLAN_SLUG,
				});
			}
			if (planNotFound) {
				triggerNotFound = true;
				reportCustomError({
					name: REDIRECT_NOT_FOUND_ERROR_KEY,
					error: REDIRECT_NOT_FOUND_REASON_MAP.PLAN_NOT_FOUND,
				});
			}
			if (planIsError) {
				triggerNotFound = true;
				reportCustomError({
					name: REDIRECT_NOT_FOUND_ERROR_KEY,
					error: REDIRECT_NOT_FOUND_REASON_MAP.PLAN_ERROR,
				});
			}

			if (isThirdPartyVendorRoute) {
				if (!entitlementId) {
					triggerNotFound = true;
					reportCustomError({
						name: REDIRECT_NOT_FOUND_ERROR_KEY,
						error: REDIRECT_NOT_FOUND_REASON_MAP.NO_ENTITLEMENT_ID,
					});
				}
			} else {
				if (plan.paidThroughThirdParty) {
					triggerNotFound = true;
					reportCustomError({
						name: REDIRECT_NOT_FOUND_ERROR_KEY,
						error: REDIRECT_NOT_FOUND_REASON_MAP.IS_THIRD_PARTY,
					});
				}
			}

			return triggerNotFound;
		}
	}, [history, isThirdPartyVendorRoute, planSlug, planNotFound, planIsError, entitlementId, plan]);

	return stateRender({
		isPending: isBlackFriday === undefined,
		onSuccess: () => (
			<Route
				path={path}
				exact={exact}
				render={(props) => (
					<Layout hideFooter={props.hideFooter} tvGemistFinalizePage={tvGemistFinalizePage}>
						<OnboardingContainer
							path={path}
							component={Component}
							{...props}
							{...restProps}
							planSlug={planSlug}
							plan={plan}
							isPromoAllowed={isPromoAllowed}
							entitlementId={entitlementId}
							isParentLoading={planIsLoading || planIsPristine || isEmpty(plan)}
							isParentError={planIsError}
						/>
					</Layout>
				)}
			/>
		),
	});
};
