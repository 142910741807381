import {
	ROUTE_REGISTER,
	ROUTE_REGISTER_BIRTHDATE,
	ROUTE_REGISTER_GENDER,
	ROUTE_REGISTER_INCOMPLETE,
	ROUTE_REGISTER_NAME,
	ROUTE_REGISTER_PASSWORD,
	ROUTE_REGISTER_PRIVACY,
	ROUTE_VERIFY_EMAIL,
	ROUTE_ACTIVATE_PLAN,
	ROUTE_EMAIL_VERIFIED,
	ROUTE_ACTIVATED_PLAN,
	ROUTE_LOGIN,
	ROUTE_TIER_OPTIONS,
	ROUTE_ACTIVATE_VOUCHER,
	ROUTE_FORGOT_PASSWORD_UPDATED,
	ROUTE_FORGOT_PASSWORD_NEW,
	ROUTE_FORGOT_PASSWORD,
	ROUTE_ACCOUNT_BEDROCK,
	ROUTE_CHANGE_PAYMENT_DETAILS_BEDROCK,
	ROUTE_CHANGE_TIER_BEDROCK,
	ROUTE_MESSAGES_ERROR_SUBSCRIPTION_CANCELLED,
	ROUTE_PPE,
	ROUTE_FORGOT_PASSWORD_SENT,
	ROUTE_TIER_OPTIONS_WINBACK,
	ROUTE_GEO_CHECK,
	ROUTE_THIRD_PARTY_COOKIES,
	ROUTE_ERROR,
	ROUTE_NPL,
	ROUTE_NOT_FOUND,
	ROUTE_MESSAGES_NEW_PLATFORM,
	ROUTE_MESSAGES_ERROR_BLOCKED_OPEN_PAYMENT,
	ROUTE_LINK_EXPIRED,
	ROUTE_PAY_BAD_DEBT,
	ROUTE_TRIAL_EXPIRED,
	ROUTE_MESSAGES_RESUME_PENDING_PAYMENT,
} from '../constants/Routes';
import {
	EVT_ACTION_EMAIL_CONFIRM,
	EVT_ACTION_PASSWORD_CONFIRM,
	EVT_ACTION_NAME_CONFIRM,
	EVT_ACTION_GENDER_CONFIRM,
	EVT_ACTION_BIRTHDATE_CONFIRM,
	EVT_ACTION_PRIVACY_CONFIRM,
	EVT_ELEMENT_INTERACTION,
	EVT_EMAIL,
	EVT_ELEMENT_TYPE_BUTTON,
	EVT_PASSWORD,
	EVT_NAME,
	EVT_GENDER,
	EVT_BIRTHDATE,
	EVT_PRIVACY,
} from '../constants/SnowplowEvents';
import { NEXT, ONBOARDING_PRIVACY_CTA } from '../constants/Copy';

export function normalizeGigyaUserProfile(gigyaUserProfile = {}) {
	const { birthDay, birthMonth, birthYear } = gigyaUserProfile;
	const normalized = {
		...gigyaUserProfile,
	};
	if (birthDay && birthMonth && birthYear) {
		normalized.birthdate = [birthYear, birthMonth, birthDay]
			.map((part) => parseInt(part, 10))
			.join('-');
	}
	return normalized;
}

/**
 * Returns a list of all routes that remain to complete user registration.
 */
export function getRemainingFormRoutes({
	isIncomplete,
	profile,
	showAllProfileSteps,
	registerState,
}) {
	const routes = [];
	if (isIncomplete) {
		routes.push(ROUTE_REGISTER_INCOMPLETE);
	}
	if (
		showAllProfileSteps ||
		!profile ||
		!profile.email ||
		registerState?.asyncErrors?.email ||
		registerState?.syncErrors?.email
	) {
		routes.push(ROUTE_REGISTER);
	}
	if (!isIncomplete) {
		// We ask for a password for all NOT incomplete accounts.
		// Incomplete accounts already have a password, because they come from a social provider or other (SSO) RTL sites.
		routes.push(ROUTE_REGISTER_PASSWORD);
	}
	if (showAllProfileSteps || !profile || !profile.firstName || !profile.lastName) {
		routes.push(ROUTE_REGISTER_NAME);
	}
	if (showAllProfileSteps || !profile || !profile.gender) {
		routes.push(ROUTE_REGISTER_GENDER);
	}
	if (showAllProfileSteps || !profile || !profile.birthdate) {
		routes.push(ROUTE_REGISTER_BIRTHDATE);
	}

	// Terms and conditions are always required
	routes.push(ROUTE_REGISTER_PRIVACY);
	return routes;
}

export const analyticsOnboardingByRoute = {
	[ROUTE_REGISTER]: {
		pageName: 'videoland.onboarding.signup.email.funnel',
		funnelData: {
			funnelStepName: 'Aanmelden',
			funnelStep: '01',
		},
	},
	[ROUTE_REGISTER_PASSWORD]: {
		pageName: 'videoland.onboarding.signup.password.funnel',
		funnelData: {
			funnelStepName: 'Wachtwoord',
			funnelStep: '02',
		},
	},
	[ROUTE_REGISTER_NAME]: {
		pageName: 'videoland.onboarding.signup.name.funnel',
		funnelData: {
			funnelStepName: 'Naam',
			funnelStep: '03',
		},
	},
	[ROUTE_REGISTER_GENDER]: {
		pageName: 'videoland.onboarding.signup.gender.funnel',
		funnelData: {
			funnelStepName: 'Geslacht',
			funnelStep: '04',
		},
	},
	[ROUTE_REGISTER_BIRTHDATE]: {
		pageName: 'videoland.onboarding.signup.date-of-birth.funnel',
		funnelData: {
			funnelStepName: 'Geboortedatum',
			funnelStep: '05',
		},
	},
	[ROUTE_REGISTER_PRIVACY]: {
		pageName: 'videoland.onboarding.signup.privacy.funnel',
		funnelData: {
			funnelStepName: 'Privacy',
			funnelStep: '06',
		},
	},
	[ROUTE_VERIFY_EMAIL]: {
		pageName: 'videoland.onboarding.signup.verify.funnel',
		funnelData: {
			funnelStepName: 'Email',
			funnelStep: '07',
		},
	},
	[ROUTE_EMAIL_VERIFIED]: {
		pageName: 'videoland.onboarding.signup.verified.funnel',
		funnelData: {
			funnelStepName: 'Geverifieerd',
			funnelStep: '08',
		},
	},
	[ROUTE_ACTIVATE_PLAN]: {
		pageName: 'videoland.onboarding.signup.activate.funnel',
		funnelData: {
			funnelStepName: 'Activeren',
			funnelStep: '09',
		},
	},
	[ROUTE_ACTIVATED_PLAN]: {
		pageName: 'videoland.onboarding.signup.transaction.funnel',
		funnelData: {
			funnelStepName: 'Betaling',
			funnelStep: '10',
		},
	},
};

export const analyticsByRoute = {
	[ROUTE_LOGIN]: {
		pageName: 'videoland.onboarding.login.index',
	},
	[ROUTE_TIER_OPTIONS]: {
		pageName: 'videoland.onboarding.subscription.funnel',
	},
	[ROUTE_ACTIVATE_VOUCHER]: {
		pageName: 'videoland.onboarding.voucher.index',
		useRtlUser: true,
	},
	[ROUTE_FORGOT_PASSWORD_UPDATED]: {
		pageName: 'videoland.onboarding.password.modified.index',
	},
	[ROUTE_FORGOT_PASSWORD_NEW]: {
		pageName: 'videoland.onboarding.password.new.index',
	},
	[ROUTE_FORGOT_PASSWORD_SENT]: {
		pageName: 'videoland.onboarding.password.recovery-link.index',
	},
	[ROUTE_FORGOT_PASSWORD]: {
		pageName: 'videoland.onboarding.password.index',
	},
	[ROUTE_ACCOUNT_BEDROCK]: {
		pageName: 'videoland.onboarding.my-subscription.index',
		useRtlUser: true,
	},
	[ROUTE_CHANGE_PAYMENT_DETAILS_BEDROCK]: {
		pageName: 'videoland.onboarding.my-subscription.payment',
	},
	[ROUTE_CHANGE_TIER_BEDROCK]: {
		pageName: 'videoland.onboarding.my-subscription.modify-subscription.index',
		useRtlUser: true,
	},
	[ROUTE_CHANGE_PAYMENT_DETAILS_BEDROCK]: {
		pageName: 'videoland.onboarding.my-subscription.modify-payment.index',
		useRtlUser: true,
	},
	[ROUTE_MESSAGES_ERROR_SUBSCRIPTION_CANCELLED]: {
		pageName: 'videoland.onboarding.debt.index',
		useRtlUser: true,
	},
	[ROUTE_PPE]: {
		pageName: 'videoland.onboarding.debt.pay.index',
		useRtlUser: true,
	},
	[ROUTE_TIER_OPTIONS_WINBACK]: {
		pageName: 'videoland.onboarding.subscription-welkom-terug.funnel',
	},
	[ROUTE_GEO_CHECK]: {
		pageName: 'videoland.onboarding.geo.index',
	},
	[ROUTE_THIRD_PARTY_COOKIES]: {
		pageName: 'videoland.onboarding.cookies-blocked.index',
	},
	[ROUTE_ERROR]: {
		pageName: 'videoland.onboarding.error.index',
	},
	[ROUTE_NPL]: {
		pageName: 'videoland.onboarding.npl.index',
	},
	[ROUTE_NOT_FOUND]: {
		pageName: 'videoland.onboarding.not-found.index',
	},
	[ROUTE_MESSAGES_NEW_PLATFORM]: {
		pageName: 'videoland.onboarding.new-platform.index',
	},
	[ROUTE_MESSAGES_ERROR_BLOCKED_OPEN_PAYMENT]: {
		pageName: 'videoland.onboarding.debt.open-invoice.index',
		useRtlUser: true,
	},
	[ROUTE_LINK_EXPIRED]: {
		pageName: 'videoland.onboarding.signup.link-venced.index',
	},
	[ROUTE_PAY_BAD_DEBT]: {
		pageName: 'videoland.onboarding.debt.pay-all.index',
		useRtlUser: true,
	},
	[ROUTE_TRIAL_EXPIRED]: {
		pageName: 'videoland.onboarding.signup.venced-trialperiod.funnel',
		useRtlUser: true,
	},
	[ROUTE_MESSAGES_RESUME_PENDING_PAYMENT]: {
		pageName: 'videoland.onboarding.signup.payment-retry.index',
	},
};

export const funnelEventsOnboarding = {
	[ROUTE_REGISTER]: {
		action: EVT_ACTION_EMAIL_CONFIRM,

		rtlElementContext: {
			elementInteraction: EVT_ELEMENT_INTERACTION,
			elementGroup: EVT_EMAIL,
			elementType: EVT_ELEMENT_TYPE_BUTTON,
			elementName: EVT_EMAIL,
			elementLabel: NEXT,
		},
	},
	[ROUTE_REGISTER_PASSWORD]: {
		action: EVT_ACTION_PASSWORD_CONFIRM,

		rtlElementContext: {
			elementInteraction: EVT_ELEMENT_INTERACTION,
			elementGroup: EVT_PASSWORD,
			elementType: EVT_ELEMENT_TYPE_BUTTON,
			elementName: EVT_PASSWORD,
			elementLabel: NEXT,
		},
	},
	[ROUTE_REGISTER_NAME]: {
		action: EVT_ACTION_NAME_CONFIRM,

		rtlElementContext: {
			elementInteraction: EVT_ELEMENT_INTERACTION,
			elementGroup: EVT_NAME,
			elementType: EVT_ELEMENT_TYPE_BUTTON,
			elementName: EVT_NAME,
			elementLabel: NEXT,
		},
	},
	[ROUTE_REGISTER_GENDER]: {
		action: EVT_ACTION_GENDER_CONFIRM,

		rtlElementContext: {
			elementInteraction: EVT_ELEMENT_INTERACTION,
			elementGroup: EVT_GENDER,
			elementType: EVT_ELEMENT_TYPE_BUTTON,
			elementName: EVT_GENDER,
			elementLabel: NEXT,
		},
	},
	[ROUTE_REGISTER_BIRTHDATE]: {
		action: EVT_ACTION_BIRTHDATE_CONFIRM,

		rtlElementContext: {
			elementInteraction: EVT_ELEMENT_INTERACTION,
			elementGroup: EVT_BIRTHDATE,
			elementType: EVT_ELEMENT_TYPE_BUTTON,
			elementName: EVT_BIRTHDATE,
			elementLabel: NEXT,
		},
	},
	[ROUTE_REGISTER_PRIVACY]: {
		action: EVT_ACTION_PRIVACY_CONFIRM,

		rtlElementContext: {
			elementInteraction: EVT_ELEMENT_INTERACTION,
			elementGroup: EVT_PRIVACY,
			elementType: EVT_ELEMENT_TYPE_BUTTON,
			elementName: EVT_PRIVACY,
			elementLabel: ONBOARDING_PRIVACY_CTA,
		},
	},
};
