import reduce from '../../helpers/redux';
import { HELP_OPEN, HELP_CLOSE } from '../../constants/ActionTypes';

const initialState = {
	isOpen: false,
};

const actionsMap = {
	[HELP_OPEN]: () => ({ isOpen: true }),
	[HELP_CLOSE]: () => ({ isOpen: false }),
};

export default reduce(initialState, actionsMap);
