import { useEffect, useState } from 'react';
import { FLAG_VARIANTS } from '../constants/FlagrABTest';
import { cookie } from '../helpers/storage';

/**
 * Evaluate multiple flags at once
 * @param flagKeys
 * @returns {{results: {value: any, key: string}[]}}
 */ export function useEvaluateFeatureFlags(flagKeys) {
	const [results, setResults] = useState([]);

	useEffect(() => {
		if (__CLIENT__) {
			const filteredExperimentFlags = flagKeys.reduce((filtered, key) => {
				const value = existExperiment(key);
				if (value) filtered.push({ key, value });
				return filtered;
			}, []);
			setResults(filteredExperimentFlags);
		}
	}, [flagKeys]);

	return {
		results,
	};
}
/**
 * Evaluate a single flag
 * @param flagKey
 * @returns {{value: any, key: *}}
 */
export function useEvaluateFeatureFlag(flagKey) {
	const [valueExperiment, setExperiment] = useState(null);

	useEffect(() => {
		if (__CLIENT__) {
			const value = existExperiment(flagKey);
			value ? setExperiment(value) : setExperiment(FLAG_VARIANTS.CONTROL);
		}
	}, [flagKey]);

	return {
		key: flagKey,
		value: valueExperiment,
	};
}

function existExperiment(flagKey) {
	return cookie.get(flagKey);
}
