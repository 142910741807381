import { getConfig } from '../apiConfig';

const defaultHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
	'Cache-Control': 'no-cache',
};

const parseResponse = (response) => {
	if (~[202, 204, 205].indexOf(response.status)) {
		return null;
	} else if (response.status >= 200 && response.status < 300) {
		return response.json();
	} else {
		return response.json().then((body) => {
			const err = new Error(body.message);
			err.body = body;
			err.response = response;
			throw err;
		});
	}
};

export default ({ url, method, body, headers }, successCallback, errorCallback) => {
	const { v5_base } = getConfig();
	return fetch(v5_base + url, {
		method: method,
		body: body ? JSON.stringify(body) : undefined,
		credentials: 'include',
		headers: {
			...defaultHeaders,
			...headers,
		},
	})
		.then(parseResponse)
		.then(successCallback)
		.catch(errorCallback);
};
