import reduce from '../../helpers/redux';
import { SUBMIT_CONTACT_FORM } from '../../constants/ActionTypes';

const initialState = {
	success: false,
	error: false,
	timestamp: 0,
};

const actionsMap = {
	[SUBMIT_CONTACT_FORM + '_SUCCESS']: () => ({
		success: true,
		error: false,
	}),
	[SUBMIT_CONTACT_FORM + '_ERROR']: () => ({
		success: false,
		error: true,
		timestamp: new Date(),
	}),
	[SUBMIT_CONTACT_FORM + '_RESET']: () => initialState,
};

export default reduce(initialState, actionsMap);
