import React, { Component } from 'react';
import cx from 'classnames';
import HelmetDefault from '../common/helmet/HelmetDefault';
import Header from './header/Header';
import OnboardingSidebar from './help/OnboardingSidebar';
import { ROUTE_ERROR } from '../../constants/Routes';
import '../../components/onboarding/Onboarding.scss';

class OnboardingContainer extends Component {
	componentDidCatch() {
		this.props.history.push(ROUTE_ERROR);
	}
	render() {
		const Component = this.props.component;
		return (
			<section>
				<div className="onboarding">
					<HelmetDefault path={this.props.path} />
					<OnboardingSidebar />
					<Header />
					<div className="onboarding__content">
						<div
							data-test="onboarding__container"
							className={cx('onboarding__container--normal', {
								['onboarding__container']: !this.props.pageIsfullWidth,
								['onboarding__container--wider']: this.props.pageIsWide,
							})}
						>
							<Component {...this.props} />
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default OnboardingContainer;
