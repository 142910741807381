import { useRef, useEffect } from 'react';

export const useTimeout = (callback, delay) => {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		let timer;

		if (delay) {
			timer = setTimeout(tick, delay);
		}

		return () => clearTimeout(timer);
	}, [delay]);
};
