import _ from 'lodash';

export const genresToGTMFormat = (genres) => _.map(genres, (genre) => ({ genre }));

export function createAnalyticsFlagsValue(flagCollection = []) {
	if (flagCollection.length === 0) return 'none';

	return flagCollection.reduce((acc, flagItem, index) => {
		if (index > 0) acc += ';';
		acc += `${flagItem.key}=${flagItem.value}`;
		return acc;
	}, '');
}
