import reduce, { pendingState, defaultState, successState, errorState } from '../../helpers/redux';
import { GEO_CHECK } from '../../constants/ActionTypes';

const initialState = {
	...defaultState,
};

const actionsMap = {
	[GEO_CHECK + '_PENDING']: () => pendingState,
	[GEO_CHECK + '_SUCCESS']: (state, action) => ({ ...successState, ...action.payload }),
	[GEO_CHECK + '_ERROR']: (state, action) => ({ ...errorState, ...action.payload }),
};

export default reduce(initialState, actionsMap);
