import { TIER_IDS } from './Tiers';

export const TierTitles = {
	[TIER_IDS.ESSENTIAL]: {
		title: 'Basis',
	},
	[TIER_IDS.STANDARD]: {
		title: 'Plus',
	},
	[TIER_IDS.PREMIUM]: {
		title: 'Premium',
	},
	[TIER_IDS.FREE]: {
		title: 'TV Gemist',
	},
};
