import createApiClient from './createGenericApiClient';

const client = createApiClient('subscription_base');

export function getSubscriptionClient() {
	return client.getApolloClientInstance();
}

export const clearCache = client.clearCache;

export default client.performRequest;
