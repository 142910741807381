import React from 'react';
import { isAllowedToEnter } from '../helpers/authentication';
import { Route } from 'react-router-dom';
import Layout from '../components/layout/Layout';

export default ({ component: Component, onEnter = [], path, exact, ...restProps }) => (
	<Route
		path={path}
		exact={exact}
		render={(props) => {
			const { history } = props;

			if (!isAllowedToEnter(onEnter, history)) return null;

			return (
				<Layout hideFooter={props.hideFooter}>
					<Component {...props} {...restProps} />
				</Layout>
			);
		}}
	/>
);
