import { addDays, friendlyDate } from './datetime';

export function hasActiveSubscription(subscriptions) {
	return !!(subscriptions?.status.toLowerCase() === 'active');
}

export function getSubscriptionEndDate(activeSubscription, nextBillingDate, isCancelled) {
	const { endDate, nextStatusEffectiveDate } = activeSubscription;
	return isCancelled && nextStatusEffectiveDate
		? friendlyDate(addDays(nextStatusEffectiveDate, -1))
		: endDate || nextBillingDate
		? friendlyDate(addDays(endDate || nextBillingDate, -1))
		: null;
}

export function getNextStatusEffectiveDate(activeSubscription) {
	const { nextStatusEffectiveDate } = activeSubscription;
	return nextStatusEffectiveDate && friendlyDate(nextStatusEffectiveDate);
}
