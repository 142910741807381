import { useLocation } from 'react-router';

function useUrlQuery() {
	return new URLSearchParams(useLocation().search);
}

export const useSearchParams = () => {
	const query = useUrlQuery();

	const getSearchParam = (parameterName) => {
		return query.get(parameterName) || null;
	};

	return {
		getSearchParam,
	};
};

export default useSearchParams;
