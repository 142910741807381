import { useEffect, useState } from 'react';
import { ROUTE_LINK_ALREADY_USED, ROUTE_LINK_EXPIRED, ROUTE_ERROR } from '../constants/Routes';
import { GIGYA_ACCOUNT_PENDING_VERIFICATION, GIGYA_REQUEST_EXPIRED } from '../constants/ErrorTypes';
import { parseQueryString } from '../helpers/path';
import { useLocation, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { verifyEmailWithGigya, gigyaGetAccountInfo } from '../actions/authenticationActions';

function getErrorCodeFromLocation({ search }) {
	const searchObject = parseQueryString(search);
	return searchObject?.errorCode ? parseInt(searchObject.errorCode) : null;
}

export function useHandleEmailVerification() {
	const { push } = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const [isVerifyDispatched, setIsVerifyDispatched] = useState(false);
	const [isVerified, setIsVerified] = useState(false);

	const errorCode = getErrorCodeFromLocation(location);

	useEffect(() => {
		switch (errorCode) {
			case GIGYA_ACCOUNT_PENDING_VERIFICATION:
				push(ROUTE_LINK_ALREADY_USED);
				break;
			case GIGYA_REQUEST_EXPIRED:
				dispatch(gigyaGetAccountInfo())
					.then((account) => {
						if (!account.payload.isVerified) {
							push(ROUTE_LINK_EXPIRED);
						} else {
							if (!isVerifyDispatched) {
								dispatch(verifyEmailWithGigya())
									.then(() => setIsVerified(true))
									.catch(() => push(ROUTE_ERROR));
								setIsVerifyDispatched(true);
							}
						}
					})
					.catch(() => {
						push(ROUTE_LINK_EXPIRED);
					});
				break;
			default:
				if (!isVerifyDispatched) {
					dispatch(verifyEmailWithGigya())
						.then(() => setIsVerified(true))
						.catch(() => push(ROUTE_ERROR));
					setIsVerifyDispatched(true);
				}
		}
	}, [errorCode, dispatch, push, isVerifyDispatched]);

	return { isVerified };
}
