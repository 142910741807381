export const EVT_BALLOON = 'customerBalloon';
export const EVT_COMPLETE_REGISTRATION_ERROR = 'completeRegistrationError';
export const EVT_ERROR_PAGE = 'errorPage';
export const EVT_FRAUD_DETECTED = 'fraudDetected';
export const EVT_LOGIN_ERROR = 'loginError';
export const EVT_LOGIN_SUCCESS = 'loginSucces';
export const EVT_LOGOUT_SUCCESS = 'logoutSuccess';
export const EVT_REGISTER_ERROR = 'registerError';
export const EVT_REGISTER_SUCCESS = 'registerSucces';
export const EVT_REQ_RESET_LINK = 'reqResetLink';
export const EVT_SUBSCRIPTION_CANCEL = 'confirmCancellation';
export const EVT_SURVEY = 'smaakenqueteClick';
export const EVT_SURVEY_FINISHED = 'SmaaktestDone';
export const EVT_VOUCHER_VALIDATED = 'voucherValidated';
export const EVT_BUTTON_CLICK = 'click.button';
export const EVT_LINK_CLICK = 'click.link';
export const EVT_SELECT_TILE = 'select.tile';
export const EVT_SHOW_NOTIFICATION = 'show.notification';
export const EVT_SHOW_TEXT = 'show.text';
export const EVT_SHOW_ERROR = 'show.error';
export const EVT_ONBOARDING_CATEGORY = 'onboarding';
export const EVT_CONCURRENCY_ERROR = 'concurrency';

export const EVT_TIER_OPTION_CONFIRM = 'EVT_TIER_OPTION_CONFIRM';
export const EVT_TIER_OPTION_SELECT = 'EVT_TIER_OPTION_SELECT';

export const EVT_SHOW_TIERS_LABEL = 'show.tier';
export const EVT_SHOW_TIER_SUMMARY = 'show.tierSummary';

export const EVT_ACTION_SHOW = 'show';
const EVT_CATEGORY_NOTIFICATION = 'notification';

export const EVT_INITIAL_PAGE = 'EVT_INITIAL_PAGE';
export const EVT_INITIAL_PAGE_NAME = 'initialPage';
export const EVT_SESSION_START = 'SESSION_START';
export const EVT_SESSION_START_EVENT_NAME = 'startSession';

export const EVT_CHANGE_TIER_FROM_UNSUBSCRIBE_CLICK = 'EVT_CHANGE_TIER_FROM_UNSUBSCRIBE_CLICK';
export const EVT_CHANGE_TIER_CLICK = 'EVT_CHANGE_TIER_CLICK';
export const EVT_CHANGE_TIER_CANCEL = 'EVT_CHANGE_TIER_CANCEL';
export const EVT_CHANGE_TIER_SUCCESS = 'EVT_CHANGE_TIER_SUCCESS';
export const EVT_CHANGE_TIER_ERROR = 'EVT_CHANGE_TIER_ERROR';
export const EVT_CHANGE_TIER_NOTIFICATION_HIDE = 'EVT_CHANGE_TIER_NOTIFICATION_HIDE';
export const EVT_CHANGE_TIER_NOTIFICATION_SHOW = 'show.notification';
export const EVT_CHANGE_TIER_SUBMIT = 'EVT_CHANGE_TIER_SUBMIT';

export const EVT_TOGGLE_TIER = 'click_tier_toggle';

const EVT_PROFILE_CATEGORY = 'profile';

export const EVT_CHANGE_TIER_FUNNEL_NAME = 'switch tier';
export const EVT_CHANGE_TIER_NOTIFICATION_CATEGORY = EVT_CATEGORY_NOTIFICATION;
export const EVT_CHANGE_TIER_PROFILE_CATEGORY = EVT_PROFILE_CATEGORY;
export const EVT_CHANGE_TIER_SUBSCRIPTION_CATEGORY = 'subscription';

export const EVT_EMAILVERIFIED_SHOW_TIERS = 'EVT_EMAILVERIFIED_SHOW_TIERS';
export const EVT_EMAILVERIFIED_CHANGE_TIER = 'EVT_EMAILVERIFIED_CHANGE_TIER';

export const EVT_PAYMENT_REMINDER_FUNNEL_NAME = 'open invoice';
export const EVT_PAYMENT_REMINDER_NOTIFICATION_CATEGORY = EVT_CATEGORY_NOTIFICATION;

export const EVT_PAYMENT_REMINDER_SHOWN = 'EVT_PAYMENT_REMINDER_SHOWN';
export const EVT_PAYMENT_REMINDER_SHOWN_LABEL = EVT_PAYMENT_REMINDER_FUNNEL_NAME;
export const EVT_PAYMENT_REMINDER_SHOWN_ACTION = EVT_ACTION_SHOW;

export const EVT_PAYMENT_REMINDER_SUCCESS_SHOWN = 'EVT_PAYMENT_REMINDER_SUCCESS_SHOWN';
export const EVT_PAYMENT_REMINDER_SUCCESS_SHOWN_LABEL = 'shown invoice is paid notification';
export const EVT_PAYMENT_REMINDER_SUCCESS_SHOWN_ACTION = EVT_ACTION_SHOW;

export const EVT_PAYMENT_REMINDER_CLICKED_PAY = 'EVT_PAYMENT_REMINDER_CLICKED_PAY';
export const EVT_PAYMENT_REMINDER_CLICKED_PAY_ACTION = EVT_BUTTON_CLICK;

export const EVT_PAYMENT_REMINDER_SUCCESS_CLICKED_CLOSED =
	'EVT_PAYMENT_REMINDER_SUCCESS_CLICKED_CLOSED';
export const EVT_PAYMENT_REMINDER_SUCCESS_CLICKED_CLOSED_ACTION = EVT_BUTTON_CLICK;

export const EVT_PAYMENT_REMINDER_SHOWN_ERROR = 'EVT_PAYMENT_REMINDER_SHOWN_ERROR';
export const EVT_PAYMENT_REMINDER_SHOWN_ERROR_LABEL = 'open invoice {status}; {message}';
export const EVT_PAYMENT_REMINDER_SHOWN_ERROR_ACTION = EVT_ACTION_SHOW;

export const EVT_CHANGE_TIER_NOTIFICATION_DISCOUNTED_PRICE =
	'EVT_CHANGE_TIER_NOTIFICATION_DISCOUNTED_PRICE';
export const EVT_CHANGE_TIER_NOTIFICATION_DISCOUNTED_PRICE_LABEL = 'change tier payment privilege';
export const EVT_CHANGE_TIER_NOTIFICATION_DISCOUNTED_PRICE_ACTION = EVT_ACTION_SHOW;

export const EVT_UNSUBSCRIBE_NOTIFICATION_DISCOUNTED_PRICE =
	'EVT_UNSUBSCRIBE_NOTIFICATION_DISCOUNTED_PRICE';
export const EVT_UNSUBSCRIBE_NOTIFICATION_DISCOUNTED_PRICE_LABEL = 'unsubscribe payment privilege';
export const EVT_UNSUBSCRIBE_NOTIFICATION_DISCOUNTED_PRICE_ACTION = EVT_ACTION_SHOW;

export const EVT_NEWSLETTER_TOGGLE = 'toggle.newsletter';
export const EVT_NEWSLETTER_CATEGORY = 'account';
export const EVT_CLICKED_CHANGE_PAYMENT_DETAILS = 'EVT_CLICKED_CHANGE_PAYMENT_DETAILS';
export const EVT_CLICKED_CHANGE_PAYMENT_DETAILS_CATEGORY = EVT_PROFILE_CATEGORY;
export const EVT_CLICKED_CHANGE_PAYMENT_DETAILS_ACTION = 'click.button';
export const EVT_CLICKED_CHANGE_PAYMENT_DETAILS_EVENT = EVT_BUTTON_CLICK;
export const EVT_CLICKED_CHANGE_PAYMENT_DETAILS_FUNNEL_NAME = 'change bank account';

export const EVT_FUNNEL_STEP_ONE = '1';
export const EVT_FUNNEL_STEP_TWO = '2';
export const EVT_FUNNEL_STEP_THREE = '3';
export const EVT_FUNNEL_STEP_FOUR = '4';

export const EVT_CLICKED_PAYMENT_METHOD = 'EVT_CLICKED_PAYMENT_METHOD';
export const EVT_CLICKED_PAYMENT_METHOD_CATEGORY = 'checkout';
export const EVT_CLICKED_PAYMENT_METHOD_ACTION = 'click.method';
export const EVT_CLICKED_PAYMENT_METHOD_FUNNEL_NAME = 'checkout';

export const EVT_CLICKED_PAYMENT_PROVIDER = 'EVT_CLICKED_PAYMENT_PROVIDER';
export const EVT_CLICKED_PAYMENT_PROVIDER_CATEGORY = 'checkout';
export const EVT_CLICKED_PAYMENT_PROVIDER_ACTION = 'click.provider';
export const EVT_CLICKED_PAYMENT_PROVIDER_FUNNEL_NAME = 'checkout';

export const EVT_NOTIFICATION_PAYMENT_STILL_PENDING = 'EVT_SHOW_NOTIFICATION_PAYMENT_STILL_PENDING';
export const EVT_NOTIFICATION_PAYMENT_STILL_PENDING_LABEL = 'processing payment';
export const EVT_NOTIFICATION_PAYMENT_STILL_PENDING_CATEGORY = EVT_CATEGORY_NOTIFICATION;

export const EVT_FLAG_ALLOCATED = 'EVT_FLAG_ALLOCATED';
export const EVT_FLAG_ALLOCATED_EVENT = 'flagAllocated';
export const EVT_FLAG_ALLOCATED_CATEGORY = 'flagr';

export const EVT_ONBOARDING_SUBMIT_GENDER = 'EVT_ONBOARDING_SUBMIT_GENDER';
export const EVT_ACTION_SUBMIT_GENDER = 'submit.gender';
export const EVT_ONBOARDING_SKIP_GENDER = 'EVT_ONBOARDING_SKIP_GENDER';

export const EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_SUCCESS_LABEL = 'confirmation change bank account';
export const EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_SUCCESS =
	'EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_SUCCESS';
export const EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_ACTION = EVT_ACTION_SHOW;
export const EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_CATEGORY = EVT_CATEGORY_NOTIFICATION;

export const EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_FAILED =
	'EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_FAILED';
export const EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_FAILED_LABEL = 'failed change bank account';

export const EVT_SHOW_ACTIVATION_PRECONDITION = 'EVT_SHOW_ACTIVATION_PRECONDITION';
export const EVT_SHOW_ACTIVATION_PRECONDITION_CATEGORY = 'activate subscription';
export const EVT_SHOW_ACTIVATION_PRECONDITION_LABEL_CANCELLATION = 'cancel current subscription';
export const EVT_SHOW_ACTIVATION_PRECONDITION_LABEL_PAUSING = 'pause current subscription';
export const EVT_SHOW_ACTIVATION_PRECONDITION_LABEL_UNKNOWN = 'unknown precondition';

export const EVT_CLICKED_VERIFY_CODE = 'EVT_CLICKED_VERIFY_CODE';
export const EVT_CLICKED_VERIFY_CODE_CATEGORY = 'activate third party account';
export const EVT_CLICKED_VERIFY_CODE_ACTION = 'submit.code';
export const EVT_CLICKED_VERIFY_CODE_EVENT = 'start.accountActivation';

export const EVT_VERIFY_CODE_ERROR = 'EVT_VERIFY_CODE_ERROR';
export const EVT_VERIFY_CODE_ERROR_CATEGORY = 'activate third party account';
export const EVT_VERIFY_CODE_ERROR_ACTION = EVT_SHOW_ERROR;
export const EVT_VERIFY_CODE_ERROR_EVENT = EVT_SHOW_ERROR;

export const EVT_ENTITLEMENT_VERIFY_INVALID = 'EVT_ENTITLEMENT_VERIFY_INVALID';
export const EVT_ENTITLEMENT_VERIFY_INVALID_CATEGORY = EVT_CATEGORY_NOTIFICATION;
export const EVT_ENTITLEMENT_VERIFY_INVALID_ACTION = EVT_ACTION_SHOW;
export const EVT_ENTITLEMENT_VERIFY_INVALID_EVENT = EVT_SHOW_NOTIFICATION;

export const EVT_FINALIZE_TRANSACTION = 'transactionTemp';

export const EVT_PAY_OPEN_INVOICE_EVENT = 'pay_open_invoice';
export const EVT_PAY_OPEN_INVOICE_CATEGORY = 'pay_open_invoice';
export const EVT_PAY_OPEN_INVOICE_FUNNEL_NAME = 'pay open invoice';

export const EVT_PAY_OPEN_INVOICE_CLICKED_PAY = 'EVT_OPEN_INVOICE_CLICKED_PAY';
export const EVT_PAY_OPEN_INVOICE_CLICKED_PAY_ACTION = 'payment_start';

export const EVT_PAY_OPEN_INVOICE_CLICKED_PAYMENT_METHOD =
	'EVT_PAY_OPEN_INVOICE_CLICKED_PAYMENT_METHOD';
export const EVT_PAY_OPEN_INVOICE_CLICKED_PAYMENT_METHOD_ACTION = 'payment_method_select';

export const EVT_PAY_OPEN_INVOICE_CLICKED_PAYMENT_PROVIDER =
	'EVT_PAY_OPEN_INVOICE_CLICKED_PAYMENT_PROVIDER';
export const EVT_PAY_OPEN_INVOICE_CLICKED_PAYMENT_PROVIDER_ACTION = 'payment_provider_select';

export const EVT_PAY_OPEN_INVOICE_CLICKED_CONTINUE_TRANSACTION =
	'EVT_PAY_OPEN_INVOICE_CLICKED_CONTINUE_TRANSACTION';
export const EVT_PAY_OPEN_INVOICE_CLICKED_CONTINUE_TRANSACTION_ACTION =
	'payment_continue_transaction';

export const EVT_PAY_OPEN_INVOICE_FINALIZE_TRANSACTION =
	'EVT_PAY_OPEN_INVOICE_FINALIZE_TRANSACTION';
export const EVT_PAY_OPEN_INVOICE_FINALIZE_TRANSACTION_ACTION = 'payment_finalize_transaction';
