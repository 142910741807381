import reduce, { pendingState, defaultState, successState, errorState } from '../../helpers/redux';
import { PAYMENT_ACCOUNT } from '../../constants/ActionTypes';

const initialState = {
	user: {
		...defaultState,
		data: {},
	},
};

const actionsMap = {
	[PAYMENT_ACCOUNT + '_PENDING']: (state) => ({
		...state,
		user: {
			...pendingState,
			data: {},
		},
	}),
	[PAYMENT_ACCOUNT + '_ERROR']: (state) => ({
		...state,
		user: {
			...errorState,
			data: {},
		},
	}),
	[PAYMENT_ACCOUNT + '_SUCCESS']: (state, { payload }) => ({
		...state,
		user: {
			...successState,
			data: {
				...payload,
			},
		},
	}),
};

export default reduce(initialState, actionsMap);
