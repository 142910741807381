export const EVT_VOUCHER_CATEGORY = 'voucher';
export const EVT_VALIDATE_SUCCESS = 'validate-success';
export const EVT_VALIDATE_ERROR = 'validate-error';
export const EVT_PASSWORD = 'password';
export const EVT_RECOVER_PASSWORD_START = 'recover-password-start';
export const EVT_RECOVER_PASSWORD_SUBMIT = 'recover-password-submit';
export const EVT_RECOVER_PASSWORD_LOGIN = 'recover-password-login';
export const EVT_SUBSCRIPTION_CATEGORY = 'tier';
export const EVT_SUBSCRIPTION_SELECT = 'subscription-select';
export const EVT_SUBSCRIPTION_HIGHLIGHT = 'subscription-highlight';
export const EVT_SUBSCRIPTION_CHANGE = 'subscription-change';
export const EVT_SUBSCRIPTION_CHANGE_PROCEED = 'subscription-change-proceed';
export const EVT_SUBSCRIPTION_CHANGE_CONFIRM = 'subscription-change-confirm';
export const EVT_SUBSCRIPTION_CONFIRM = 'subscription-confirm';
export const EVT_SUBSCRIPTION_CANCEL = 'subscription-cancel';
export const EVT_SUBSCRIPTION_VERIFICATION = 'subscription-verification';
export const EVT_PAYMENT_SELECT = 'payment-select';
export const EVT_PAYMENT_CONFIRM = 'payment-confirm';
export const EVT_PAYMENT_CHANGE = 'payment-change';
export const EVT_PAYMENT_TYPE_SELECT = 'payment-type-select';
export const EVT_PAYMENT_METHOD_SELECT = 'payment-method-select';
export const EVT_PAYMENT_METHOD = 'payment-method';
export const EVT_PAYMENT_TYPE = 'payment-type';
export const EVT_PAYMENT_CHANGE_NOTIFICATION = 'payment-change-notification';
export const EVT_PAYMENT_RETRY = 'payment-retry';
export const EVT_FOOTER = 'footer';
export const EVT_NAVIGATION_CATEGORY = 'navigation';
export const EVT_ACCOUNT = 'account';
export const EVT_BAD_DEBT = 'bad-debt';
export const EVT_INVOICE_CONFIRM = 'invoice-confirm';
export const EVT_INVOICE_CONFIRMATION = 'invoice-confirmation';
export const EVT_INVOICE_PAYMENT_SELECT = 'invoice-payment-select';
export const EVT_INVOICE_SELECT = 'invoice-select';
export const EVT_INVOICE_OVERVIEW = 'invoice-overview';
export const EVT_INVOICE_PAYMENT = 'invoice-payment';
export const EVT_INVOICE_SHOW_MORE = 'invoice-show-more';
export const EVT_INVOICE = 'invoice';
export const EVT_INVOICE_SHOW = 'invoice-show';
export const EVT_INVOICE_DOWNLOAD = 'invoice-download';
export const EVT_ALL_INVOICES = 'all-invoices';
export const EVT_UNIQUE_INVOICE = 'unique-invoice';
export const EVT_LOGIN = 'login';
export const EVT_LOGIN_SUBMIT = 'login-submit';
export const EVT_LOGIN_SUCCESS = 'login-success';
export const EVT_SUBSCRIPTION_START = 'subscription-start';
export const EVT_ERROR = 'error';
export const EVT_FUNNEL = 'funnel';
export const EVT_ACTION_EMAIL_CONFIRM = 'email-confirm';
export const EVT_ACTION_PASSWORD_CONFIRM = 'password-confirm';
export const EVT_ACTION_NAME_CONFIRM = 'name-confirm';
export const EVT_ACTION_GENDER_CONFIRM = 'gender-confirm';
export const EVT_ACTION_BIRTHDATE_CONFIRM = 'birthdate-confirm';
export const EVT_ACTION_PRIVACY_CONFIRM = 'privacy-confirm';
export const EVT_ACTION_EMAIL_RESEND = 'email-resend';
export const EVT_ACTION_EMAIL_VERIFY = 'email-verify';

export const EVT_SEND_EMAIL = 'send-email';
export const EVT_SET_PASSWORD = 'set-password';
export const EVT_TIER_TABLE = 'tier-table';
export const EVT_TIER_TABLE_BUTTON = 'tier-table-button';
export const EVT_TIER_TITLE = 'tier-tile';
export const EVT_SELECT = 'select';
export const EVT_REGISTER = 'register';
export const EVT_EMAIL = 'email';
export const EVT_NAME = 'name';
export const EVT_GENDER = 'gender';
export const EVT_BIRTHDATE = 'birthdate';
export const EVT_PRIVACY = 'privacy';
export const EVT_VERIFY = 'verify';
export const EVT_TEXT_LINK = 'textlink';
export const EVT_PAYMENT = 'payment';
export const EVT_TRANSACTION = 'transaction';
export const EVT_PAYMENT_CONFIRMATION = 'payment-confirmation';
export const EVT_SUCCESS = 'success';
export const EVT_ACTIVATE = 'activate';
export const EVT_SHOW = 'show';
export const EVT_SECTION = 'section';
export const EVT_CONFIRMATION = 'confirmation';
export const EVT_ICON = 'icon';
export const EVT_MY_INVOICES = 'my-invoices';
export const EVT_CONFIRM = 'confirm';
export const EVT_CANCEL = 'cancel';
export const EVT_START = 'start';
export const EVT_SUBSCRIPTION = 'subscription';
export const EVT_SOCIAL_MEDIA = 'social-media';
export const EVT_NOTIFICATION = 'notification';
export const EVT_NOTIFICATION_PAYMENT_CLOSE = 'notification-payment-close';
export const EVT_CONFIRM_CLOSE = 'confirm-close';
export const EVT_IMAGE = 'image';

export const EVT_ELEMENT_INTERACTION = 'click';
export const EVT_ELEMENT_TYPE_BUTTON = 'button';
export const EVT_ELEMENT_NAME_PASSWORD_RECOVERY = 'password-recovery';
export const EVT_ELEMENT_TYPE_HYPERLINK = 'hyperlink';
export const EVT_ELEMENT_STYLE_PRIMARY = 'primary';
export const EVT_ELEMENT_STYLE_TERNARY = 'ternary';
