import React from 'react';
import { ERROR_SERVICE_UNAVAILABLE } from '../../../constants/Copy';

import './Error.scss';

export default () => (
	<div className="error-container" data-test="error-message">
		<p className="error-message">{ERROR_SERVICE_UNAVAILABLE}</p>
	</div>
);
