const parseJSON = (response) => response.json();

export default ({ url, method, body, headers }, successCallback, errorCallback) => {
	return fetch(url, {
		method,
		body,
		headers,
	})
		.then(parseJSON)
		.then(successCallback)
		.catch(errorCallback);
};
