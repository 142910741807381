import { matchPath } from 'react-router';
import {
	ROUTE_ACTIVATE_PLAN,
	ROUTE_PARTNER_ACTIVATE,
	ROUTE_PARTNER,
	ROUTE_ACTIVATE_PLAN_STATUS,
	ROUTE_NOT_FOUND,
	ROUTE_ACTIVATE_VOUCHER,
	ROUTE_YOUNG_CAPITAL,
	ROUTE_TMOBILE_STANDALONE,
	ROUTE_ALBERT_HEIJN,
	ROUTE_TMOBILE_STANDALONE_REDIRECT,
	ROUTE_DUTCH_FILM_FESTIVAL,
	ROUTE_TMOBILE_THUIS,
	ROUTE_LOGIN,
	ROUTE_ACTIVATED_PLAN,
	ROUTE_CONTACT,
	ROUTE_MESSAGES,
	ROUTE_ZIGGO,
	ROUTE_ALBERT_HEIJN_SHORT,
	ROUTE_DUTCH_FILM_FESTIVAL_SHORT,
	ROUTE_ACCOUNT_BEDROCK,
	ROUTE_CHANGE_TIER_BEDROCK,
	ROUTE_CHANGE_PAYMENT_DETAILS_BEDROCK,
	ROUTE_CHANGE_PAYMENT_DETAILS_STATUS_BEDROCK,
	ROUTE_VRIENDEN_VAN_AMSTEL,
	ROUTE_INVOICE,
	ROUTE_FORGOT_PASSWORD,
	ROUTE_FORGOT_PASSWORD_SENT,
	ROUTE_NEW_PASSWORD,
	ROUTE_NEW_PASSWORD_UPDATED,
	ROUTE_NEW_PASSWORD_INVALID,
	ROUTE_FORGOT_PASSWORD_NEW,
	ROUTE_FORGOT_PASSWORD_UPDATED,
} from '../constants/Routes';
import { ALWAYS_AVAILABLE_ROUTES } from '../constants/Navigation';
import { removeDomain, removeQueryString } from './url';

export function getPathnameFromRoute(route) {
	return route.replace(/^.*\/\/[^/]+/, '');
}

export function isRouteAccessible(route, hasActiveSubscription = false, redirectToBedrock = false) {
	let allowedRoutes = [
		ROUTE_ACTIVATED_PLAN,
		ROUTE_ACTIVATE_PLAN,
		ROUTE_ACTIVATE_PLAN_STATUS,
		ROUTE_ACTIVATE_VOUCHER,
		ROUTE_ALBERT_HEIJN,
		ROUTE_ALBERT_HEIJN_SHORT,
		ROUTE_CONTACT,
		ROUTE_DUTCH_FILM_FESTIVAL,
		ROUTE_DUTCH_FILM_FESTIVAL_SHORT,
		ROUTE_MESSAGES,
		ROUTE_NOT_FOUND,
		ROUTE_PARTNER,
		ROUTE_PARTNER_ACTIVATE,
		ROUTE_TMOBILE_STANDALONE,
		ROUTE_TMOBILE_STANDALONE_REDIRECT,
		ROUTE_TMOBILE_THUIS,
		ROUTE_YOUNG_CAPITAL,
		ROUTE_ZIGGO,
		ROUTE_VRIENDEN_VAN_AMSTEL,
		ROUTE_INVOICE,
		...ALWAYS_AVAILABLE_ROUTES,
	];

	if (!redirectToBedrock) allowedRoutes = [...allowedRoutes, ROUTE_LOGIN];

	const allowedAuthenticatedRoutes = [
		ROUTE_ACCOUNT_BEDROCK,
		ROUTE_CHANGE_TIER_BEDROCK,
		ROUTE_CHANGE_PAYMENT_DETAILS_BEDROCK,
		ROUTE_CHANGE_PAYMENT_DETAILS_STATUS_BEDROCK,
		ROUTE_FORGOT_PASSWORD,
		ROUTE_FORGOT_PASSWORD_SENT,
		ROUTE_NEW_PASSWORD,
		ROUTE_NEW_PASSWORD_UPDATED,
		ROUTE_NEW_PASSWORD_INVALID,
		ROUTE_FORGOT_PASSWORD_NEW,
		ROUTE_FORGOT_PASSWORD_UPDATED,
	];

	return !!matchPath(removeDomain(removeQueryString(route)), {
		path: hasActiveSubscription ? [...allowedRoutes, ...allowedAuthenticatedRoutes] : allowedRoutes,
	});
}
