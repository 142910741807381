export const CAN_CLAIM_ERRORS = {
	ALREADY_CLAIMED: 'ALREADY_CLAIMED',
	ENTITLEMENT_CANCELLED: 'ENTITLEMENT_IS_CANCELLED',
	ALREADY_TRUSTED_PARTY: 'USER_ALREADY_HAS_TRUSTED_PARTY_SUBSCRIPTION',
	NOT_FOUND: 'NOT_FOUND',
	TRUSTED_PARTY_MISMATCH: 'TRUSTED_PARTY_MISMATCH',
};

export const ACTIVATE_PRECONDITIONS = {
	SUBSCRIPTION_WILL_BE_PAUSED: 'SUBSCRIPTION_WILL_BE_PAUSED',
	SUBSCRIPTION_WILL_BE_CANCELLED: 'SUBSCRIPTION_WILL_BE_CANCELLED',
};
