import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Heading.scss';

export const HEADING_SIZE = {
	SMALL: 'small',
	MEDIUM: 'medium',
	LARGE: 'large',
};

const Heading = ({ children, size, className, align = 'center', testId, isNarrow }) => {
	let shouldBeNarrow;
	if (typeof isNarrow === 'boolean') {
		shouldBeNarrow = isNarrow;
	} else {
		// to support legacy usage of this component
		shouldBeNarrow = size === HEADING_SIZE.MEDIUM;
	}

	return (
		<h1
			className={cx(
				className,
				'onboarding__heading',
				`onboarding__heading--${size}`,
				`onboarding__heading--align-${align}`,
				{
					'onboarding__heading--is-narrow': shouldBeNarrow,
				}
			)}
			data-test={testId}
		>
			{children}
		</h1>
	);
};

Heading.propTypes = {
	children: PropTypes.node.isRequired,
	size: PropTypes.oneOf(_.values(HEADING_SIZE)),
	className: PropTypes.string,
	testId: PropTypes.string,
};

Heading.defaultProps = {
	size: HEADING_SIZE.MEDIUM,
	className: null,
};

export default Heading;
