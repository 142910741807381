const HELP_URL = 'https://hulp.videoland.com';
export const CONDITIONS_URL = `${HELP_URL}/article/gebruikersvoorwaarden-videoland`;
const PRIVACY_URL = `${HELP_URL}/article/privacy-en-cookiestatement-videoland`;
const VOUCHER_URL = `https://www.videoland.com/activeer/voucher`;
const ABOUT_BASIS = `${HELP_URL}/article/wat-kan-ik-met-het-basis-abonnement`;
const ABOUT_PLUS = `${HELP_URL}/article/wat-kan-ik-met-het-plus-abonnement`;
const ABOUT_PREMIUM = `${HELP_URL}/article/wat-kan-ik-met-het-premium-abonnement`;

export const ONBOARDING_PRIVACY_TITLE = 'Ga je akkoord met onze voorwaarden?';
export const ONBOARDING_PRIVACY_DESCRIPTION = 'Als je doorgaat, ga je akkoord met de {conditions}';
export const ONBOARDING_PRIVACY_INVALID = 'Ga je akkoord met onze voorwaarden?';
export const ONBOARDING_PRIVACY_CTA = 'Ja, ik ga akkoord';
export const ONBOARDING_TERMS_USER_CONDITIONS = 'gebruikersvoorwaarden';
export const ONBOARDING_PRIVACY_NAME = 'Bekijk ons {privacy}';
export const ONBOARDING_PRIVACY_LINK = 'privacy- en cookiestatement.';
export const ONBOARDING_PRIVACY_CONDITIONS = 'abonnementsvoorwaarden van Videoland';
export const ONBOARDING_PRIVACY_URL = 'https://privacy.rtl.nl/privacy-statement';
export const ONBOARDING_TERMS_CONNECT_PARTNER =
	'Ook ga je akkoord met het feit dat je klant wordt bij Videoland. Je betaalt het Videoland abonnement via je tv-aanbieder';

export const TERMS_AND_CONDITIONS_CTA = 'Ja, ik ga akkoord met de actievoorwaarden';
export const TERMS_AND_CONDITIONS_SUBTITLE = 'Omschrijving van de actie';

export const ONBOARDING_TERMS = {
	title: 'ABONNEMENTVOORWAARDEN VIDEOLAND',
	text: [
		'<strong>ARTIKEL 1 Definities</strong>',
		'Abonnee: de natuurlijke persoon die een Videoland abonnement heeft afgesloten bij Videoland.',
		'Apparaten: de apparaten waarop Content afgespeeld kan worden. Kijk voor actuele informatie over de apparaten die je kunt gebruiken op de Website.',
		'App: de App van Videoland.',
		'Cadeaukaarten: cadeaukaarten waarmee je als Abonnee bij Videoland kunt afrekenen. Cadeaukaarten kunnen als betaalmiddel worden gebruikt voor de abonnementskosten. Lees op de Website de voorwaarden voor het gebruik van de Cadeaukaarten.',
		'Content: al het audiovisueel materiaal, zoals films, series, documentaires en trailers, dat via de Dienst wordt aangeboden.',
		'Dienst: de abonneedienst van Videoland.',
		'Klantenservice: de gegevens van onze Klantenservice zijn te vinden op <a href="https://hulp.videoland.com/hc/nl" target="_blank" rel=“nofollow”>https://hulp.videoland.com/hc/nl</a>. De Klantenservice is bereikbaar middels het contactformulier en/of de chatfunctie op de Website, telefonisch en/of via de social media-kanalen van Videoland.',
		'Partijen: Videoland en Abonnee gezamenlijk.',
		'RTL Account: Jouw persoonlijke registratie via RTL Account waarmee je toegang tot de Dienst krijgt. Naast je gebruikersnaam en het wachtwoord bevat het RTL Account jouw Profiel.',
		'Overeenkomst: De Overeenkomst die tot stand komt op het moment dat jij een abonnement sluit op onze Dienst, waarbij Videoland toezegt, met toepasselijkheid van de bepalingen opgenomen in deze Voorwaarden, toegang tot bepaalde Content te leveren tegen een door jou te verrichten tegenprestatie bestaande uit de betaling van een bedrag aan Videoland en/of het accepteren van advertenties. Abonnees die via derden, waaronder televisie- en telecomproviders, een abonnement hebben afgesloten betalen aan deze derden.',
		'Videoland: de besloten vennootschap met beperkte aansprakelijkheid, Videoland B.V., statutair gevestigd aan de Barend en Van Dorpweg 2, te (1217 WP) Hilversum (KvK 17279203).',
		'Videoland abonnement: het abonnement van Videoland waarmee je toegang krijgt tot de abonneedienst van Videoland.',
		'Voorwaarden: de onderhavige voorwaarden, die op de Overeenkomst tussen van Partijen van toepassing zijn.',
		'Website: de Website van de Dienst van Videoland: <a href="https://www.videoland.com/nl/" target="_blank" rel=“nofollow”>www.videoland.com</a>.',
		'<strong>ARTIKEL 2 Aanmaken van een RTL Account</strong>',
		'2.1 Om gebruik te kunnen maken van onze Dienst dien je een RTL Account aan te maken. Met een RTL-account heb je 7 dagen lang gratis toegang tot alle gemist Content van de RTL-zenders.',
		'2.2 Alleen personen van 16 jaar en ouder kunnen zich registreren. Minderjarigen mogen alleen gebruik maken van onze Dienst onder toezicht van en met toestemming van een volwassene.',
		'2.3 Abonnees dienen bij het aanmaken van een RTL Account op de Website zowel hun e-mailadres als gebruikersnaam op te geven en een uniek wachtwoord te kiezen. Bij het aanmaken van een RTL Account kan er ook om andere persoonlijke gegevens gevraagd worden, zoals voornaam, achternaam, leeftijd en geslacht, die aan je profiel worden toegevoegd. Door in te loggen op je RTL Account kun je de informatie die is opgeslagen in je profiel inzien of wijzigen.',
		'2.4 Je RTL Account en het bijbehorende wachtwoord wordt alleen voor persoonlijk, niet commercieel gebruik toegekend. Het wachtwoord dient vertrouwelijk te worden gehouden en mag niet worden gedeeld met personen buiten je huishouden. Onder ‘huishouden’ wordt verstaan alle apparaten die zijn gekoppeld aan je primaire woning en die worden gebruikt door personen die daar wonen. ',
		'2.5 Misbruik en/of oneigenlijk gebruik van het RTL Account of het wachtwoord in combinatie met je gebruikersnaam komen voor rekening en risico van de Abonnee. ',
		'2.6 Videoland behoudt zich het recht voor om de toegang tot de Dienst te blokkeren indien een Abonnee in strijd met deze voorwaarden handelt. ',
		'2.7 Indien je je Videoland abonnement beëindigt, eindigt je RTL account niet automatisch. Je kunt dan nog steeds gemist Content kijken. Als je jouw RTL Account wil opzeggen kan dat via de Website.',
		'<strong>ARTIKEL 3 - Videoland abonnement aangaan, opzeggen of wijzigen</strong>',
		'3.1 Om toegang te kunnen krijgen tot de overige Content van Videoland, dien je een betaald abonnement af te sluiten. Er zijn drie soorten abonnementen verkrijgbaar: Basis, Plus en Premium. Kijk voor meer informatie over de verschillende abonnementen op de Website. Betaalde abonnementen kunnen ook door derden, zoals een kabelmaatschappij, worden aangeboden in combinatie met hun eigen producten of diensten.',
		'3.2 Het Basis, Plus of Premium abonnement gaat in op de datum waarop Videoland de aanmelding van jou als Abonnee bevestigt. Je krijgt toegang tot de Content vanaf het moment dat de eerste termijn van het abonnementsgeld of -in geval van een proef- of cadeau-abonnement- de verificatiebetaling van € 0,01 door Videoland is ontvangen. Bij sommige proef- of cadeau-abonnementen wordt er geen verificatiebetaling afgeschreven.',
		'3.4 Je kan het Videoland abonnement een (1) keer per betaalperiode wijzigen naar een Basis, Plus of Premium abonnement. De (maandelijkse) vergoeding wordt in dat geval bij de eerstvolgende betaalperiode aangepast aan het nieuwe abonnement.',
		'3.5 Behoudens in geval van opzegging tijdens een eventuele proefperiode (dit kan op elk moment tijdens de proefperiode), loopt het Videoland abonnement automatisch door tot het moment dat het Videoland abonnement door jou of Videoland wordt opgezegd.',
		'3.6 Je kan het Videoland abonnement op elk gewenst moment opzeggen, doch tenminste een (1) dag voor de dag waarop de volgende (maandelijkse) vergoeding geïncasseerd wordt. Na opzegging hou je toegang tot het Videoland abonnement voor de periode waarvoor de (maandelijkse) vergoeding geïncasseerd is, waarna het abonnement wordt stopgezet. ',
		'3.7 Opzegging van een tijdelijk abonnement, dat wil zeggen een abonnement dat voor een bepaalde periode (bijvoorbeeld een maand of 12 maanden) is afgesloten, is gedurende de overeengekomen termijn niet mogelijk. Tijdelijke abonnementen worden na afloop automatisch verlengd, tenzij er bij het aangaan van het betreffende abonnement anders wordt aangegeven. Na afloop van de overeengekomen termijn kun je het Videoland abonnement op elk gewenst moment opzeggen.',
		'3.8 Opzeggen kan online via de Website (ga naar het tabje “abonnement” in de accountinstellingen). Indien je op een andere wijze wenst op te zeggen, zul je in de opzegging in ieder geval het e-mailadres moeten vermelden waarmee je je RTL-account hebt aangemaakt.',
		'3.9 Met het afsluiten van een Basis, Plus of Premium abonnement stem je ermee in dat de Dienst, voor het verstrijken van de bedenktermijn, direct wordt geleverd. Je verklaart tegelijkertijd afstand te doen van je herroepingsrecht.',
		'<strong>ARTIKEL 4 - Betaling van de abonnementsvergoeding</strong>',
		'4.1 Door gebruik te maken van het Basis, Plus of Premium abonnement autoriseer je Videoland om maandelijks de abonnementskosten, tegen het dan geldende tarief, af te schrijven via de door jou geselecteerde betalingsmethode (bankrekening, creditcard of paypal account).',
		'4.2 Videoland kan een gratis proefperiode aanbieden. Als dat het geval is wordt dat vermeld bij het aangaan van het abonnement.',
		'4.3 De abonnementskosten voor het Basis, Plus of Premium abonnement zijn verschuldigd vanaf de eerste dag van het aangaan van het abonnement en worden elke maand daarna, behoudens opzegging, op dezelfde dag (of de eerstvolgende geschikte werkdag, ter keuze van Videoland) automatisch afgeschreven. Videoland behoudt zich het recht voor om het tijdstip van het in rekening brengen van de abonnementskosten te wijzigen, bijvoorbeeld in het geval dat de abonnementskosten niet kunnen worden geïncasseerd wegens een ontoereikend banksaldo, wanneer je je abonnement wijzigt of als je betaalde abonnement is ingegaan op een dag die in een bepaalde maand niet voorkomt. ',
		'4.4 Videoland is gerechtigd om de toegang tot de Dienst (of een deel daarvan) met onmiddellijke ingang onmogelijk te maken en/of de overeenkomst met onmiddellijke ingang te beëindigen indien Videoland niet in staat is de verschuldigde vergoeding via de opgegeven betaalmethode te incasseren, of indien een reeds geïncasseerd bedrag wordt gestorneerd.',
		'4.5 Indien een Abonnee met enige betaling in gebreke blijft, bijvoorbeeld wanneer Videoland niet in staat is om de uitstaande bedragen te innen, is Videoland, al dan niet via derden, zoals televisie- en telecomproviders, gerechtigd de betreffende betalingen buitengerechtelijk te (doen) incasseren, vermeerderd met de verschuldigde wettelijke rente daarover alsmede de gemaakte en te maken buitengerechtelijke kosten.',
		'4.6 Een Abonnee is pas gekweten van zijn betalingsverplichting indien het verschuldigde bedrag door Videoland onvoorwaardelijk is ontvangen.',
		'4.7 Videoland verricht geen terugbetalingen of crediteringen (voor gedeeltelijk gebruikte perioden).',
		'4.8 Voor abonnees die via derden, waaronder televisie- en telecomproviders, een Videoland abonnement afnemen verloopt de betaling via deze derden, onder de toepasselijkheid van de voorwaarden van deze derden.',
		'<strong>ARTIKEL 5 – Prijzen en acties</strong>',
		'5.1 Alle door Videoland gepubliceerde prijzen zijn in euro’s, inclusief het in Nederland geldende BTW-tarief, tenzij anders vermeld.',
		'5.2 Videoland kan naar eigen goeddunken special introductie aanbiedingen (zoals een gratis proefperiode) of andere prijsacties aanbieden. De voorwaarden voor de aanbieding worden door Videoland bepaald. Videoland behoudt zich het recht voor om een aanbieding in te trekken en het RTL Account of de toegang tot de Dienst te blokkeren als Videoland vaststelt dat je onterecht gebruik maakt van zo’n aanbieding.',
		'5.3 Leden van huishoudens met een bestaand of recent gestopt Videoland abonnement komen in beginsel niet in aanmerking voor introductieaanbiedingen. Aan de hand van gegevens, zoals een apparaat-id, betalingsmethode (bankrekening, creditcard en/of paypal account) en/of e-mailadres, die door een bestaand of recent gestopt Videoland abonnement worden of is gebruikt, bepaalt Videoland of je in aanmerking komt voor een introductieaanbieding.',
		'5.4 Videoland behoudt zich het recht voor om prijzen op ieder tijdstip te wijzigen. Videoland biedt geen prijsbescherming of terugbetaling in geval van een prijsverlaging en/of promotionele aanbieding.',
		'5.5 De verschillende betalingswijzen kunnen van tijd tot tijd wijzigen. De meest recente mogelijkheden staan vermeld op de Website.',
		'5.6 Als Abonnee geef je Videoland toestemming om, al dan niet via derden, zoals televisie- en telecomproviders, alle handelingen te verrichten die nodig zijn om een betaling te laten plaatsvinden op de door de jou gekozen betaalwijze.',
		'5.7 Videoland maakt onder andere gebruik van het betaalsysteem van Buckaroo B.V. om betalingen te verwerken. Buckaroo B.V. heeft een vergunning als betaaldienstverlener van De Nederlandsche Bank N.V. Indien je PayPal als betaalmethode selecteert ga je een facturatieovereenkomst aan met Videoland B.V..',
		'<strong>ARTIKEL 6 - De content</strong>',
		'6.1 De beschikbare Content op de Dienst zal voortdurend variëren. Videoland behoudt zich het recht voor om het aanbod van Content zonder voorafgaande kennisgeving te wijzigen.',
		'6.2 De Content wordt aangeboden onder bepaalde leeftijds- en inhoudsclassificaties, volgens de classificatie van Kijkwijzer.  De Abonnee verklaart tenminste de voor de specifieke Content vereiste leeftijd te hebben.',
		'6.3 Sommige Content zal (tevens) beschikbaar zijn in HD-Content, en UHD-Content. Dergelijke Content is uitsluitend te bekijken op apparaten die HD en/of UHD ondersteunen en via een internetverbinding die daarvoor voldoende capaciteit heeft. Je bent er zelf verantwoordelijk voor dat HD-Content of UHD-Content kan worden afgespeeld op jouw apparaat Abonnee en dat de capaciteit van je internetverbinding voldoende is voor het afspelen van deze Content. Voor meer informatie over de beschikbaarheid van Content in HD- en UHD-beeldkwaliteit kan je de Website raadplegen.',
		'<strong>ARTIKEL 7 - Technische vereisten</strong>',
		'7.1 Specifieke technische vereisten en beperkingen voor het ontvangen en het afspelen van Content op de verschillende soorten Apparaten, zoals pc, smart TV, tablet, smartphone en gameconsole, zijn vermeld op de Website. Voor (bepaalde soorten) Content en Apparaten kunnen deze technische vereisten en beperkingen door de ontwikkeling van de techniek of vereisten van de licentiegevers van Videoland van tijd tot tijd wijzigen.',
		'7.2 Het aantal Apparaten waarop je tegelijkertijd Content kan afspelen, is afhankelijk van het soort abonnement dat je hebt gekozen. Zie voor meer informatie de website.',
		'<strong>,ARTIKEL 8 - Licentie</strong>',
		'8.1 Door een abonnement af te sluiten op de Dienst van Videoland verlenen we je een beperkt, niet-exclusief en niet-overdraagbaar recht om de Content conform de Voorwaarden, uitsluitend in huiselijke kring (voor persoonlijk en niet-commercieel gebruik) in Nederland op een Apparaat te bekijken.',
		'8.2 Al het gebruik van de Content anders dan in de Overeenkomst omschreven is niet toegestaan en is uitdrukkelijk voorbehouden aan Videoland en haar licentiegevers.',
		'8.3 Mede omdat Videoland voor het aanbieden van de Content afhankelijk is van afspraken met haar licentiegevers behoudt Videoland zich het recht voor om op ieder tijdstip (een deel van) de Content te wijzigen, op te schorten of te verwijderen. Voor zover mogelijk zal Videoland je hierover vooraf informeren.',
		'8.4 Het is niet toegestaan de Content voor commerciële doeleinden op te nemen, te kopiëren, te verveelvoudigen en/of door te leveren aan een derde. Ook is het niet toegestaan de Content publiekelijk (dat wil zeggen buiten jouw huiselijke kring) openbaar te maken of anderen in staat te stellen de Content publiekelijk openbaar te maken in openbare gelegenheden. Onder openbare gelegenheden wordt in ieder geval verstaan: i) iedere publiekelijk toegankelijke ruimte, ii) elke ruimte waarvoor een toegangsprijs wordt gevraagd, iii) horecagelegenheden, iv) gemeenschappelijke ruimtes van hotels, ziekenhuizen, bejaardenhuizen, kantoren, overheidsinstellingen en clubhuizen.',
		'8.5  Aangezien de rechten van Videoland op de Content veelal beperkt zijn tot Nederland is het niet toegestaan om de Dienst te gebruiken buiten Nederland, behalve tijdens een tijdelijk verblijf (zoals vakantie).',
		'8.6 Een Abonnee is gehouden om ten aanzien van de Content steeds in overeenstemming te handelen met de Voorwaarden en de geldende wettelijke regels, waaronder maar niet beperkt tot regels ten aanzien van intellectuele eigendom. Indien we constateren dat je in strijd met de Voorwaarden en/of de geldende wettelijke regels Content openbaar maakt, verveelvoudigt, ter beschikking stelt aan derden, en/of distribueert, zal aangifte bij de politie worden gedaan en zullen alle kosten en schade, waaronder maar niet beperkt tot immateriële en materiële schade op jou worden verhaald. In dat geval zal je tevens per direct worden uitgesloten van ieder gebruik van de Dienst.',
		'<strong>,ARTIKEL 9 - Intellectuele en industriële eigendomsrechten</strong>',
		'9.1 Alle (intellectuele eigendoms-) rechten die rusten op of voortvloeien uit (de inhoud van) (onderdelen van) de Dienst – met inbegrip van doch niet beperkt tot afbeeldingen, gebruikersinterfaces, audio- en videoclips, de Content, redactionele inhoud en de scripts en software die worden gebruikt om de Dienst uit te voeren – berusten bij Videoland en/of de licentiegevers van Videoland. Geen enkel deel van de Content mag in enigerlei vorm of op enigerlei wijze worden gereproduceerd door de Abonnee, behalve voor zover uitdrukkelijk toegestaan in deze Voorwaarden.',
		'9.2 Aan het aangaan van deze Overeenkomst kan de Abonnee geen rechten ontlenen ten aanzien van de inhoud van de Dienst of de Content. Alle door jou opgevraagde Content blijft eigendom van Videoland of haar leveranciers/licentiegevers.',
		'9.3 De Content bevat technische beveiligingen, aangebracht door Videoland en/of haar licentiegevers, om de Content te beschermen en het gebruik van de Content te beperken in overeenstemming met de Voorwaarden. Het is derhalve niet toegestaan om:',
		'<ul><li>de Content en/of een deel daarvan en/of het computerbestand waarmee de Content aan een Abonnee wordt aangeboden te kopiëren, aan te bieden, te verspreiden, te verkopen, te verhuren, over te dragen, te (sub)licenseren, te distribueren, ter beschikking te stellen aan derden of derden toegang te verlenen tot (delen van) de Content door het verstrekken van daartoe benodigde gegevens;</li><li>te (proberen om) de versleuteling en/of de beveiliging van de (doorvoer van) de Content en/of het computerbestand en/of de software waarmee de Content aan een Abonnee wordt aangeboden of via welke de Content wordt afgespeeld te omzeilen of te kraken, dan wel enig door of namens Videoland aangebrachte technische voorzieningen te omzeilen;</li><li>de Content aan te passen, veranderen, vertalen, of afgeleide werken van de Content te vervaardigen;</li><li>het bestand waarmee de Content wordt aangeboden te de-compileren, kopiëren, verveelvoudigen of aan te passen om achter de beginspecificaties van het systeem en/of de ontwerpspecificaties te komen, of anderszins het bestand dusdanig te bewerken dat een voor personen leesbare code ontstaat;</li><li>de Content en/of het bestand waarmee de Content aangeboden wordt te verveelvoudigen of te “branden” op een fysieke drager, zoals, maar niet beperkt tot CD’s, DVD’s, Blue-ray, USB-sticks etc., of welke andere in te toekomst te ontwikkelen fysieke dragers.</li></ul>',
		'<strong>ARTIKEL 10 - Aansprakelijkheid</strong>',
		'10.1 Videoland sluit iedere aansprakelijkheid uit voor eventuele gevolgen (waaronder begrepen directe en indirecte schade) die verband houden met het gebruik van de Dienst of de onbereikbaarheid daarvan, zoals storingen, onderbrekingen, onvolledige informatie of fouten, behoudens wanneer deze het gevolg zijn van opzet of bewuste roekeloosheid van Videoland. ',
		'10.2 Videoland verleent geen garanties en aanvaardt geen aansprakelijkheid ten aanzien van het niet kunnen afspelen van Content, bijvoorbeeld door ontoereikende functionaliteit en/of storingen van jouw apparatuur of internetverbinding. De minimale functionaliteiten en vereisten voor het afspelen van Content, zijn weergegeven op de Website. ',
		'10.3 Videoland kan niet garanderen dat de Content voldoet aan de inhoudelijke verwachtingen die Abonnees ten aanzien van die Content kunnen hebben op grond van marketing uitingen zoals trailers, synopsis etc. In geen geval zullen Videoland en/of haar licentiegevers aansprakelijk zijn voor het feit dat Content niet voldoet aan de inhoudelijk verwachtingen ten aanzien van deze Content.',
		'10.4 Een Abonnee is aansprakelijk voor schade geleden door Videoland ten gevolge van gebruik van de Dienst of de Content in strijd met het bepaalde in de wet en/of deze Voorwaarden.',
		'10.5 Videoland is niet aansprakelijk voor tekortkomingen van derden, waaronder tekortkomingen van Buckaroo B.V., de partij die de betalingen in verband met de Overeenkomst verwerkt, tenzij zij bij de selectie van deze derden niet de nodige zorgvuldigheid heeft betracht en de geleden schade te wijten is aan grove nalatigheid of opzet van de zijde van Videoland.',
		'10.6 Eventuele klachten of vermeende aanspraken dien je binnen 24 uur na constatering, of in ieder geval zo snel mogelijk, schriftelijk kenbaar te maken aan de Klantenservice. Indien en voor zover Videoland op welke grond ook jegens een Abonnee aansprakelijk mocht zijn voor enige door een Abonnee geleden schade, dan is deze aansprakelijkheid te allen tijde beperkt tot het bedrag van de vergoeding die Videoland voor het verlenen van haar Dienst van de Abonnee heeft ontvangen of zou hebben ontvangen.',
		'<strong>ARTIKEL 11 - Overmacht</strong>',
		'11.1 Onverminderd de overige aan haar toekomende rechten, heeft Videoland in geval van overmacht het recht, om naar eigen keuze, de uitvoering van de Dienst op te schorten, dan wel de Overeenkomst zonder rechterlijke tussenkomst te ontbinden, zulks zonder dat Videoland gehouden is tot enige schadevergoeding, tenzij dit in de gegeven omstandigheden naar maatstaven van redelijkheid en billijkheid onaanvaardbaar zou zijn.',
		'11.2 Onder overmacht wordt verstaan iedere tekortkoming welke niet aan Videoland kan worden toegerekend, omdat zij niet te wijten is aan haar schuld, noch krachtens de wet, een rechtshandeling of in het verkeer geldende opvattingen voor haar rekening komt.',
		'<strong>ARTIKEL 12 – Beëindiging door Videoland</strong>',
		'12.1 Wanneer je je niet aan de wet of deze Voorwaarden houdt, of Videoland het aannemelijk acht dat  je je daar niet aan houdt of zal houden, heeft Videoland het recht om geheel naar eigen inzicht en zonder voorafgaande kennisgeving: (i) de Overeenkomst te beëindigen, waarbij de Abonnee aansprakelijk blijft voor alle bedragen die tot en met de datum van beëindiging uit hoofde van de Overeenkomst of eerdere Overeenkomsten verschuldigd is geworden; en/of (ii) de toegang tot de Dienst (of een deel daarvan) onmogelijk te maken.',
		'12.2 Videoland behoudt zich het recht voor om op ieder tijdstip de Overeenkomst en/of de Dienst (of een deel daarvan) te wijzigen, op te schorten of te beëindigen met uitsluiting van iedere aansprakelijkheid jegens een Abonnee of derden. Voor zover mogelijk zal Videoland Abonnees vooraf informeren over een wijziging, opschorting of stopzetting van de Overeenkomst of de Dienst.',
		'<strong>ARTIKEL 13 - Toepasselijkheid voorwaarden</strong>',
		'13.1 Deze Voorwaarden zijn van toepassing op alle rechtsverhoudingen tussen jou en Videoland ten aanzien van de Dienst, deze Overeenkomst inbegrepen.',
		'13.2 Door het aangaan van een abonnement of door gebruik te maken van de Dienst aanvaard je de toepasselijkheid van deze Voorwaarden.',
		'13.3 Videoland behoudt zich het recht voor, om van tijd tot tijd, wijzigingen aan te brengen in deze Voorwaarden. De meest actuele versie van de Voorwaarden kan altijd op de Website geraadpleegd worden. Door gebruik te blijven maken van de Dienst na wijziging van deze Voorwaarden aanvaard je de toepasselijkheid van de gewijzigde Voorwaarden.',
		'13.4 De nietigheid van enige bepaling van deze Voorwaarden tast de geldigheid van de overige bepalingen van deze Voorwaarden niet aan.',
		'13.5 Op de verwerking van persoonsgegevens is het privacy statement van RTL van toepassing, zoals dat is gepubliceerd op de Website. RTL Nederland B.V. is de verantwoordelijke voor de verwerking van persoonsgegevens in de zin van de Algemene verordening gegevensbescherming.',
		'<strong>ARTIKEL 14 Toepasselijk recht en forumkeuze</strong>',
		'14.1 Op deze Voorwaarden en de Overeenkomst is Nederlands recht van toepassing. Eventuele geschillen zullen worden voorgelegd aan de bevoegde rechter in Amsterdam.',
		'<strong>Voor het laatst gewijzigd op 6 februari 2024</strong>',
	],
};

export const NPL_WINBACK_TERMS = {
	title: 'Algemene Actievoorwaarden Nationale Postcode Loterij X Videoland aanbod – 2022',
	description:
		'Op de tijdelijke Nationale Postcode Loterij X Videoland aanbod – 2022 zijn de volgende voorwaarden van toepassing:',
	text: [
		'Dit persoonlijke aanbod is alleen beschikbaar voor Gebruikers die reeds via de Nationale Postcode Loterij tijdelijk (6 maanden) over een Videoland Plus abonnement beschikken welk abonnement is afgelopen tussen 1 juli 2022 en 1 september 2022.',
		'Inhoud van het aanbod:  6 maanden een Videoland Basis abonnement voor een tijdelijke actieprijs van € 3,99 per maand i.p.v. het huidige reguliere tarief van € 5,99.',
		'Het aanbod is enkel van toepassing op het Videoland Basis abonnement en geldt dus niet voor andere Videoland abonnement soorten.',
		'Het aanbod geldt vanaf het moment dat je na afloop van jouw tijdelijke Videoland Plus abonnement een persoonlijke actiecode ontvangt per e-mail van Videoland tot en met 31 december 2022 (Actieperiode).',
		'Om gebruik te maken van dit persoonlijke aanbod dien je jouw persoonlijke actiecode binnen de Actieperiode te activeren via de pagina: videoland.com/code.',
		'Je kunt je abonnement tussentijds opzeggen (zie voor meer informatie de Gebruikersvoorwaarden van Videoland). Dat betekent dat je ook korter dan 6 maanden gebruik maken van dit tijdelijke voordelige actietarief. Sluit je daarna opnieuw een Videoland abonnement af, dan geldt dit aanbod niet langer en maak je gebruik van de dan geldende tarieven.',
		'Het Videoland Basis abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>mét reclame</strong>. Je kunt 1 stream tegelijk afspelen en hebt geen toegang tot download to go faciliteit. Meer informatie over het Videoland Basis abonnement vind je op <a href="https://www.videoland.com/nl/" target="_blank" rel=“nofollow”>www.videoland.com</a>. Met Videoland Basis kun je niet kijken op een TV-ontvanger (Ziggo Horizon/Next, KPN, XS4all, Budget). Je kunt wel gewoon kijken via je laptop, desktop, mobiele Videoland app op je telefoon of tablet (iOS en Android), Smart tv en PS4.',
		'Op dit aanbod is geen gratis proefperiode van veertien (14) dagen van toepassing. Dit betekent dat de Gebruiker de tijdelijke maandelijkse actieprijs direct verschuldigd is aan Videoland vanaf de datum waarop Videoland de aanmelding van de Gebruiker bevestigt en begint.',
		'Na 6 maanden loopt je Videoland Basis abonnement automatisch door en betaal je het op dat moment geldende tarief van Videoland Basis. De exacte start datum vind je terug in je Videoland Account.',
		`Op het Videoland Basis abonnement dat op grond van dit aanbod wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel=“nofollow”>Gebruikersvoorwaarden</a> van Videoland en het  <a href=${PRIVACY_URL} target="_blank" rel=“nofollow”>Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende afspraken zijn gemaakt.`,
		`Heb je vragen over het aanbod, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel=“nofollow”>klantenservice</a>. De Klantenservice is bereikbaar middels het contactformulier en/of de chatfunctie op de website www.videoland.com, telefonisch via 088 - 123 24 56 en/of via de social media-kanalen van Videoland.`,
		'Wijzigingen voorbehouden.',
	],
};

export const LTC_12_MAANDEN = {
	title: 'Videoland Plus jaarabonnement ',
	description:
		'Op de Videoland Plus jaarabonnement actie zijn de volgende actievoorwaarden van toepassing:',
	text: [
		'Het actie aanbod betreft het Videoland Plus jaarabonnement (12 maanden) voor de prijs van 10 maanden, te weten 99,90 EURO inclusief btw i.p.v. de reguliere prijs voor 12 maanden van 107,88 EURO inclusief btw.',
		'De gebruiker betaalt dit bedrag in één keer vooraf tijdens het afsluiten van het abonnement. ',
		'Na 12 maanden loopt het abonnement automatisch door en betaalt de gebruiker het dan geldende maandtarief van het Videoland Plus abonnement welke maandelijks wordt afgerekend. Het abonnement is vanaf dan maandelijks opzegbaar (zie voor meer informatie de algemene voorwaarden).',
		'Als de gebruiker na verloop van de eerste 12 maanden van het jaarabonnement het Videoland Plus abonnement wil stopzetten, dan kan dit tot uiterlijk één dag voor het verlopen van het Videoland Plus jaarabonnement. De exacte datum hiervan vind je terug in je Videoland Account.',
		'De actie is enkel van toepassing op het Videoland Plus abonnement en geldt dus niet voor de andere Videoland abonnement soorten.',
		'Het aanbod kan alleen worden afgesloten in de periode 17 september t/m 20 januari 2024 (de actieperiode) door middel van activatie binnen deze actieperiode via de actiepagina <a href="https://www.videoland.com/nl/titels/glory/" target="_blank" rel=“nofollow”>videoland.com/glory</a>',
		'Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en documentaires van Videoland kunt kijken zonder reclame. Je kunt 2 streams tegelijk afspelen en gebruik maken van de download to go faciliteit. ',
		'Op deze actie is de gratis proefperiode van veertien (14) dagen niet van toepassing. Ook is dit abonnement niet maandelijks tussentijds op te zeggen. Indien de gebruiker tijdens het jaar opzegt, wordt het Videoland Plus jaarabonnement aan het einde van de periode van 12 maanden beëindigd. ',
		'Het actie aanbod geldt alleen voor nieuwe Videoland klanten. Bestaande klanten kunnen hier geen gebruik van maken. ',
		`Op het Videoland Plus jaarabonnement dat op grond van de actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel=“nofollow”>Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel=“nofollow”>Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende afspraken zijn gemaakt.`,
		`Heb je vragen over de actie, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel=“nofollow”>klantenservice</a>.`,
		'Wijzigingen voorbehouden. ',
	],
};

export const BLACK_FRIDAY_TERMS = {
	title: 'Actievoorwaarden Black Friday Deal 2024',
	description:
		'Op de Black Friday Deal 2024 (hierna: de BFD Deal) zijn de volgende voorwaarden van toepassing:',
	text: [
		'Je kunt deze deal alleen afnemen in de periode vanaf 25 november t/m 2 december 2024 (actieperiode) via www.videoland.com.',
		'Inhoud van de BFD Deal: Je kunt kiezen uit 6 maanden lang gebruik van het Videoland Basis abonnement voor de tijdelijke actieprijs van € 3,99 per maand i.p.v. het huidige tarief van € 5,99 óf 6 maanden lang gebruik van het Videoland Plus abonnement voor de tijdelijke actieprijs van € 8,99 per maand i.p.v. het huidige tarief van € 10,99.',
		'De BFD Deal is enkel van toepassing op het Videoland Basis of Plus abonnement en geldt dus niet voor de andere Videoland abonnementen.',
		`Je kunt je abonnement tussentijds opzeggen (zie voor meer informatie artikel 3 van de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a>). Dat betekent dat je ook korter dan 6 maanden gebruik kunt maken van het voordelige BFD Deal tarief. Sluit je daarna opnieuw een Videoland abonnement af, dan geldt deze BFD Deal niet langer en maak je gebruik van de dan geldende tarieven.`,
		`Het Videoland Basis abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken mét reclame. Je kunt 1 stream tegelijk afspelen en hebt geen toegang tot de Download to Go faciliteit. Meer info over het Videoland Basis abonnement vind je <a href=${ABOUT_BASIS} target="_blank" rel="noopener">hier</a>.`,
		`Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken zonder reclame. Je kunt 2 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Plus abonnement vind je <a href=${ABOUT_PLUS} target="_blank" rel="noopener">hier</a>.`,
		'Na 6 maanden loopt je Videoland Basis of Plus abonnement automatisch door en betaal je het op dat moment geldende tarief voor je abonnement. De exacte start datum vind je terug in je Videoland Account.',
		'Heb je nog nooit eerder een Videoland abonnement gehad? Dan kijk je de eerste 2 weken gratis.',
		'De BFD Deal geldt alleen voor nieuwe Videoland abonnees. Bestaande abonnees kunnen hier geen gebruik van maken.',
		`Op het Videoland Basis en Plus abonnement dat op grond van de BFD Deal wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende afspraken zijn gemaakt.`,
		`Heb je vragen over de BFD Deal, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice</a>.`,
		'Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de BFD Deal stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst, voorzien van een datum van ingang.',
	],
};

export const NPL_2023 = {
	title: 'Actievoorwaarden Postcode Loterij aanbod 2024',
	description: 'De looptijd van de Actie is vanaf 1 januari 2024 tot en met 31 december 2024.',
	text: [
		'Met de Actie kun je 12 maanden lang gebruik maken van het Videoland Basis abonnement voor de tijdelijke actieprijs van € 3,99 per maand i.p.v. het huidige tarief van € 5,99.',
		`Het Videoland Basis abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken mét reclame. Je kunt 1 stream tegelijk afspelen en hebt geen toegang tot de Download to Go faciliteit. Meer info over het Videoland Basis abonnement vind je <a href="${ABOUT_BASIS}" target="_blank" rel="noopener">hier</a>.`,
		'Je kunt van de Actie gebruik maken vanaf het moment dat je de e-mail met het aanbod hebt ontvangen t/m uiterlijk 30 dagen daarna, https://www.videoland.com/activeer/voucher. Je kunt hierbij gebruik maken van de vouchercode uit de email.',
		'De Actie is uitsluitend van toepassing op het Videoland Basis abonnement en geldt niet voor andere Videoland abonnementen.',
		`Je kunt je gekozen Videoland abonnement altijd tussentijds opzeggen (zie voor meer informatie de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a>). Dat betekent dat je ook korter dan 12 maanden gebruik kunt maken van de voordelige actieprijs. Sluit je na je opzegging echter weer opnieuw een Videoland abonnement af, dan kun je niet langer gebruikmaken van de actieprijs en zijn de dan regulier geldende tarieven van toepassing.`,
		`Na 12 maanden loopt je Videoland Basis abonnement automatisch door en betaal je het op dat moment geldende tarief voor het abonnement dat je hebt gekozen. De exacte startdatum hiervan vind je terug in je Videoland Account.`,
		'Op deze Actie is de gratis proefperiode van veertien (14) dagen niet van toepassing. Dit betekent dat de gebruiker de tijdelijke maandelijkse actieprijs direct verschuldigd is aan Videoland vanaf de datum waarop Videoland de aanmelding van de gebruiker bevestigt en het betreffende abonnement ingaat.',
		'De Actie is alleen beschikbaar voor nieuwe Videoland abonnees. Bestaande abonnees kunnen hier geen gebruik van maken.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		`Op het Videoland Basis abonnement dat op grond van deze Actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende bepalingen gelden.`,
		`Heb je vragen over deze Actie, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice</a>.`,
		`Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst, voorzien van een datum.`,
	],
};

export const NPL_2024 = {
	title: 'Actievoorwaarden Postcode Loterij aanbod 2024',
	description: 'De looptijd van de Actie is vanaf 1 januari 2024 tot en met 31 december 2024.',
	text: [
		'Met de Actie kun je 12 maanden lang gebruik maken van het Videoland Basis abonnement voor de tijdelijke actieprijs van € 2,99 per maand i.p.v. het huidige tarief van € 5,99.',
		`Het Videoland Basis abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken mét reclame. Je kunt 1 stream tegelijk afspelen en hebt geen toegang tot de Download to Go faciliteit. Meer info over het Videoland Basis abonnement vind je <a href="${ABOUT_BASIS}" target="_blank" rel="noopener">hier</a>.`,
		'Je kunt van de Actie gebruik maken vanaf het moment dat je de e-mail met het aanbod hebt ontvangen t/m uiterlijk 30 dagen daarna, https://www.videoland.com/activeer/voucher. Je kunt hierbij gebruik maken van de vouchercode uit de email.',
		'De Actie is uitsluitend van toepassing op het Videoland Basis abonnement en geldt niet voor andere Videoland abonnementen.',
		`Je kunt je gekozen Videoland abonnement altijd tussentijds opzeggen (zie voor meer informatie de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a>). Dat betekent dat je ook korter dan 12 maanden gebruik kunt maken van de voordelige actieprijs. Sluit je na je opzegging echter weer opnieuw een Videoland abonnement af, dan kun je niet langer gebruikmaken van de actieprijs en zijn de dan regulier geldende tarieven van toepassing.`,
		`Na 12 maanden loopt je Videoland Basis abonnement automatisch door en betaal je het op dat moment geldende tarief voor het abonnement dat je hebt gekozen. De exacte startdatum hiervan vind je terug in je Videoland Account.`,
		'Op deze Actie is de gratis proefperiode van veertien (14) dagen niet van toepassing. Dit betekent dat de gebruiker de tijdelijke maandelijkse actieprijs direct verschuldigd is aan Videoland vanaf de datum waarop Videoland de aanmelding van de gebruiker bevestigt en het betreffende abonnement ingaat.',
		'De Actie is alleen beschikbaar voor nieuwe Videoland abonnees. Bestaande abonnees kunnen hier geen gebruik van maken.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		`Op het Videoland Basis abonnement dat op grond van deze Actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende bepalingen gelden.`,
		`Heb je vragen over deze Actie, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice</a>.`,
		`Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst, voorzien van een datum.`,
	],
};

export const NPL_2024_2 = {
	title: 'Actievoorwaarden Postcode Loterij aanbod 2024',
	text: [
		'Met de Actie kun je 12 maanden lang gebruik maken van het Videoland Basis abonnement voor de tijdelijke actieprijs van € 3,99 per maand i.p.v. het huidige tarief van € 5,99.',
		`Het Videoland Basis abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken mét reclame. Je kunt 1 stream tegelijk afspelen en hebt geen toegang tot de Download to Go faciliteit. Meer info over het Videoland Basis abonnement vind je <a href="${ABOUT_BASIS}" target="_blank" rel="noopener">hier</a>.`,
		'Je kunt van de Actie gebruik maken vanaf het moment dat je de e-mail met het aanbod hebt ontvangen t/m uiterlijk 30 dagen daarna, https://www.videoland.com/activeer/voucher. Je kunt hierbij gebruik maken van de vouchercode uit de email.',
		'De Actie is uitsluitend van toepassing op het Videoland Basis abonnement en geldt niet voor andere Videoland abonnementen.',
		`Je kunt je gekozen Videoland abonnement altijd tussentijds opzeggen (zie voor meer informatie de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a>). Dat betekent dat je ook korter dan 12 maanden gebruik kunt maken van de voordelige actieprijs. Sluit je na je opzegging echter weer opnieuw een Videoland abonnement af, dan kun je niet langer gebruikmaken van de actieprijs en zijn de dan regulier geldende tarieven van toepassing.`,
		`Na 12 maanden loopt je Videoland Basis abonnement automatisch door en betaal je het op dat moment geldende tarief voor het abonnement dat je hebt gekozen. De exacte startdatum hiervan vind je terug in je Videoland Account.`,
		'Op deze Actie is de gratis proefperiode van veertien (14) dagen niet van toepassing. Dit betekent dat de gebruiker de tijdelijke maandelijkse actieprijs direct verschuldigd is aan Videoland vanaf de datum waarop Videoland de aanmelding van de gebruiker bevestigt en het betreffende abonnement ingaat.',
		'De Actie is alleen beschikbaar voor nieuwe Videoland abonnees. Bestaande abonnees kunnen hier geen gebruik van maken.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		`Op het Videoland Basis abonnement dat op grond van deze Actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende bepalingen gelden.`,
		`Heb je vragen over deze Actie, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice</a>.`,
		`Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst, voorzien van een datum.`,
	],
};

const ANNUAL_SUBSCRIPTION_PROMOTION_2024 = {
	title: 'Voorwaarden jaarabonnement Videoland base 2024',
	description:
		'Op de jaarabonnement actie 2024 (hierna: de "Actie”) zijn de volgende actievoorwaarden van toepassing:',
	text: [
		'De Actie is alleen beschikbaar voor bestaande Videoland abonnees die op 22 juli 2024 een Videoland Basis, Plus of Premium abonnement hebben. Nieuwe abonnees kunnen hier geen gebruik van maken.',
		'De looptijd van de Actie is vanaf 22 juli 2024 tot en met 25 augustus 2024. <br/>Let op: het is niet mogelijk om de vouchercode in het buitenland te activeren.',
		'Via de Actie kun je een jaarabonnement afsluiten voor:',
		'Videoland Basis voor 4,99 per maand i.p.v. het nieuwe tarief van 5,99 per maand; of ',
		'Videoland Plus voor 9,99 per maand i.p.v. het nieuwe tarief van 10,99 per maand; of ',
		'Videoland Premium voor 11,99 per maand i.p.v. het nieuwe tarief van 12,99 per maand.',
		'Voor abonnees die op basis van een eerdere actie op dit moment een lagere prijs betalen dan hier vermeld geldt dat zij tegen deze prijs gebruik kunnen maken van de Actie.',
		'Bij de activatie van je jaarabonnement betaal je via een Ideal of creditcard betaling de actieprijs vooruit voor de eerste maand. Het kan zijn dat je hierdoor een dubbele betaling doet, dit wordt gecorrigeerd na afloop van het jaarabonnement, je huidige abonnement wordt dan hervat en eventuele vooruitbetaalde abonnement kosten worden verrekend.',
		'Bij het afsluiten van een abonnement via deze Actie ga je een verplichting aan voor 12 maanden. Dit abonnement is niet tussentijds opzegbaar.',
		'De Actie kan niet worden gecombineerd met andere acties. De actie is niet beschikbaar voor abonnees die reeds gebruik maken van een andere actie d.m.v. een vouchercode.',
		'De eventuele proefperiode waarvan de abonnee op het moment van het aangaan van het jaarabonnement gebruik maakt komt per direct te vervallen.',
		'Na 12 maanden loopt het abonnement automatisch door en betaal je vanaf dat moment het reguliere tarief dat geldt voor het abonnement dat je hebt gekozen. De exacte startdatum en prijs vind je terug in je Videoland Account.',
		`Het Videoland Basis abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken met reclame. Je kunt 1 stream tegelijk afspelen. Meer info over het Videoland Basis abonnement vind je <a href="${ABOUT_BASIS}" target="_blank" rel="noopener">hier</a>.`,
		`Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken zonder reclame. Je kunt 2 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Plus abonnement vind je <a href="${ABOUT_PLUS}" target="_blank" rel="noopener">hier</a>.`,
		`Het Videoland Premium abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken zonder reclame. Je kunt 4 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Premium abonnement vind je <a href="${ABOUT_PREMIUM}" target="_blank" rel="noopener">hier</a>.`,
		`Je kunt deelnemen aan de Actie door gebruik te maken van de vouchercode uit de email via <a href=${VOUCHER_URL} target="_blank" rel="noopener">https://www.videoland.com/activeer/voucher</a>.`,
		'De Actie is uitsluitend van toepassing op de bestaande abonnementen, te weten het Videoland Basis, Videoland Plus of Videoland Premium jaarabonnement.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		`Op het Videoland Basis, Videoland Plus en Videoland Premium abonnement dat op grond van deze Actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a>  van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing, behalve voor zover in deze actievoorwaarden afwijkende bepalingen zijn opgenomen.`,
		`Heb je vragen over deze Actie, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice</a>.`,
		'Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst, voorzien van een datum van ingang.',
	],
};

const MKT_SUMMER_OFFER = {
	title: 'Actievoorwaarden Zomeractie- 3 maanden plus voor €14,99 2023',
	description:
		'Op de zomeractie - 3 maanden plus voor €14,99 2023 (hierna: de "Actie”) zijn de volgende voorwaarden van toepassing',
	text: [
		'De Actie is alleen af te nemen in de periode van 21 juni 2023 tot en met 21 september 2023 (hierna: de “Actieperiode”) door personen die een persoonlijke vouchercode hebben ontvangen voor de zomeractie 2023 per e-mail.',
		'Met de Actie kun je 3 maanden lang gebruik maken van het Videoland Plus abonnement voor de tijdelijke actieprijs van €14,99 per drie maanden in plaats van het huidige tarief van €29,97 per drie maanden. Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en documentaires van Videoland kunt kijken zonder reclame. Je kunt 2 streams tegelijk afspelen en je kunt gebruik maken van de download to go faciliteit. Meer informatie over het Videoland Plus abonnement vind je hier.',
		'Om gebruik te maken van de Actie dien je jouw persoonlijke actiecode voor de Actie die je per e-mail hebt ontvangen te activeren via: https://www.videoland.com/activeer/voucher.',
		'De Actie is uitsluitend van toepassing op het Videoland Plus abonnement en geldt niet voor andere Videoland abonnement soorten.',
		'Let op: je kunt je Videoland Plus abonnement gedurende de Actieperiode niet tussentijds opzeggen. Na de Actieperiode kun je jouw Videoland Plus abonnement altijd tussentijds opzeggen (zie voor meer informatie de Gebruikersvoorwaarden).',
		'Na 3 maanden loopt je Videoland Plus abonnement automatisch door en betaal je het op dat moment geldende reguliere tarief voor Videoland Plus. De exacte startdatum hiervan vind je terug in je Videoland account.',
		'Op deze Actie is de gratis proefperiode van 14 dagen niet van toepassing. Dit betekent dat je als abonnee de tijdelijke maandelijkse actieprijs direct verschuldigd bent aan Videoland vanaf de datum waarop Videoland jouw aanmelding heeft bevestigt en waarop jouw abonnement ingaat.',
		'De Actie is alleen beschikbaar voor nieuwe Videoland abonnees. Bestaande abonnees kunnen hier geen gebruik van maken.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		'Op het Videoland Plus abonnement dat op grond van deze Actie wordt aangegaan zijn de Gebruikersvoorwaarden van Videoland en het Privacy- en cookiestatement van Videoland van toepassing, behalve voor zover in deze actievoorwaarden afwijkende bepalingen gelden.',
		'Heb je vragen over deze Actie? Neem dan contact op met klantenservice van Videoland via één van de volgende servicekanelen: Whatsapp naar 06-1445 92 77, telefonisch via 088-123 2456, de chatfunctie, het contactformulier of de social media kanalen vermeld op www.videoland.com.',
		'Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst.',
	],
};

const MKT_SUMMER_OFFER_50_PERCENT = {
	title: 'Actievoorwaarden Zomeractie 2023',
	description:
		'Op de zomeractie 2023 (hierna: de "Actie”) zijn de volgende voorwaarden van toepassing:',
	text: [
		'De Actie is alleen af te nemen in de periode van 21 juni 2023 tot en met 21 september 2023 (hierna: de “Actieperiode”) door personen die een persoonlijke vouchercode hebben ontvangen voor de zomeractie 2023 per e-mail.',
		`Met de Actie kun je 3 maanden lang gebruik maken van het Videoland Plus abonnement voor de tijdelijke actieprijs van €5,99 per maand in plaats van het huidige tarief van €10,99 per maand. Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en documentaires van Videoland kunt kijken <strong>zonder reclame</strong>. Je kunt 2 streams tegelijk afspelen en je kunt gebruik maken van de download to go faciliteit. Meer informatie over het Videoland Plus abonnement vind je <a href="${ABOUT_PLUS}" target="_blank" rel="noopener">hier</a>.`,
		'Om gebruik te maken van de Actie dien je jouw persoonlijke actiecode voor de Actie die je per e-mail hebt ontvangen te activeren via: <a href="https://www.videoland.com/activeer/voucher" target="_blank" rel="noopener">https://www.videoland.com/activeer/voucher</a>.',
		'De Actie is uitsluitend van toepassing op het Videoland Plus abonnement en geldt niet voor andere Videoland abonnement soorten.',
		'Je kunt je gekozen Videoland abonnement altijd tussentijds opzeggen (zie voor meer informatie de Gebruikersvoorwaarden). Dat betekent dat je ook korter dan 3 maanden gebruik kunt maken van de tijdelijke actieprijs. Sluit je na je opzegging opnieuw een Videoland abonnement af, dan kun je niet langer gebruikmaken van de actieprijs en zijn de dan geldende reguliere tarieven van toepassing.',
		'Na 3 maanden loopt je Videoland Plus abonnement automatisch door en betaal je het op dat moment geldende reguliere tarief voor Videoland Plus. De exacte startdatum hiervan vind je terug in je Videoland account.',
		'Op deze Actie is de gratis proefperiode van 14 dagen niet van toepassing. Dit betekent dat je als abonnee de tijdelijke maandelijkse actieprijs direct verschuldigd bent aan Videoland vanaf de datum waarop Videoland jouw aanmelding heeft bevestigt en waarop jouw abonnement ingaat.',
		'De Actie is alleen beschikbaar voor nieuwe Videoland abonnees. Bestaande abonnees kunnen hier geen gebruik van maken.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		`Op het Videoland Plus abonnement dat op grond van deze Actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a>  van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing, behalve voor zover in deze actievoorwaarden afwijkende bepalingen gelden.`,
		`Heb je vragen over deze Actie? Neem dan contact op met <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice</a> van Videoland via één van de volgende servicekanelen: Whatsapp naar 06-1445 92 77, telefonisch via 088-123 2456, de chatfunctie, het contactformulier of de social media kanalen vermeld op www.videoland.com.`,
		'Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst.',
	],
};

const FALL_OFFER_CANCELLABLE = {
	title: 'Voorwaarden speciaal aanbod Najaar 2023',
	description:
		'Op de Najaar actie 2023 (hierna: de "Actie”) zijn de volgende actievoorwaarden van toepassing:',
	text: [
		'Via de Actie kun je een 6 maanden Videoland Plus abonnement afsluiten voor € 8,99 per maand i.p.v. € 10,99 per maand.',
		`Je kunt je abonnement tussentijds opzeggen (zie voor meer informatie artikel 3 van de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a>). Dat betekent dat je ook korter dan 6 maanden gebruik kunt maken van het voordelige tarief. Sluit je daarna opnieuw een Videoland abonnement af, dan geldt deze actie niet langer en maak je gebruik van de dan geldende tarieven.`,
		'De Actie kan niet worden gecombineerd met andere acties.',
		'Na 6 maanden loopt het abonnement automatisch door en betaal je vanaf dat moment het tarief dat geldt voor het abonnement dat je hebt gekozen. De exacte startdatum en prijs vind je terug in je Videoland Account.',
		`Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>zonder reclame</strong>. Je kunt 2 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Plus abonnement vind je <a href="${ABOUT_PLUS}" target="_blank" rel="noopener">hier</a>.`,
		`Je kunt van de Actie gebruik maken vanaf 26 oktober 2023 t/m uiterlijk 10 november 2023. Je kunt hierbij gebruik maken van de vouchercode uit de email en het abonnement afsluiten via <a href=${VOUCHER_URL} target="_blank" rel="noopener">https://www.videoland.com/activeer/voucher</a>`,
		'De Actie is uitsluitend van toepassing op het Videoland Plus abonnement en geldt niet voor andere Videoland abonnementen.',
		'De Actie is alleen beschikbaar voor voormalige Videoland abonnees die hierover een e-mail hebben ontvangen.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		`Op het Videoland Plus abonnement dat op grond van deze Actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende bepalingen zijn opgenomen.`,
		`Heb je vragen over deze Actie, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice.</a>`,
		'Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst, voorzien van een datum van ingang.',
	],
};

const FALL_OFFER_NON_CANCELLABLE = {
	title: 'Voorwaarden speciaal aanbod Najaar 2023',
	description:
		'Op de Najaar actie 2023 (hierna: de "Actie”) zijn de volgende actievoorwaarden van toepassing:',
	text: [
		'Via de Actie kun je één van de volgende aanbiedingen afsluiten: 6 maanden Videoland Plus abonnement voor € 8,99 per maand i.p.v. € 10,99 per maand of 6 maanden Videoland Premium abonnement voor € 10,99 per maand i.p.v. € 12,99 per maand.',
		'Het abonnement is niet tussentijds opzegbaar.',
		'De Actie kan niet worden gecombineerd met andere acties.',
		'Na 6 maanden loopt het abonnement automatisch door en betaal je vanaf dat moment het tarief dat geldt voor het abonnement dat je hebt gekozen. De exacte startdatum en prijs vind je terug in je Videoland Account.',
		`Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>zonder reclame</strong>. Je kunt 2 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Plus abonnement vind je <a href="${ABOUT_PLUS}" target="_blank" rel="noopener">hier</a>.`,
		`Het Videoland Premium abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>zonder reclame</strong>. Je kunt 4 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Premium abonnement vind je <a href="${ABOUT_PREMIUM}" target="_blank" rel="noopener">hier</a>.`,
		`Je kunt van de Actie gebruik maken vanaf 26 oktober 2023 t/m uiterlijk 10 november 2023. Je kunt hierbij gebruik maken van de vouchercode uit de email en het abonnement afsluiten via <a href=${VOUCHER_URL} target="_blank" rel="noopener">https://www.videoland.com/activeer/voucher</a>`,
		'De Actie is uitsluitend van toepassing op het Videoland Plus of Videoland Premium 6 maanden abonnement en geldt niet voor andere Videoland abonnementen.',
		'De Actie is alleen beschikbaar voor voormalige Videoland abonnees die hierover een e-mail hebben ontvangen.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		`Op het Videoland Plus en Videoland Premium abonnement dat op grond van deze Actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende bepalingen zijn opgenomen.`,
		`Heb je vragen over deze Actie, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice.</a>`,
		'Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst, voorzien van een datum van ingang.',
	],
};

export const RATEPLAN_WITH_TERMS = [
	{
		planId: 'Essential6M299', //voucher rate plan id has different format
		terms: NPL_WINBACK_TERMS,
	},
	{
		planId: 'SVOD_STANDARD_12M_LTC',
		terms: LTC_12_MAANDEN,
	},
	{
		planId: 'Essential12M2EurDiscount',
		terms: NPL_2024,
	},
	{
		planId: 'Essential12M2EurDiscount2',
		terms: NPL_2024_2,
	},
	{
		planId: 'Plus12M2EurDiscount',
		terms: NPL_2023,
	},
	{
		planId: 'EssentialTermed12M1EurDiscount',
		terms: ANNUAL_SUBSCRIPTION_PROMOTION_2024,
	},
	{
		planId: 'PlusTermed12M1EurDiscount',
		terms: ANNUAL_SUBSCRIPTION_PROMOTION_2024,
	},
	{
		planId: 'PremiumTermed12M1EurDiscount',
		terms: ANNUAL_SUBSCRIPTION_PROMOTION_2024,
	},
	{
		planId: 'PremiumTermed12M2EurDiscount',
		terms: ANNUAL_SUBSCRIPTION_PROMOTION_2024,
	},
	{
		planId: 'PlusLongTermContract3M',
		terms: MKT_SUMMER_OFFER,
	},
	{
		planId: 'Standard3M-50Percent',
		terms: MKT_SUMMER_OFFER_50_PERCENT,
	},
	{
		planId: 'Plus6M2EurDiscount',
		terms: FALL_OFFER_CANCELLABLE,
	},
	{
		planId: 'Premium6M2EurDiscountNonCancellable',
		terms: FALL_OFFER_NON_CANCELLABLE,
	},
	{
		planId: 'Plus6M2EurDiscountNonCancellable',
		terms: FALL_OFFER_NON_CANCELLABLE,
	},
];
