import { useEffect, useReducer } from 'react';
import { reportCustomError } from '../helpers/realUserMonitoring';
import { getPaymentPlan, DYNAMIC_FILE_ERRORS } from '../helpers/payment';

const initialState = {
	isLoading: false,
	isError: false,
	isNotFound: false,
	isSuccess: false,
	isPristine: true,
	plan: {},
};

function activePlanReducer(state, action) {
	switch (action.type) {
		case 'loading':
			return { ...initialState, isPristine: false, isLoading: true };
		case 'completed':
			return { ...initialState, isPristine: false, isSuccess: true, plan: action.plan };
		case 'error':
			return { ...initialState, isPristine: false, isError: true };
		case 'notfound':
			return { ...initialState, isPristine: false, isNotFound: true };
		case 'reset':
			return { ...initialState };
	}
}

export default function usePlan(slug) {
	const [state, dispatch] = useReducer(activePlanReducer, initialState);

	useEffect(() => {
		if (__CLIENT__ && slug) {
			dispatch({ type: 'loading' });

			getPaymentPlan(slug)
				.then((plan) => dispatch({ type: 'completed', plan }))
				.catch(onError);

			// eslint-disable-next-line no-inner-declarations
			function onError(errMessage) {
				if (errMessage === DYNAMIC_FILE_ERRORS.NOT_FOUND) {
					const errorMessage = `No plan with slug ${slug} found`;
					console.error(errorMessage);
					dispatch({ type: 'notfound' });
				} else {
					const errorMessage = `Something wen't wrong: ${errMessage}`;
					reportCustomError({ name: 'activation', error: slug, message: errorMessage });
					dispatch({ type: 'error' });
				}
			}

			return () => dispatch({ type: 'reset' });
		}
	}, [slug]);

	return state;
}
