import reduce, { defaultState, errorState, pendingState } from '../../helpers/redux';
import {
	ACCOUNT_CLEAR,
	ACCOUNT_RESET_PASSWORD,
	ACCOUNT_UPDATE_PASSWORD,
	DEFER_LOGIN,
	DEFER_LOGIN_RESET,
	GIGYA_CLOSE_POPUP,
	GIGYA_FINALIZE_REGISTRATION,
	GIGYA_GET_ACCOUNT_INFO,
	GIGYA_LINK_ACCOUNT,
	GIGYA_LOGIN,
	OVERWRITE_REDIRECT_URL,
	CLEAR_REDIRECT_URL,
	OVERWRITE_UID,
	REDUX_FORM_CHANGE,
	VIDEOLAND_LOGIN,
} from '../../constants/ActionTypes';
import {
	ACCOUNT_DISABLED,
	ACCOUNT_INACTIVE,
	ACCOUNT_INCOMPLETE,
	ACCOUNT_LOCKED,
	ACCOUNT_NOT_FINALIZED,
	ACCOUNT_FORCED_PWD_RESET,
	EMAIL_NOT_FOUND,
	EXISTING_ACCOUNT,
	GIGYA_ACCOUNT_PENDING_REGISTRATION,
	GIGYA_ACCOUNT_PENDING_VERIFICATION,
	GIGYA_ACCOUNT_TEMPORARILY_LOCKED,
	GIGYA_ACCOUNT_DISABLED,
	GIGYA_GET_ACCOUNT_INFO_ERROR,
	GIGYA_INVALID_ARGUMENT,
	GIGYA_INVALID_LOGIN_ID,
	GIGYA_LOGIN_IDENTIFIER_EXISTS,
	GIGYA_ACCOUNT_PENDING_PWD_CHANGE,
	UNKNOWN_ACCOUNT_RESET_ERROR,
	THIRD_PARTY_COOKIES_BLOCKED,
	UNAUTHORIZED_USER,
	UNKNOWN_GIGYA_LOGIN_ERROR,
	UNVERIFIED_ACCOUNT,
	WRONG_PASSWORD,
} from '../../constants/ErrorTypes';
import { reportCustomError } from '../../helpers/realUserMonitoring';
import { removeDomain } from '../../helpers/url';

const initialState = {
	...defaultState,
	errorCode: null,
	gigyaErrorCode: null,
	gigyaUser: {},
	vlId: null,
	email: null,
	regToken: null,
	loginProvider: null,
	redirectUrl: null,
	policies: {
		pending: false,
		payload: {},
	},
	accountInfo: defaultState,
	deferredLogin: null,
};

const actionsMap = {
	[REDUX_FORM_CHANGE]: (state, action) => {
		if (action.meta.field === 'email') {
			return { email: action.payload }; // Store any user-entered email value for prefilling later
		}
		return {};
	},
	[DEFER_LOGIN]: (state, action) => ({ deferredLogin: action.payload }),
	[DEFER_LOGIN_RESET]: () => ({ deferredLogin: null }),

	[GIGYA_GET_ACCOUNT_INFO + '_SUCCESS']: (state, action) => ({
		...initialState,
		redirectUrl: removeDomain(state.redirectUrl),
		gigyaUser: action.payload,
		accountInfo: defaultState,
	}),
	[GIGYA_GET_ACCOUNT_INFO + '_PENDING']: (state) => ({ ...state, ...pendingState }),
	[GIGYA_GET_ACCOUNT_INFO + '_ERROR']: loginError,
	[GIGYA_LINK_ACCOUNT + '_SUCCESS']: () => defaultState,
	[GIGYA_LINK_ACCOUNT + '_PENDING']: () => ({ ...pendingState }),
	[GIGYA_LINK_ACCOUNT + '_ERROR']: loginError,

	[GIGYA_LOGIN + '_SUCCESS']: (state, action) => ({
		...defaultState,
		errorCode: null,
		gigyaUser: action.payload,
	}),
	[GIGYA_LOGIN + '_PENDING']: (state, action) => ({
		...pendingState,
		email: action.payload.email,
		loginProvider: action.payload.loginProvider,
	}),
	[GIGYA_LOGIN + '_ERROR']: loginError,

	[GIGYA_CLOSE_POPUP]: () => defaultState,

	[GIGYA_FINALIZE_REGISTRATION + '_SUCCESS']: (state) => ({
		...defaultState,
		gigyaUser: state.gigyaUser,
	}),
	[GIGYA_FINALIZE_REGISTRATION + '_ERROR']: (state, { error: { errorCode } }) => ({
		...defaultState,
		errorCode,
		isNotVerified: errorCode === 206002,
		...errorState,
	}),

	[ACCOUNT_UPDATE_PASSWORD + '_PENDING']: () => pendingState,
	[ACCOUNT_UPDATE_PASSWORD + '_SUCCESS']: () => defaultState,
	[ACCOUNT_UPDATE_PASSWORD + '_ERROR']: () => errorState,

	[ACCOUNT_RESET_PASSWORD + '_PENDING']: () => pendingState,
	[ACCOUNT_RESET_PASSWORD + '_SUCCESS']: (state, action) => ({
		...defaultState,
		email: action.originalPayload.email,
	}),
	[ACCOUNT_RESET_PASSWORD + '_ERROR']: (state, action) => ({
		...errorState,
		errorCode:
			action.error.code === 'EmailNotFound'
				? EMAIL_NOT_FOUND
				: `${UNKNOWN_ACCOUNT_RESET_ERROR}_${action.error.code}`,
	}),
	[OVERWRITE_UID + '_SUCCESS']: (state, action) => {
		const { UID, UIDSignature, signatureTimestamp } = action.payload.newUserData.userInfo;
		return {
			gigyaUser: {
				...state.gigyaUser,
				UID,
				UIDSignature,
				signatureTimestamp,
			},
		};
	},
	[OVERWRITE_UID + '_ERROR']: (state, action) => ({
		errorCode: action.error.errorCode,
		...errorState,
	}),
	[OVERWRITE_REDIRECT_URL]: (state, action) => ({
		redirectUrl: removeDomain(action.payload.redirectUrl),
	}),
	[CLEAR_REDIRECT_URL]: () => ({
		redirectUrl: null,
	}),
	[VIDEOLAND_LOGIN + '_SUCCESS']: (state, action) => ({
		vlId: action.payload.vlHash,
		expires: action.payload.expires,
	}),
	[VIDEOLAND_LOGIN + '_ERROR']: (state, action) => ({
		errorCode: /invalid videoland uid/i.test(action.message) ? 1002 : 1001,
		...errorState,
	}),
	[ACCOUNT_CLEAR]: () => initialState,
};

function loginError(state, action) {
	const { errorCode: gigyaErrorCode, regToken, loginID } = action.error;

	const translatedGigyaError = translateGigyaError(action.error);
	if (translatedGigyaError != UNAUTHORIZED_USER || translatedGigyaError != ACCOUNT_INACTIVE) {
		reportCustomError({
			name: 'gigya_login_error',
			error: action.error,
			message: translatedGigyaError,
		});
	}

	return {
		...state,
		gigyaErrorCode,
		errorCode: translatedGigyaError,
		regToken: regToken || state.regToken,
		email: loginID || state.email,
		gigyaUser: {
			...state.gigyaUser,
			...action.error,
		},
		...errorState,
	};
}

const translateGigyaError = (gigyaError) => {
	if (!gigyaError) return null;
	switch (gigyaError.errorCode) {
		case GIGYA_GET_ACCOUNT_INFO_ERROR:
			return UNAUTHORIZED_USER;
		case GIGYA_ACCOUNT_PENDING_REGISTRATION:
			return ACCOUNT_INCOMPLETE;
		case GIGYA_ACCOUNT_PENDING_VERIFICATION:
			return UNVERIFIED_ACCOUNT;
		case GIGYA_ACCOUNT_DISABLED:
			return ACCOUNT_DISABLED;
		case GIGYA_LOGIN_IDENTIFIER_EXISTS:
			return EXISTING_ACCOUNT;
		case GIGYA_INVALID_LOGIN_ID:
			return WRONG_PASSWORD;
		case GIGYA_ACCOUNT_TEMPORARILY_LOCKED:
			return ACCOUNT_LOCKED;
		case GIGYA_INVALID_ARGUMENT:
			return THIRD_PARTY_COOKIES_BLOCKED;
		case GIGYA_ACCOUNT_PENDING_PWD_CHANGE:
			return ACCOUNT_FORCED_PWD_RESET;
		default:
		// handled later
	}
	if (!gigyaError.isActive) return ACCOUNT_INACTIVE;
	if (!gigyaError.isRegistered) return ACCOUNT_NOT_FINALIZED;
	return `${UNKNOWN_GIGYA_LOGIN_ERROR}_${gigyaError.errorCode}`;
};

export default reduce(initialState, actionsMap);
