import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { hasValidVideolandCookie } from '../helpers/authentication';
import { useHistory, useLocation } from 'react-router';
import { cookie } from '../helpers/storage';
import { AUTOLOGIN_COOKIE_BR, AUTH_DOMAIN } from '../config';
import { getConfig } from '../apiConfig';
import useSearchParams from './useSearchParams';
import { PARAM_NAMES } from '../constants/Checkout';
import { ROUTE_MESSAGES_NEW_PLATFORM } from '../constants/Routes';
import { isRouteAccessible } from '../helpers/routes';
import { getDateNextYear } from '../helpers/datetime';
import { redirectIfNoActiveSubscription } from '../helpers/redirect';

export function redirectToBedrock() {
	const { bedrock } = getConfig();
	window.location.href = bedrock;
}

export function autoLoginBR(expires) {
	cookie.set(AUTOLOGIN_COOKIE_BR, true, {
		domain: AUTH_DOMAIN,
		expires: expires ? new Date(expires) : getDateNextYear(),
		httpOnly: false,
		path: '/',
		secure: true,
	});

	redirectToBedrock();
}

export function useRedirectUser({ skip }) {
	const { expires } = useSelector((state) => state.authentication);
	const router = useSelector((state) => state.router);
	const location = useLocation();
	const subscription = useSelector((state) => state.subscription.details);
	const history = useHistory();
	const { getSearchParam } = useSearchParams();
	const { BEDROCK_REDIRECT_REASON } = PARAM_NAMES;
	const bedrockRedirectReasonQueryParam = getSearchParam(BEDROCK_REDIRECT_REASON);
	const isLoggedin = hasValidVideolandCookie();
	const hasActiveSubscription = subscription.data?.status?.toUpperCase() === 'ACTIVE';

	useEffect(() => {
		if (isLoggedin) {
			if (
				hasActiveSubscription &&
				!bedrockRedirectReasonQueryParam &&
				!isRouteAccessible(location.pathname, hasActiveSubscription, true) &&
				!skip
			) {
				autoLoginBR(expires);
				history.replace(ROUTE_MESSAGES_NEW_PLATFORM);
			} else if (subscription.success && !hasActiveSubscription && !skip) {
				redirectIfNoActiveSubscription({
					subscriptions: subscription.data,
					router,
				});
			}
		}
		//if we add router that is a missing dependencies redirectIfNoActiveSubscription create an infinite loop
	}, [
		isLoggedin,
		location.pathname,
		expires,
		history,
		bedrockRedirectReasonQueryParam,
		skip,
		hasActiveSubscription,
		subscription,
	]);
}
