import reduce from '../../helpers/redux';
import {
	GIGYA_INIT_REGISTRATION,
	GIGYA_REGISTER,
	GIGYA_FINALIZE_REGISTRATION,
	RESEND_VERIFICATION_CODE,
} from '../../constants/ActionTypes';
import { errorState, pendingState, defaultState, successState } from '../../helpers/redux';
import {
	GIGYA_UNIQUE_IDENTIFIER_EXISTS,
	EMAIL_ALREADY_EXISTS,
	UNVERIFIED_ACCOUNT,
	UNKNOWN_GIGYA_REGISTRATION_ERROR,
	THIRD_PARTY_COOKIES_BLOCKED,
	GIGYA_ACCOUNT_PENDING_VERIFICATION,
	GIGYA_INVALID_ARGUMENT,
} from '../../constants/ErrorTypes';
import _ from 'lodash';

const initialState = {
	pending: false,
	error: false,
	regToken: null,
	errorCode: null,
	gigyaErrorCode: null,
	resendVerificationEmail: {
		success: null,
		pending: null,
		error: null,
	},
};

const actionsMap = {
	[GIGYA_INIT_REGISTRATION + '_PENDING']: () => ({
		...pendingState,
		regToken: null,
	}),
	[GIGYA_INIT_REGISTRATION + '_SUCCESS']: (state, action) => ({
		pending: false,
		regToken: action.payload.regToken,
	}),
	[GIGYA_INIT_REGISTRATION + '_ERROR']: () => ({
		...errorState,
	}),

	[GIGYA_REGISTER + '_SUCCESS']: (state, action) => ({
		...defaultState,
		regToken: action.payload.regToken,
	}),
	[GIGYA_REGISTER + '_ERROR']: registerError,

	[GIGYA_FINALIZE_REGISTRATION + '_SUCCESS']: () => ({ ...defaultState }),
	[GIGYA_FINALIZE_REGISTRATION + '_ERROR']: registerError,

	[RESEND_VERIFICATION_CODE + '_PENDING']: () => ({
		resendVerificationEmail: {
			...pendingState,
		},
	}),

	[RESEND_VERIFICATION_CODE + '_SUCCESS']: () => ({
		resendVerificationEmail: {
			...successState,
		},
	}),

	[RESEND_VERIFICATION_CODE + '_ERROR']: () => ({
		resendVerificationEmail: {
			...errorState,
		},
	}),
};

function registerError(state, action) {
	const { errorCode: gigyaErrorCode, validationErrors, regToken } = action.error;
	const validationErrorCode = _.get(validationErrors, '0.errorCode', null);
	return {
		errorCode: translateGigyaError(validationErrorCode || gigyaErrorCode),
		gigyaErrorCode,
		regToken,
		...errorState,
	};
}

const translateGigyaError = (errorCode) => {
	if (errorCode === GIGYA_ACCOUNT_PENDING_VERIFICATION) {
		return UNVERIFIED_ACCOUNT;
	}

	if (errorCode === GIGYA_UNIQUE_IDENTIFIER_EXISTS) {
		return EMAIL_ALREADY_EXISTS;
	}

	// The only identified cause for an invalid argument error is the client
	// has blocked third-party cookies (Ghostery in particular).
	// https://developers.gigya.com/display/GD/Blocked+Third-Party+Cookies
	if (errorCode === GIGYA_INVALID_ARGUMENT) {
		return THIRD_PARTY_COOKIES_BLOCKED;
	}

	return `${UNKNOWN_GIGYA_REGISTRATION_ERROR}_${errorCode}`;
};

export default reduce(initialState, actionsMap);
