const defaultConsent = ['C0001', 'C0002'];

export function getConsentCategories() {
	if (!window.OnetrustActiveGroups) {
		return defaultConsent;
	}

	try {
		return window.OnetrustActiveGroups.split(',').filter((c) => c); // Remove all the empty values.
	} catch (error) {
		return defaultConsent;
	}
}

export function isAdvertisingAndBehaviorConsentGiven() {
	const categories = getConsentCategories();
	return categories.some((c) => c === 'C0004');
}
