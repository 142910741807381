export const FLAG_CONTENT = {};

export const FLAG_KEYS = {
	REPEAT_TITLE: 'repeat_title',
	BUTTON_TIERS: 'button_tiers',
	OFFER_BASED_ON_EXIT_REASON: 'offer_based_on_exit_reason',
	PRIVACY_STEP_NEWSLETTER: 'privacy_step_newsletter',
	CONTINUE_WATCHING_CTA: 'continue_watching_cta',
	NON_CLICKABLE_CATEGORY: 'non_clickable_category',
};

export const FLAG_VARIANTS = {
	CONTROL: 'control',
	VARIANT_A: 'variant_a',
	VARIANT_B: 'variant_b',
	VARIANT_C: 'variant_c',
};

export const JIRA_TESTING_TICKET_NUMBERS = {
	REPEAT_TITLE: 521,
};
