export const FEATURE_FLAG_IDS = {
	SHOW_TIERS: 'show_tiers',
	SHOW_OPEN_INVOICES: 'show_open_invoices',
	LOGOUT_ALL: 'logout_all',
	PRIVACY_STEP_NEWSLETTER: 'privacy_step_newsletter',
};

export const FEATURE_FLAG_BROWSER_BASE = 'vl_flag';
export const FEATURE_FLAG_BROWSER_VALUES = {
	ON: 'on',
	OFF: 'off',
};

export const FEATURE_FLAG_OPEN_INVOICES_KEY = createFeatureFlagKey(
	FEATURE_FLAG_IDS.SHOW_OPEN_INVOICES
);
export const FEATURE_FLAG_PRIVACY_STEP_NEWSLETTER = createFeatureFlagKey(
	FEATURE_FLAG_IDS.PRIVACY_STEP_NEWSLETTER
);
export const FEATURE_FLAG_LOGOUT_ALL = createFeatureFlagKey(FEATURE_FLAG_IDS.LOGOUT_ALL);

export const TRANSACTION_ID_SESSION = 'vl-pushed-transaction-id';

function createFeatureFlagKey(postFix) {
	return `${FEATURE_FLAG_BROWSER_BASE}_${postFix}`;
}
