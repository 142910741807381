export const ACCOUNT_INACTIVE = 'ACCOUNT_INACTIVE';
export const ACCOUNT_INCOMPLETE = 'ACCOUNT_INCOMPLETE';
export const ACCOUNT_LOCKED = 'ACCOUNT_LOCKED';
export const ACCOUNT_DISABLED = 'ACCOUNT_DISABLED';
export const ACCOUNT_NOT_FINALIZED = 'ACCOUNT_NOT_FINALIZED';
export const ACCOUNT_FORCED_PWD_RESET = 'ACCOUNT_FORCED_PWD_RESET';
export const EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND';
export const EMAIL_CHECK_UNKNOWN_ERROR = 'EMAIL_CHECK_UNKNOWN_ERROR';
export const EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS';
export const EXISTING_ACCOUNT = 'EXISTING_ACCOUNT';
export const THIRD_PARTY_COOKIES_BLOCKED = 'THIRD_PARTY_COOKIES_BLOCKED';
export const UNKNOWN_GIGYA_REGISTRATION_ERROR = 'UNKNOWN_GIGYA_REGISTRATION_ERROR';
export const UNKNOWN_GIGYA_LOGIN_ERROR = 'UNKNOWN_GIGYA_LOGIN_ERROR';
export const UNKNOWN_ACCOUNT_RESET_ERROR = 'UNKNOWN_ACCOUNT_RESET_ERROR';
export const UNVERIFIED_ACCOUNT = 'UNVERIFIED_ACCOUNT';
export const UNAUTHORIZED_USER = 'UNAUTHORIZED_USER';
export const TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS';
export const WRONG_PASSWORD = 'WRONG_PASSWORD';
export const VOUCHER_ACCEPTED = 'VOUCHER_ACCEPTED';
export const VOUCHER_INVALID = 'VOUCHER_INVALID';
export const VOUCHER_ALREADY_USED = 'VOUCHER_ALREADY_USED';
export const VOUCHER_EXPIRED = 'VOUCHER_EXPIRED';
export const ERROR = 'ERROR';
export const PAGE_NOT_FOUND = 'PAGE_NOT_FOUND';

// Response codes available here:
// https://developers.gigya.com/display/GD/Response+Codes+and+Errors

export const GIGYA_SUCCESS = 0;
export const GIGYA_ACCOUNT_PENDING_REGISTRATION = 206001;
export const GIGYA_ACCOUNT_PENDING_VERIFICATION = 206002;
export const GIGYA_INVALID_ARGUMENT = 400006;
export const GIGYA_REQUEST_EXPIRED = 403002;
export const GIGYA_UNVERIFIED_USER = 403013;
export const GIGYA_ACCOUNT_TEMPORARILY_LOCKED = 403120;
export const GIGYA_INVALID_LOGIN_ID = 403042;
export const GIGYA_UNIQUE_IDENTIFIER_EXISTS = 400003;
export const GIGYA_LOGIN_IDENTIFIER_EXISTS = 403043;
export const GIGYA_GET_ACCOUNT_INFO_ERROR = 403005;
export const GIGYA_ACCOUNT_DISABLED = 403041;
export const GIGYA_ACCOUNT_PENDING_PWD_CHANGE = 403100;
