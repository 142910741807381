import React from 'react';
import loadable from '@loadable/component';
import { addReducer } from '../../../store';

export const Login = (props) => {
	const LoginComponent = loadable(() => import('./Login.container'));
	import('redux-form').then(({ reducer }) => addReducer('form', reducer));

	return <LoginComponent {...props} />;
};
