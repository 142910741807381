export function transformPathnameForMonitoring(pathname) {
	const matches = [
		'/activeer/abonnement/',
		'/geactiveerd/abonnement/',
		'/mijn-abonnement/factuur/',
		'/partner/',
	];
	for (const match of matches) {
		if (pathname.includes(match) && !pathname.endsWith(match)) {
			const itemIndex = pathname.indexOf(match);
			if (itemIndex !== -1) {
				let newPath = pathname.slice(0, itemIndex + match.length);
				return newPath + ':slug';
			}
		}
	}
	return pathname;
}
