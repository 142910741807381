import React from 'react';
import { Route } from 'react-router-dom';

// This is a simple wrapper component that overrides the http status code in the server response. This is only for
// server side rendered components. It is the recommended router v4 way.
// see https://reacttraining.com/react-router/web/guides/server-rendering
const Status = ({ code, children }) => (
	<Route
		render={({ staticContext }) => {
			if (staticContext) staticContext.status = code;
			return children;
		}}
	/>
);

export default Status;
