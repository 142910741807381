import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
import api from './middleware/api';
import * as clients from './api';
import reducers from './reducers';
import analytics from './middleware/analytics';
import history from './history';

const middlewares = [thunk, api(clients)];

let composeEnhancers = compose;

if (__CLIENT__) {
	middlewares.push(analytics);
	if ('__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in window) {
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ latency: 0 });
	}
}

if (__DEBUG__) {
	const logger = require('./middleware/logger');
	middlewares.push(logger.default);
}

export const initialReducers = { ...reducers, router: connectRouter(history) };

export default (initialState = {}) => {
	return createStore(
		combineReducers(initialReducers),
		initialState,
		composeEnhancers(applyMiddleware(routerMiddleware(history), ...middlewares))
	);
};
