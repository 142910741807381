import { getEndpointUrl } from '../../config';
import mutationRevokeCancellation from '../../graphql/mutationRevokeCancellation.graphql';
import { dispatchApiError, dispatchApiSuccess } from '../../helpers/redux';
import { SUBSCRIPTION_REACTIVATE } from '../../constants/ActionTypes';
import { isFunction, reportError, startUseCase } from '../../helpers/realUserMonitoring';
import { fetchSubscriptionTier } from '../profileActions';
import { OperationNames, SpanNames } from '../../constants/OtelSpans';
import { OperationTelemetry } from '@rtl_nl/rtl-otel-js-web';

const reactivateSubscriptionTelemetry = new OperationTelemetry(
	OperationNames.REACTIVATE_SUBSCRIPTION
);

export function createReactivateSubscriptionAction(subscriptionService) {
	return function reactivateSubscriptionThunk(order_name) {
		function dispatchReactivateSubscription({ endSpan, recordSpanError } = {}) {
			return (dispatch) => {
				const action = { payload: { order_name } };
				return new Promise((resolve, reject) => {
					subscriptionService(
						{
							url: getEndpointUrl('graphql_path'),
							mutation: mutationRevokeCancellation,
						},
						onReactivateSubscriptionSuccess,
						onReactivateSubscriptionError
					);

					function onReactivateSubscriptionError(err) {
						dispatchApiError({ dispatch, type: SUBSCRIPTION_REACTIVATE, action, err });
						reportError(err);
						if (isFunction(recordSpanError)) recordSpanError(err);
						if (isFunction(endSpan)) endSpan();
						resolve();
					}

					function onReactivateSubscriptionSuccess(result) {
						if (result.data?.revokeCancellation?.__typename === 'RevokeCancellationSuccess') {
							dispatchApiSuccess({
								dispatch,
								type: SUBSCRIPTION_REACTIVATE,
								action,
								payload: result,
							});
							dispatch(fetchSubscriptionTier(reactivateSubscriptionTelemetry))
								.then(resolve)
								.catch(reject);
							if (isFunction(endSpan)) endSpan();
						} else {
							const err = { message: result.reason };
							if (isFunction(recordSpanError)) recordSpanError(err);
							if (isFunction(endSpan)) endSpan();
							dispatchApiError({
								dispatch,
								type: SUBSCRIPTION_REACTIVATE,
								action,
								err: err,
							});
							resolve();
						}
					}
				});
			};
		}

		return startUseCase({
			telemetry: reactivateSubscriptionTelemetry,
			spanName: SpanNames.SUBSCRIPTION_REACTIVATE_ATTEMPT,
			callBack: dispatchReactivateSubscription,
			isRootSpan: true,
		});
	};
}
