import React from 'react';
import { ONBOARDING_EMAIL_VERIFIED_PAYMENT_BUTTON } from '../../../../constants/Copy';
import Button from '../../../common/buttons/onboarding/OnboardingButton';
import { initiateTrackStructEvent } from '../../../analytics/Snowplow';
import {
	EVT_FUNNEL,
	EVT_SUBSCRIPTION_CONFIRM,
	EVT_ELEMENT_INTERACTION,
	EVT_SUBSCRIPTION_VERIFICATION,
	EVT_ELEMENT_TYPE_BUTTON,
} from '../../../../constants/SnowplowEvents';

export const EmailVerifiedProceedButton = ({ proceedUrl, testId }) => {
	return (
		<div className="email-verified__proceed-button">
			<Button
				testId={testId}
				href={proceedUrl}
				onClick={() => {
					initiateTrackStructEvent({
						category: EVT_FUNNEL,
						action: EVT_SUBSCRIPTION_CONFIRM,

						rtlElementContext: {
							elementInteraction: EVT_ELEMENT_INTERACTION,
							elementGroup: EVT_SUBSCRIPTION_VERIFICATION,
							elementType: EVT_ELEMENT_TYPE_BUTTON,
							elementName: EVT_SUBSCRIPTION_CONFIRM,
							elementLabel: ONBOARDING_EMAIL_VERIFIED_PAYMENT_BUTTON,
						},
					});
				}}
			>
				{ONBOARDING_EMAIL_VERIFIED_PAYMENT_BUTTON}
			</Button>
		</div>
	);
};
