import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { getConfig } from '../apiConfig';
import fetch from 'isomorphic-fetch';
import { reportError } from '../helpers/realUserMonitoring';
import { SpanKind, SpanStatusCode, trace } from '@rtl_nl/rtl-otel-js-web';
import { ApolloLink, concat } from 'apollo-link';

/* This is a fix for older browsers that don't support the full Promise spec and more specific 'finally'.
 * Apollo has a bug where it doesn't compile properly for older browsers. See https://github.com/apollographql/apollo-client/issues/6381
 */
async function loadFinallyPolyfill() {
	const hasSupportForFinally = !!Promise.prototype.finally;

	if (!hasSupportForFinally) {
		await import('core-js/features/promise');
	}
	return Promise.resolve();
}

const defaultApiHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
	'Cache-Control': 'no-cache',
};

export default createGenericApiClientWithDefaults;

function createGenericApiClientWithDefaults(configHostKey, useRestLink = false) {
	return createGenericApiClient(
		configHostKey,
		ApolloClient,
		InMemoryCache,
		fetch,
		reportError,
		useRestLink
	);
}

export function createGenericApiClient(
	configHostKey,
	ApolloClient,
	InMemoryCache,
	fetch,
	reportError,
	useRestLink
) {
	const apolloClientInstance = createApolloClient(configHostKey, useRestLink);

	return { performRequest, getApolloClientInstance: () => apolloClientInstance };

	function performRequest(options, successCallback, errorCallBack) {
		if (options.query || options.mutation) {
			performGraphQLRequest(apolloClientInstance, options, successCallback, errorCallBack);
		} else {
			performRestRequest(options, successCallback, errorCallBack);
		}
	}

	async function performGraphQLRequest(
		apolloClientInstance,
		{ query, mutation, variables, fetchPolicy },
		successCallback,
		errorCallBack
	) {
		let span = undefined;
		let context = undefined;
		try {
			await loadFinallyPolyfill();
			const client = apolloClientInstance;
			const result = mutation
				? await client.mutate({ mutation, variables, context })
				: await client.query({ query, variables, fetchPolicy, context });

			if (result?.errors?.length > 0) {
				span?.setAttribute('error.message', result.errors[0]);
				span?.setStatus({ code: SpanStatusCode.ERROR });
			}
			// if an exception wasn't thrown, the request was successful
			successCallback(result);
		} catch (e) {
			addExceptionAttributesToSpan(span, e);
			reportError(e);
			errorCallBack(e);
			span?.setAttribute('error.message', e.message);
			span?.setStatus({ code: SpanStatusCode.ERROR });
		} finally {
			span?.end();
		}
	}

	async function performRestRequest(
		{ url, method, body, headers },
		successCallback,
		errorCallback
	) {
		try {
			await loadFinallyPolyfill();
			const config = getConfig();
			const base = config[configHostKey];
			const rawResponse = await fetch(base + url, {
				method,
				body: body ? JSON.stringify(body) : undefined,
				credentials: 'include',
				headers: {
					...defaultApiHeaders,
					...headers,
				},
			});
			const parsedResponse = await parseRestResponse(rawResponse);
			successCallback(parsedResponse);
		} catch (e) {
			reportError(e);
			errorCallback(e);
		}
	}

	function addExceptionAttributesToSpan(span, exception) {
		if (!span || !exception) {
			return;
		}
		span.setAttribute(
			'http.status_code',
			exception.response?.status || exception.networkError?.statusCode || 500
		);
		span.setAttribute('error.message', exception.message);
		span.setAttribute('error.stack', exception.stack);
		span.setAttribute('error.type', exception.name);
	}

	function createApolloClient(configHostKey, useRestLink) {
		const config = getConfig();
		if (!config[configHostKey]) throw new Error(`Can't find host with key ${configHostKey}`);

		if (useRestLink) {
			const restLink = new RestLink({
				uri: config[configHostKey],
				credentials: 'include',
				headers: {
					'videoland-platform': 'videoland',
				},
			});

			return new ApolloClient({
				link: restLink,
				cache: new InMemoryCache(),
			});
		}

		const httpLink = new HttpLink({
			uri: config[configHostKey] + '/graphql',
			credentials: 'include',
		});

		return new ApolloClient({
			link: concat(createSpanLink, httpLink),
			name: 'apollo_' + configHostKey,
			cache: new InMemoryCache(),
		});
	}
}

async function parseRestResponse(response) {
	if (~[202, 204, 205].indexOf(response.status)) {
		return null;
	} else if (response.status >= 200 && response.status < 300) {
		return await response.json().catch(() => {});
	} else {
		const body = await response.json();
		const err = new Error(body.message);
		err.body = body;
		err.response = response;
		throw err;
	}
}

// Create Span Link
export const createSpanLink = new ApolloLink((operation, forward) => {
	const tracer = trace.getTracer('@apollo/client');
	const span = tracer.startSpan('GraphQL request', {
		attributes: {
			'graphql.operation_name': operation.operationName,
		},
		kind: SpanKind.CLIENT,
	});
	const spanContext = span.spanContext();

	operation.setContext({
		span,
		headers: { traceparent: `00-${spanContext.traceId}-${spanContext.spanId}-01` },
	});

	return forward(operation).map((data) => {
		span?.end();
		return data;
	});
});
