import { pad } from './text';
import { MONTH_NAMES } from '../constants/Dates';
import { HOUR } from '../constants/Time';
import { parse } from 'iso8601-duration';

export const addDays = (date, days) => {
	if (!date) return null;
	const dateObj = toDate(date);
	dateObj.setDate(dateObj.getDate() + days);
	return dateObj;
};

export const friendlyDate = (unfriendlyDate) => {
	if (!unfriendlyDate) return null;
	const dateObj = toDate(unfriendlyDate);
	return dateObj.getDate() + ' ' + MONTH_NAMES[dateObj.getMonth()] + ' ' + dateObj.getFullYear();
};

export const simpleDate = (unfriendlyDate) => {
	if (!unfriendlyDate) return null;
	const dateObj = toDate(unfriendlyDate);
	const month = pad(dateObj.getMonth() + 1);
	return pad(dateObj.getDate()) + '-' + month + '-' + dateObj.getFullYear();
};

export const splitDate = (date) => {
	const chunks = date.split('-');
	return {
		day: pad(chunks[2]),
		month: pad(chunks[1]),
		year: chunks[0],
	};
};

export const friendlyDuration = (date) => {
	const duration = parse(date);
	if (duration.days > 0) {
		return `${duration.days} ${duration.days > 1 ? 'dagen' : 'dag'}`;
	} else if (duration.weeks > 0) {
		return `${duration.weeks} ${duration.weeks > 1 ? 'weken' : 'week'}`;
	} else if (duration.months > 0) {
		return `${duration.months} ${duration.months > 1 ? 'maanden' : 'maand'}`;
	} else if (duration.years > 0) {
		return `${duration.years} jaar`;
	} else {
		return null;
	}
};

export const colonTimeToSeconds = (time) => {
	const chunks = time.split(':');
	return +chunks[0] * 60 * 60 + +chunks[1] * 60 + +chunks[2];
};

export const hoursFromNow = (date) => Math.round((date - new Date()) / HOUR);

export const secondsToMinutes = (seconds) => Math.floor(seconds / 60);

export const getTimestamp = () => new Date().getTime().toString();

function toDate(dateOrString) {
	return typeof dateOrString === 'string' ? new Date(dateOrString) : dateOrString;
}

export function getDateNextYear() {
	return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
}
