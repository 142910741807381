import { useState, useEffect, useMemo, useCallback } from 'react';
import {
	startConsentChangedListener,
	addConsentChangedListener,
	removeConsentChangedListener,
} from '../helpers/onConsentChanged';
import { getConsentedCategories } from '../helpers/consent';

export default function useConsent(consentCategory) {
	const [categories, setCategories] = useState(getConsentedCategories());

	const consentCallback = useCallback(() => {
		setCategories(getConsentedCategories());
	}, []);

	useEffect(() => {
		(async () => {
			await startConsentChangedListener();
			addConsentChangedListener(consentCallback);
		})();
		return () => removeConsentChangedListener(consentCallback);
	}, [consentCallback]);

	return useMemo(
		() => categories.some((c) => c === consentCategory),
		[categories, consentCategory]
	);
}
