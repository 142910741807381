import { generatePath } from 'react-router';
import { DEFAULT_RATEPLAN_SLUG, PAYMENT_ORIGIN } from '../constants/Payment';
import { ROUTE_ACTIVATE_PLAN } from '../constants/Routes';
import { getConfig } from '../apiConfig';

export const isAbsolute = (url) => {
	return new RegExp('^(?:[a-z]+:)?//', 'i').test(url);
};

export const getRateplanUrl = (plan = DEFAULT_RATEPLAN_SLUG, actiecode) => {
	let redirectUrl = generatePath(ROUTE_ACTIVATE_PLAN, { plan });
	if (actiecode) redirectUrl += `?actiecode=${actiecode}`;
	return redirectUrl;
};

export const removeDomain = (str) => {
	if (!str || typeof str !== 'string') {
		return null;
	}
	return str.replace(/(https?:)?\/\/[^/]+/i, '');
};

export const removeQueryString = (str) => {
	if (!str || typeof str !== 'string') {
		return null;
	}

	return str.split('?')[0];
};

export const cleanMalformedQueryString = (str) => {
	// Context: External systems, like SalesForce, sometimes wrongly append analytics query parameters with a "?" instead of a "&".
	// If a "?" is used while the URL already contains a query parameter, it's seen as part of this query parameter.
	return removeQueryString(str);
};

export const host = () => {
	if (__DEBUG__ && __CLIENT__) return `${window.location.protocol}//${window.location.host}`;
	else return getConfig().host;
};

export const getPaymentOrigin = (origin) => {
	if (origin && Object.values(PAYMENT_ORIGIN).includes(origin)) return origin;
	else return PAYMENT_ORIGIN.PPE_EMAIL;
};
