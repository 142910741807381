import { session } from './storage';
import { FEATURE_FLAG_BROWSER_VALUES } from '../constants/WebStorage';

export const isFeatureFlagSessionEnabled = (key) => {
	const featureFlag = session.get(key);
	return featureFlag === FEATURE_FLAG_BROWSER_VALUES.ON;
};

export function getFeatureFlagsArray(flagCollection) {
	return flagCollection.reduce(
		(acc, item) => [...acc, { key: item.key, value: item.isEnabled ? 'enabled' : 'disabled' }],
		[]
	);
}

export function getExperimentsArray(expirementsCollection) {
	return expirementsCollection.reduce(
		(acc, item) => [...acc, { key: item.key, value: item.variantKey }],
		[]
	);
}
