let config = {};

export const setConfig = (options) => {
	Object.assign(config, options);
};

/** Use this if you are sure you won't run into race conditions */
export const getConfig = () => {
	return config;
};
