import { combineReducers } from 'redux';
import configureStore, { initialReducers } from '../common/configureStore';
import { dummyReducer } from './helpers/redux';

const initialState = typeof window !== 'undefined' ? window.__INITIAL_STATE__ : {};

const store = configureStore(initialState);

export const addReducer = (key, newReducer) => {
	if (__SERVER__ || (key in initialReducers && initialReducers[key] !== dummyReducer)) return;
	initialReducers[key] = newReducer;
	store.replaceReducer(combineReducers(initialReducers));
};

export default store;
