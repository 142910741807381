import React from 'react';
import Error from '../components/common/error/Error';
import Spinner from '../components/common/spinner/Spinner';

export function key(value) {
	return typeof value === 'string' ? value.replace(/\W+/g, '') : '';
}

export function stateRender({ isPending, isError, onSuccess, onPending, spinnerModifierClass }) {
	if (isError) {
		return typeof isError === 'function' ? isError() : <Error />;
	}

	if (isPending) {
		return onPending ? (
			onPending()
		) : (
			<Spinner spinnerModifierClass={spinnerModifierClass} data-test="react-helper-spinner" />
		);
	}

	return onSuccess();
}
