import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, generatePath, useLocation, matchPath } from 'react-router';
import { hasValidVideolandCookie } from '../helpers/authentication';
import { getTransactionState, getTransactionPlanSlug } from '../helpers/state';
import { TRANSACTION_PROPERTIES, TRANSACTION_STATUS_VALUES } from '../constants/Payment';
import {
	ROUTE_ACTIVATE_PLAN_STATUS,
	ROUTE_ERROR,
	ROUTE_LOGOUT,
	ROUTE_MESSAGES_RESUME_PENDING_PAYMENT,
} from '../constants/Routes';
import { getAccountClient } from '../api/accountService';
import { reportCustomError } from '../helpers/realUserMonitoring';
import queryPurchaseAndRedemptionStatus from '../graphql/queryPurchaseAndRedemptionStatus.graphql';
import { TRANSACTION_ID_SESSION } from '../constants/WebStorage';
import { session } from '../helpers/storage';
import { PARAM_NAMES } from '../constants/Checkout';
import useSearchParams from './useSearchParams';

const { IN_PROGRESS } = TRANSACTION_STATUS_VALUES;
const { VOUCHERCODE } = PARAM_NAMES;

const videolandAccountApiClient = getAccountClient();

export function useRedirectIfTransactionInProgress() {
	const location = useLocation();
	const history = useHistory();
	const isLoggedIn = hasValidVideolandCookie();
	const isOnPollingPath = !!matchPath(location.pathname, ROUTE_ACTIVATE_PLAN_STATUS);
	const isOnResumePendingPaymentPath = !!matchPath(
		location.pathname,
		ROUTE_MESSAGES_RESUME_PENDING_PAYMENT
	);
	const skip =
		!isLoggedIn ||
		isOnPollingPath ||
		isOnResumePendingPaymentPath ||
		location.pathname === ROUTE_LOGOUT;
	const { getSearchParam } = useSearchParams();

	const voucherCodeQueryParam = getSearchParam(VOUCHERCODE);

	const { data, error, loading } = useQuery(queryPurchaseAndRedemptionStatus, {
		client: videolandAccountApiClient,
		fetchPolicy: 'no-cache',
		skip,
	});

	useEffect(() => {
		if (data && !loading) {
			handleIfInProgress({ data, history, voucherCodeQueryParam });
		}

		if (error) {
			reportCustomError({
				name: 'purchase_redemption_status',
				error: error,
				message: 'initial check',
			});
			history.replace(ROUTE_ERROR);
		}
	}, [data, error, history, voucherCodeQueryParam, loading]);

	return {
		loading,
	};
}

function handleIfInProgress({ data, history, voucherCodeQueryParam }) {
	const { purchaseState, voucherRedemptionState } = getTransactionState(data);
	const { VOUCHER_REDEMPTION } = TRANSACTION_PROPERTIES;
	let planSlug;
	let transactionMethod;

	if (purchaseState.status === IN_PROGRESS) {
		if (location.pathname != ROUTE_LOGOUT) {
			window.location.href = purchaseState.returnUrl;
		}
	}

	if (voucherRedemptionState.status === IN_PROGRESS) {
		planSlug = getTransactionPlanSlug(voucherRedemptionState);
		transactionMethod = VOUCHER_REDEMPTION.METHOD_SLUG.URL;
	}

	if (planSlug && transactionMethod) {
		const transactionId = session.get(TRANSACTION_ID_SESSION);
		history.replace(
			generatePath(ROUTE_ACTIVATE_PLAN_STATUS, { plan: planSlug, transactionMethod }) +
				`?transactionId=${transactionId}&actiecode=${voucherCodeQueryParam}`
		);
	}
}
