import { GIGYA_INVALID_ARGUMENT } from '../constants/ErrorTypes';
import { reportCustomError } from '../helpers/realUserMonitoring';

const normalizeIsError = ({ result }) => {
	result.errorCode = parseInt(result.errorCode || 0, 10);
	result.isError = result.errorCode !== 0;

	// 200009 from Gigya means 'The accounts have been linked successfully.' so it is not really an error.
	if (result.errorCode === 200009) {
		result.isError = false;
	}
	return result;
};

const fetch = ({ api, method, params }) => {
	return new Promise((resolve, reject) => {
		try {
			window.gigya[api][method]({
				...params,
				callback: (result) => {
					result = normalizeIsError({ api, method, result });
					if (result.isError) {
						return reject(result);
					}
					resolve(result);
				},
			});
		} catch (error) {
			reportCustomError({ name: 'gigya_execution_error', error });
			// When script is blocked by a plugin like Ghostery, a TypeError is thrown
			// GIGYA_INVALID_ARGUMENT translates into a third party cookies blocked error
			if (error.name === 'TypeError') {
				reject({ errorCode: GIGYA_INVALID_ARGUMENT });
			}
		}
	});
};

export default ({ body }, successCallback, errorCallback) => {
	const { api, method, params } = body;
	return fetch({ api, method, params }).then(successCallback).catch(errorCallback);
};
