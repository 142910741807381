import Cookies from 'universal-cookie';

const cookiesContext = new Cookies('');

export const session = {
	set(key, value) {
		if (__CLIENT__) sessionStorage.setItem(key, value);
	},

	get(key) {
		return __CLIENT__ ? sessionStorage.getItem(key) : null;
	},

	remove(key) {
		if (__CLIENT__) sessionStorage.removeItem(key);
	},
};

export const cookie = {
	set(key, value, options) {
		cookiesContext.set(key, value, options);
	},

	get(key) {
		return cookiesContext.get(key);
	},

	getAll() {
		return cookiesContext.getAll();
	},

	remove(key, options) {
		cookiesContext.remove(key, options);
	},
};

export const localStorageClient = {
	get(key) {
		if (__CLIENT__) {
			if (window.localStorage && window.localStorage.getItem) {
				return window.localStorage.getItem(key);
			} else {
				// eslint-disable-next-line no-console
				console.error('Local storage is not available in this browser');
			}
		}
		return null;
	},
	set(key, value) {
		if (__CLIENT__) {
			if (window.localStorage && window.localStorage.setItem) {
				return window.localStorage.setItem(key, value);
			} else {
				// eslint-disable-next-line no-console
				console.error('Local storage is not available in this browser');
			}
		}
		return null;
	},
};
