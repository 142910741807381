import React from 'react';
import { isAllowedToEnter } from '../helpers/authentication';
import { Route } from 'react-router-dom';
import { parseQueryString } from '../helpers/path';
import { hasValidVideolandCookie } from '../helpers/authentication';
import { redirectToRateplan } from '../helpers/redirect';
import OnboardingContainer from '../components/onboarding/Onboarding.container';
import { overwriteRedirectUrl } from '../actions/authenticationActions';
import store from '../../common/store';
import '../components/onboarding/Onboarding.scss';
import Layout from '../components/layout/Layout';
import {
	ROUTE_ALBERT_HEIJN,
	ROUTE_DUTCH_FILM_FESTIVAL,
	ROUTE_EFTELING,
	ROUTE_EMAIL_VERIFIED,
	ROUTE_NPL,
	ROUTE_TIER_OPTIONS,
	ROUTE_TMOBILE_THUIS,
	ROUTE_VRIENDENLOTERIJ,
	ROUTE_VRIENDEN_VAN_AMSTEL,
	ROUTE_YOUNG_CAPITAL,
	ROUTE_ZIGGO,
} from '../constants/Routes';
import { matchPath } from 'react-router';

export default ({ component: Component, onEnter = [], path, exact, location, ...restProps }) => {
	const search = location.search
		? location.search
		: location.pathname.substring(location.pathname.indexOf('?') + 1);
	const isOnFixedActionRoute = matchPath(location.pathname, {
		path: [ROUTE_TIER_OPTIONS, ROUTE_EMAIL_VERIFIED],
		exact: true,
		strict: false,
	});
	const softLandingPage = !!matchPath(location.pathname, [
		ROUTE_VRIENDENLOTERIJ,
		ROUTE_ALBERT_HEIJN,
		ROUTE_DUTCH_FILM_FESTIVAL,
		ROUTE_TMOBILE_THUIS,
		ROUTE_NPL,
		ROUTE_YOUNG_CAPITAL,
		ROUTE_ZIGGO,
		ROUTE_VRIENDEN_VAN_AMSTEL,
		ROUTE_EFTELING,
	]);

	const query = parseQueryString(search);

	// forward winback users to the proper rateplan
	if (hasValidVideolandCookie() && query.variant) {
		redirectToRateplan(query.variant);
		return null;
	}

	if (Object.keys(query).length) {
		store.dispatch(overwriteRedirectUrl(query));
	}

	return (
		<Route
			path={path}
			exact={exact}
			render={(props) => {
				const { history } = props;
				if (!isAllowedToEnter(onEnter, history)) return null;

				return (
					<Layout
						hideFooter={props.hideFooter}
						hasFixedAction={isOnFixedActionRoute}
						softLandingPage={softLandingPage}
					>
						<OnboardingContainer path={path} component={Component} {...props} {...restProps} />
					</Layout>
				);
			}}
		/>
	);
};
