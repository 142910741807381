import { cookie } from './storage';
import _ from 'lodash';
import jwt_decode from 'jwt-decode';
import { ROUTE_CONNECT, ROUTE_LOGIN, ROUTE_NOT_FOUND } from '../constants/Routes';
import { template } from '../helpers/text';
import { getConfig } from '../apiConfig';
import { gigyaGetAccountInfo } from '../actions/authenticationActions';
import store from '../store';

export const hasValidVideolandCookie = () => {
	const cookieTokenValue = cookie.get('vlId');

	if (!cookieTokenValue) {
		return false;
	}

	if (window.gigya?.cypressMock) {
		return true;
	}

	try {
		const decodedTokenValue = jwt_decode(cookieTokenValue);
		const { issuer } = getConfig();

		const isValidToken = decodedTokenValue.iss === issuer;
		// && decodedTokenValue.exp > Math.round(Date.now() / 1000);

		if (isValidToken) {
			return true;
		}
	} catch (error) {
		//console.error(error.message);
	}

	return false;
};

export function requireAuthenticationBedrock(nextState, replaceState) {
	if (__CLIENT__) {
		store
			.dispatch(gigyaGetAccountInfo())
			.then(() => {
				return true;
			})
			.catch(() => {
				replaceState({ pathname: ROUTE_LOGIN });
				return false;
			});
	}
	return true;
}

export function requireAuthentication(nextState, replaceState) {
	if (__CLIENT__) {
		const { pathname } = nextState.location;

		if (!hasValidVideolandCookie()) {
			const redirectToLoginPaths = [ROUTE_CONNECT];
			let loginPath = ROUTE_LOGIN;

			if (_.includes(redirectToLoginPaths, pathname)) {
				loginPath += '/?redirectUrl=${redirectUrl}';
			}

			const redirectPath = template(loginPath, {
				redirectUrl: encodeURIComponent(window.location.href),
			});
			replaceState({ pathname: redirectPath });
			return false;
		}
	}
	return true;
}

export function redirect404IfUnauthenticated(nextState, replaceState) {
	if (__CLIENT__) {
		if (!hasValidVideolandCookie()) {
			replaceState({ pathname: ROUTE_NOT_FOUND });
			return false;
		}
	}
	return true;
}

export function isAllowedToEnter(onEnter, history) {
	// If any of the `onEnter` functions returns `false`, this function returns `false`
	const onEnters = _.castArray(onEnter);
	const reducer = (result, fn) => result && fn(history, history.push) !== false;
	return onEnters.reduce(reducer, true);
}
