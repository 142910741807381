import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Spinner.scss';

const Spinner = ({ size = 'large', strokeWidth = '4', spinnerModifierClass }) => (
	<section
		className={cx('spinner', 'spinner-rotate', `spinner-${size}`, spinnerModifierClass)}
		role="progressbar"
		aria-busy="true"
	>
		<svg viewBox="0 0 64 64">
			<defs key="spinner_defs">
				<linearGradient id="sGD" gradientUnits="userSpaceOnUse" x1="55" y1="46" x2="2" y2="46">
					<stop offset="0.1" className="stop1" key="1" />
					<stop offset="1" className="stop2" key="2" />
				</linearGradient>
			</defs>
			<g key="spinner_g" strokeWidth={strokeWidth} strokeLinecap="round" fill="none">
				<path stroke="url(#sGD)" d="M4,32 c0,15,12,28,28,28c8,0,16-4,21-9" />
				<path d="M60,32 C60,16,47.464,4,32,4S4,16,4,32" />
			</g>
		</svg>
	</section>
);

Spinner.propTypes = {
	size: PropTypes.string,
	strokeWidth: PropTypes.string,
	spinnerModifierClass: PropTypes.string,
};

export default Spinner;
