import React from 'react';
import loadable from '@loadable/component';
import { addReducer } from '../../../../common/store';

const LoginComponent = loadable(() => import('./ForgotPassword.container'));

export const ForgotPassword = (props) => {
	import('redux-form').then(({ reducer }) => addReducer('form', reducer));

	return <LoginComponent {...props} />;
};
