import _ from 'lodash';
import { MINUTE, SECOND } from './constants/Time';
import { getConfig } from './apiConfig';

const host = __HOST__;
export const cloudimageHost = 'https://ehn7zwe.cloudimg.io/v7';

export const APP_LOCATION_IOS = 'https://itunes.apple.com/nl/app/id888975587';
export const APP_LOCATION_ANDROID = 'http://play.google.com/store/apps/details?id=nl.rtl.videoland';
export const HELP_URL = 'https://hulp.videoland.com';
export const CONDITIONS_URL = `${HELP_URL}/article/gebruikersvoorwaarden-videoland`;
export const PRIVACY_URL = `${HELP_URL}/article/privacy-en-cookiestatement-videoland`;
export const AUTH_COOKIE = 'vlId';
export const AUTOLOGIN_COOKIE_BR = 'autologin';
export const AUTH_DOMAIN = '.videoland.com';

const { external } = __CONFIG__;
const endpoints = Object.assign(..._.values(__CONFIG__.endpoints));

export const GTM_ID = 'GTM-KFVNG2';

export const TTL_SHORT = 1.5 * SECOND;
export const TTL_MEDIUM = MINUTE;
export const TTL_LONG = 5 * MINUTE;

export const PENDING_DELAY = 250;

export const PROFILE_LIMIT = 5;

const imageSizes = {
	poster: [
		'',
		'60x90',
		'127x190',
		'166x248',
		'179x270',
		'270x404',
		'277x415',
		'355x530',
		'544x804',
	],
	square: ['', '300x300'],
	still: [
		'',
		'127x72',
		'165x93',
		'240x135',
		'355x200',
		'450x254',
		'720x405',
		'1265x712',
		'1920x1080',
	],
	tile: ['', '260x142', '520x285', '1040x570'],
};

const defaultSizes = {
	poster: imageSizes.poster[4],
	square: imageSizes.square[1],
	still: imageSizes.still[4],
	tile: imageSizes.tile[2],
};

export function getEndpointUrl(endpoint, replacements = {}) {
	let path = endpoints[endpoint];
	for (let key in replacements) {
		const replacement = replacements[key] == null ? '' : replacements[key];
		path = path.replace('${' + key + '}', replacement);
	}
	return path;
}

export const getWebHost = () => host;

export function getExternalUrl(endpoint, replacements) {
	const { host } = getConfig();

	let path = external[endpoint];
	for (let key in replacements) {
		const replacement = replacements[key] == null ? '' : replacements[key];
		path = path.replace('${' + key + '}', replacement);
	}

	return `${host}${path}`;
}

export const getImageUrl = (filePath) => {
	const { statics_location } = getConfig();
	return `${statics_location}${filePath}`;
};

export function createImagePath(imagePath, format, type = 'poster') {
	const size = _.get(imageSizes, `${type}.${format}`, defaultSizes[type]);
	const replaceString = format === '0' ? '_[format]' : '[format]';
	return imagePath ? imagePath.replace(replaceString, size) : '';
}

export const getGigyaUrl = () => {
	const { gigya_base, gigya_key } = getConfig();
	return `${gigya_base}${gigya_key}`;
};
export const staticUrl = () => {
	const { statics_location } = getConfig();
	return `${statics_location}`;
};
