import { createScript } from '../../client/scriptloader';
import { getGigyaUrl } from '../config';
import { ROUTE_THIRD_PARTY_COOKIES } from '../constants/Routes';
import { SECOND } from '../constants/Time';
import { reportCustomError } from '../helpers/realUserMonitoring';
import history from '../history';

let gigyaLoader = null;
let isCallbackCalled = false;

export async function waitForGigyaReady(options = {}) {
	if (window?.gigya) {
		return Promise.resolve();
	}

	if (gigyaLoader) {
		return gigyaLoader;
	}

	// see: https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/417f6b5e70b21014bbc5a10ce4041860.html?locale=en-US#ongigyaserviceready
	gigyaLoader = new Promise((resolve, reject) => {
		window.onGigyaServiceReady = () => {
			if (!window?.gigya) {
				reportCustomError({ name: 'gigya_callback_error', error: window.gigya });
				return reject();
			}

			isCallbackCalled = true;
			return resolve();
		};
	});

	loadGigya(options);

	return gigyaLoader;
}

// Gigya config to disable the default generated UI's
const GIGYA_CONFIGURATION = {
	defaultRegScreenSet: '',
	defaultMobileRegScreenSet: '',
};

function loadGigya({ onCreateScriptError }) {
	window.__gigyaConf = {
		...GIGYA_CONFIGURATION,
	};

	setTimeout(() => {
		manuallyCallGigyaCallback();
	}, 5 * SECOND);

	return createScript({
		src: getGigyaUrl(),
		skipAwaitOneTrust: true,
		external: true,
		consentCategory: 'C0001',
		onError: () => {
			onGigyaLoadError();
			onCreateScriptError();
		},
	});
}

function manuallyCallGigyaCallback() {
	// When onGigyaServiceReady is never called
	if (!isCallbackCalled && window.onGigyaServiceReady) {
		reportCustomError({ name: 'gigya_manual_callback', error: window.gigya });
		window.onGigyaServiceReady();
	}
}

function onGigyaLoadError(error) {
	reportCustomError({ name: 'gigya_load_error', error });
	history.replace(ROUTE_THIRD_PARTY_COOKIES);
}
