import { TIER_IDS } from './Tiers';

const TierPaidSubtitle = 'Onbeperkt toegang tot alle series films en programma’s';

export const TierPresentations = {
	[TIER_IDS.FREE]: {
		title: 'TV Gemist',
		subtitle: 'Kijk tot 7 dagen je favoriete RTL programma’s terug',
		features: [
			{
				icon: 'times',
				description: 'Met reclame',
				description_no_icon: 'Met reclame',
			},
			{
				icon: 'users',
				description: '1 scherm tegelijk',
				description_no_icon: '1 scherm tegelijk',
			},
			{
				icon: 'times',
				description: 'Geen Download-to-go',
				description_no_icon: 'Geen download-to-go',
			},
			{
				description_no_icon: 'Geen Live tv',
			},
		],
	},
	[TIER_IDS.ESSENTIAL]: {
		title: 'Basis',
		subtitle: TierPaidSubtitle,
		features: [
			{
				icon: 'times',
				description: 'Met reclame',
				description_no_icon: 'Met reclame',
			},
			{
				icon: 'users',
				description: '1 scherm tegelijk',
				description_no_icon: '1 scherm tegelijk',
			},
			{
				icon: 'times',
				description: 'Geen Download-to-go',
				description_no_icon: 'Geen download-to-go',
			},
			{
				description_no_icon: 'Live tv',
			},
		],
	},
	[TIER_IDS.STANDARD]: {
		title: 'Plus',
		subtitle: TierPaidSubtitle,
		features: [
			{
				icon: 'checkmark',
				description: 'reclamevrij',
				description_no_icon: 'reclamevrij',
			},
			{
				icon: 'users',
				description: '2 schermen tegelijk',
				description_no_icon: '2 schermen tegelijk',
			},
			{
				icon: 'checkmark',
				description: 'Download-to-go',
				description_no_icon: 'Download-to-go',
			},
			{
				description_no_icon: 'Live tv',
			},
		],
	},
	[TIER_IDS.PREMIUM]: {
		title: 'Premium',
		subtitle: TierPaidSubtitle,
		features: [
			{
				icon: 'checkmark',
				description: 'reclamevrij',
				description_no_icon: 'reclamevrij',
			},
			{
				icon: 'users',
				description: '4 schermen tegelijk',
				description_no_icon: '4 schermen tegelijk',
			},
			{
				icon: 'checkmark',
				description: 'Download-to-go',
				description_no_icon: 'Download-to-go',
			},
			{
				description_no_icon: 'Live tv',
			},
		],
	},
	[TIER_IDS.LEGACY]: {
		title: 'Legacy',
		subtitle: TierPaidSubtitle,
		features: [
			{
				icon: 'checkmark',
				description: 'reclamevrij',
				description_no_icon: 'reclamevrij',
			},
			{
				icon: 'users',
				description: '4 schermen tegelijk',
				description_no_icon: '4 schermen tegelijk',
			},
			{
				icon: 'checkmark',
				description: 'Download-to-go',
				description_no_icon: 'Download-to-go',
				description_list_item: 'Live tv',
			},
			{
				description_no_icon: 'Live tv',
			},
		],
	},
};

export const TierPresentationsB = {
	[TIER_IDS.FREE]: {
		title: 'TV Gemist',
		subtitle: 'Kijk tot 7 dagen je favoriete RTL programma’s terug',
		label: 'Met reclame',
		features: [
			{
				description: 'Onbeperkt toegang tot alle series, films en programma’s',
				feature: false,
			},
			{
				description: 'Schermen tegelijk',
				feature: '1',
			},
			{
				description: 'Download-to-go',
				feature: false,
			},
			{
				description: 'Live TV',
				feature: false,
			},
		],
	},
	[TIER_IDS.ESSENTIAL]: {
		title: 'Basis',
		subtitle: TierPaidSubtitle,
		label: 'Met reclame',
		features: [
			{
				description: 'Onbeperkt toegang tot alle series, films en programma’s',
				feature: true,
			},
			{
				description: 'Schermen tegelijk',
				feature: '1',
			},
			{
				description: 'Download-to-go',
				feature: false,
			},
			{
				description: 'Live TV',
				feature: true,
			},
		],
	},
	[TIER_IDS.STANDARD]: {
		title: 'Plus',
		subtitle: TierPaidSubtitle,
		label: 'Meest gekozen',
		features: [
			{
				description: 'Onbeperkt toegang tot alle series, films en programma’s',
				feature: true,
			},
			{
				description: 'Schermen tegelijk',
				feature: '2',
			},
			{
				description: 'Download-to-go',
				feature: true,
			},
			{
				description: 'Live TV',
				feature: true,
			},
		],
	},
	[TIER_IDS.PREMIUM]: {
		title: 'Premium',
		subtitle: TierPaidSubtitle,
		label: '',
		features: [
			{
				description: 'Onbeperkt toegang tot alle series, films en programma’s',
				feature: true,
			},
			{
				description: 'Schermen tegelijk',
				feature: '4',
			},
			{
				description: 'Download-to-go',
				feature: true,
			},
			{
				description: 'Live TV',
				feature: true,
			},
		],
	},
};

export const TierPresentationsOneLine = {
	[TIER_IDS.ESSENTIAL]: 'Basis (met reclame, 1 scherm tegelijk, geen download-to-go)',
	[TIER_IDS.STANDARD]: 'Plus (reclamevrij, 2 schermen tegelijk, download-to-go)',
	[TIER_IDS.PREMIUM]: 'Premium (reclamevrij, 4 schermen tegelijk, download-to-go)',
	[TIER_IDS.LEGACY]: 'Legacy (reclamevrij, 4 schermen tegelijk, download-to-go)',
	[TIER_IDS.FREE]:
		'TV Gemist (RTL programma’s tot 7 dagen terugkijken, met reclame, zonder betaald abonnement)',
};
