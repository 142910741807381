import React from 'react';
import Helmet from 'react-helmet';
import { VIDEOLAND, VIDEOLAND_DESCRIPTION } from '../../../constants/Copy';
import { getWebHost } from '../../../config';
import { PAGES } from '../../../constants/Navigation';

export default ({ path }) => (
	<Helmet titleTemplate="%s" {...createHelmetProps(path, getWebHost())} />
);

export function createHelmetProps(path, host) {
	const page = PAGES[path];
	const title = page?.title ? `${page.title} - ${VIDEOLAND}` : VIDEOLAND;

	return {
		title,
		link: getLinkData(page),
		meta: getMetaData(page, host),
	};
}

function getLinkData(page) {
	const linkData = [];
	if (page?.canonicalUrl) {
		linkData.push({ rel: 'canonical', href: page.canonicalUrl });
	}
	return linkData;
}

function getMetaData(page, host) {
	const pageDescription = page?.pageDescription || VIDEOLAND_DESCRIPTION;
	const metaData = [
		{ name: 'description', content: pageDescription },
		{ name: 'twitter:description', property: 'og:description', content: pageDescription },
		{ name: 'twitter:title', property: 'og:title', content: VIDEOLAND },
		{ name: 'twitter:url', property: 'og:url', content: host },
	];

	if (page?.noIndex) {
		metaData.push({ name: 'robots', content: 'noindex' });
	}

	return metaData;
}
