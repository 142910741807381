import React from 'react';
import { HELP_URL } from '../../../config';
import {
	ONBOARDING_HELP_TITLE,
	ONBOARDING_HELP_SUBTITLE,
	ONBOARDING_HELP_HELP_BUTTON,
	ONBOARDING_HELP_FEEDBACK_BUTTON,
} from '../../../constants/Copy';
import './OnboardingSidebar.scss';
import '../../common/buttons/onboarding/OnboardingButton.scss';
import Button, { BUTTON_KIND_LIGHT } from '../../common/buttons/onboarding/OnboardingButton';
import HelpList from './help-list/HelpList';
import { useDispatch, useSelector } from 'react-redux';
import { openUsabilla } from '../../../helpers/usabilla';
import Sidebar from '../../sidebar/Sidebar.loadable';
import { toggleHelp } from '../../../actions/helpActions';
import { toggleTieringSidebar } from '../../../actions/tierActions';
import Heading from '../../common/heading/Heading';
import { BLACK_FRIDAY_TERMS } from '../../../constants/TermsAndConditions';

export default function OnboardingSidebar() {
	const dispatch = useDispatch();
	const { isOpen } = useSelector((state) => state.help);
	const isTieringOpen = useSelector((state) => state.tiers.sidebarOpen);

	const handleFeedbackClick = () => {
		dispatch(toggleHelp(false));
		openUsabilla();
	};

	const handleHelpClick = () => {
		window.open(HELP_URL);
	};

	return (
		<div className="help-sidebar">
			<button
				aria-label="Help openen"
				className="help-sidebar__open-sidebar"
				onClick={() => {
					dispatch(toggleHelp(true));
				}}
			/>
			{isOpen && (
				<Sidebar isOpen={isOpen} onClose={() => dispatch(toggleHelp(false))}>
					<h2 className="help-sidebar__title">{ONBOARDING_HELP_TITLE}</h2>
					<p className="help-sidebar__subtitle">{ONBOARDING_HELP_SUBTITLE}</p>
					<HelpList />
					<Button testId="onboarding-help-button" onClick={handleHelpClick}>
						{ONBOARDING_HELP_HELP_BUTTON}
					</Button>
					<Button
						testId="onboarding-feedback-button"
						kind={BUTTON_KIND_LIGHT}
						onClick={handleFeedbackClick}
					>
						{ONBOARDING_HELP_FEEDBACK_BUTTON}
					</Button>
				</Sidebar>
			)}
			{isTieringOpen && (
				<div className="tier-options-sidebar">
					<Sidebar isOpen={isTieringOpen} onClose={() => dispatch(toggleTieringSidebar(false))}>
						<Heading align="left" size="small">
							{BLACK_FRIDAY_TERMS.description}
						</Heading>
						<ul className="tier-options-sidebar__list">
							{BLACK_FRIDAY_TERMS.text.map((paragraph, id) => (
								<li key={id}>
									{/* eslint-disable-next-line react/no-danger*/}
									<p dangerouslySetInnerHTML={{ __html: paragraph }} />
								</li>
							))}
						</ul>
					</Sidebar>
				</div>
			)}
		</div>
	);
}
