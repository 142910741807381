/** This function tracks certain actions from users
 * which might trigger a usabilla feedback dialog */
export const setCustomVariable = (name) => {
	setVariable(name, true);
};

export const openUsabilla = () => {
	if (isUsabillaLoaded()) {
		window.usabilla_live('click');
	}
};

const setVariable = (key, value) => {
	if (isUsabillaLoaded()) {
		window.usabilla_live('data', {
			custom: { [key]: value },
		});
	}
};

export const isUsabillaLoaded = () => {
	return __CLIENT__ && typeof window.usabilla_live === 'function';
};
