// This breakpoint is the point where the header icons collapse with the search and profile icon,
// can also be found in _variables.scss.
const BREAKPOINT_HEADER_WIDTH = 750;
const userAgent = __CLIENT__ ? navigator.userAgent : 'node';

export const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);

export const isSafari11 = isSafari && /Version\/11/.test(userAgent);

export const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);

export const isIE = /MSIE/.test(userAgent) || /Trident\/7\./.test(userAgent);

export const isEdge = /Edge\//.test(userAgent);

export const isWideScreen = __CLIENT__
	? document.documentElement.clientWidth >= BREAKPOINT_HEADER_WIDTH
	: false;

export const isFirefox = /Firefox\//.test(userAgent) && !/Seamonkey\//.test(userAgent);

export const requestAnimationFrame = __CLIENT__ ? window.requestAnimationFrame : (fn) => fn();

export const isElementInContainerView = (el, container) => {
	if (!el || !container) return false;

	const containerRect = container.getBoundingClientRect();
	const elementRect = el.getBoundingClientRect();

	const relativePosition = {
		top: elementRect.top - containerRect.top,
		right: elementRect.right - containerRect.right,
		bottom: elementRect.bottom - containerRect.bottom,
		left: elementRect.left - containerRect.left,
	};

	return (
		relativePosition.top >= 0 &&
		relativePosition.left >= 0 &&
		relativePosition.bottom <= 0 &&
		relativePosition.right <= 0
	);
};
