import React from 'react';
import { useSelector } from 'react-redux';
import { Usabilla } from './usabilla/Usabilla';
import { hasSubscription } from '../../helpers/state';
import { showConsentPopup } from '../../../client/onetrust';
import {
	FOOTER_COMPANY_DETAILS,
	FOOTER_CONTACT,
	FOOTER_COOKIE,
	FOOTER_PRIVACY,
	FOOTER_REQ,
} from '../../constants/Copy';
import './Footer.scss';
import { ROUTE_COMPANY_INFORMATION } from '../../constants/Routes';
import { Link } from 'react-router-dom';
import { PRIVACY_URL, CONDITIONS_URL, HELP_URL } from '../../config';
import {
	EVT_FOOTER,
	EVT_NAVIGATION_CATEGORY,
	EVT_ELEMENT_INTERACTION,
	EVT_ELEMENT_TYPE_BUTTON,
	EVT_TEXT_LINK,
} from '../../constants/SnowplowEvents';
import { initiateTrackStructEvent } from '../analytics/Snowplow';
import cx from 'classnames';

function Footer({ hideFooter, fullWidthFooter, hasFixedAction }) {
	const activeSubscription = useSelector((state) => hasSubscription(state));

	function trackFooterEvent(label) {
		initiateTrackStructEvent({
			category: EVT_NAVIGATION_CATEGORY,
			action: EVT_FOOTER,
			label: label,

			rtlElementContext: {
				elementInteraction: EVT_ELEMENT_INTERACTION,
				elementGroup: EVT_FOOTER,
				elementType: EVT_ELEMENT_TYPE_BUTTON,
				elementName: EVT_FOOTER,
				elementLabel: label,
				elementStyle: EVT_TEXT_LINK,
			},
		});
	}

	return (
		<footer
			className={cx('footer', {
				['footer--fullwidth']: fullWidthFooter,
				['footer--fixed-button']: hasFixedAction,
			})}
		>
			{!activeSubscription || hideFooter ? null : (
				<div className="footer-usabilla">
					<Usabilla />
				</div>
			)}
			<div className="footer-nav" data-test="footer-nav">
				<ul className="footer-nav__list">
					<li className="footer-nav__item">&copy; {new Date().getFullYear()} Videoland</li>
					<li className="footer-nav__item">
						<Link
							to={ROUTE_COMPANY_INFORMATION}
							className="footer-nav__link"
							data-test="footer-company-info"
							onClick={() => {
								trackFooterEvent(FOOTER_COMPANY_DETAILS);
							}}
						>
							{FOOTER_COMPANY_DETAILS}
						</Link>
					</li>
					<li className="footer-nav__item">
						<a
							href={`${HELP_URL}/article/hoe-kan-ik-contact-opnemen`}
							className="footer-nav__link"
							target="_blank"
							rel="noopener"
							onClick={() => {
								trackFooterEvent(FOOTER_CONTACT);
							}}
						>
							{FOOTER_CONTACT}
						</a>
					</li>
					<li className="footer-nav__item">
						<a
							href={CONDITIONS_URL}
							className="footer-nav__link"
							target="_blank"
							rel="noopener"
							onClick={() => {
								trackFooterEvent(FOOTER_REQ);
							}}
						>
							{FOOTER_REQ}
						</a>
					</li>
					<li className="footer-nav__item">
						<a
							href={PRIVACY_URL}
							className="footer-nav__link"
							target="_blank"
							rel="noopener"
							onClick={() => {
								trackFooterEvent(FOOTER_PRIVACY);
							}}
						>
							{FOOTER_PRIVACY}
						</a>
					</li>
					<li className="footer-nav__item">
						<button
							onClick={() => {
								showConsentPopup();
								trackFooterEvent(FOOTER_COOKIE);
							}}
							className="footer-nav__link"
							target="_blank"
							rel="noopener"
						>
							{FOOTER_COOKIE}
						</button>
					</li>
				</ul>
			</div>
		</footer>
	);
}

export default Footer;
