import React from 'react';
import loadable from '@loadable/component';
import { addReducer } from '../../../common/store';

const ContactComponent = loadable(() => import('./Contact.container'));

export const Contact = (props) => {
	import('../../reducers/contact/contact').then((reducer) =>
		addReducer('contact', reducer.default)
	);

	return <ContactComponent {...props} />;
};
