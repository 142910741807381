import { getEndpointUrl } from '../config';
import { ANALYTICS_PUSH, GEO_CHECK, COOKIE_CONFIG } from '../constants/ActionTypes';
import queryIsOnboardingGeoBlocked from '../graphql/queryIsOnboardingGeoBlocked.graphql';

export const analyticsPush = (payload, eventType) => ({
	type: eventType ? `${ANALYTICS_PUSH}_${eventType}` : ANALYTICS_PUSH,
	payload,
});

export const geoCheck = () => (dispatch) =>
	dispatch({
		type: GEO_CHECK,
		payload: {
			client: 'accountService',
			url: getEndpointUrl('graphql_path'),
			query: queryIsOnboardingGeoBlocked,
		},
	});

export const fetchCookieConfig = () => (dispatch) =>
	dispatch({
		type: COOKIE_CONFIG,
		payload: {
			client: 'basic',
			url: 'https://cookiewet.rtl.nl/data/settings.json',
			method: 'GET',
		},
	});
