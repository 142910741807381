import { TIERS_FETCH, TIERS_SIDEBAR_TOGGLE } from '../constants/ActionTypes';
import { getEndpointUrl, TTL_LONG } from '../config';
import queryFetchTiers from '../graphql/queryFetchTiers.graphql';

export const fetchTiers = () => {
	return {
		type: TIERS_FETCH,
		payload: {
			client: 'subscriptionService',
			url: getEndpointUrl('graphql_path'),
			query: queryFetchTiers,
		},
		meta: {
			cache: TTL_LONG,
			pending: { delay: false },
		},
	};
};

export const toggleTieringSidebar = (isOpen) => ({
	type: TIERS_SIDEBAR_TOGGLE,
	payload: {
		isOpen,
	},
});
