import { waitForOneTrustScriptReady } from '../../client/onetrust';

const listeners = [];
let isListening = false;

export async function startConsentChangedListener() {
	if (isListening) {
		return;
	}

	await waitForOneTrustScriptReady();

	window.Optanon?.OnConsentChanged(() => {
		runCallbacks();
	});

	isListening = true;
}

export function addConsentChangedListener(listener) {
	listeners.push(listener);
}

export function removeConsentChangedListener(listener) {
	const cbIndex = listeners.findIndex((cb) => cb === listener);
	if (cbIndex > 0) {
		listeners.splice(cbIndex, 1);
	}
}

function runCallbacks() {
	listeners.forEach((cb) => cb());
}
