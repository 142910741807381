import { supportsTouchEvents } from '../common/helpers/device';

export const detectTouchEvents = () => {
	if (document) {
		const body = document.body;
		if (supportsTouchEvents) {
			body.classList.add('has-touch');
		}
	}
};
