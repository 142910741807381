export const MONTH_NAMES = [
	'januari',
	'februari',
	'maart',
	'april',
	'mei',
	'juni',
	'juli',
	'augustus',
	'september',
	'oktober',
	'november',
	'december',
];

export const DATE_INPUT_NAMES = {
	day: 'Dag',
	month: 'Maand',
	year: 'Jaar',
};
