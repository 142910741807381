import { reportError } from '../helpers/realUserMonitoring';
import { DEFAULT_ENABLED_CATEGORIES } from '../constants/OptanonConsentCategory';

const CONSENT_TIMEOUT = 1500;

export const CREATE_SCRIPT_TYPES = {
	JAVASCRIPT: 'application/javascript',
	TEXT: 'text/plain',
};

export default async function getConsentData() {
	let dataResolver = null;

	if (window.__tcfapi) {
		dataResolver = createConsentResolver('__tcfapi', 'getTCData', 'tcString');
	}

	// This is a fallback attempt when v2 consent api is not found
	// It can be removed once vl has moved away from the onetrust cmp v1 api.
	if (window.__cmp && !window.__tcfapi) {
		dataResolver = createConsentResolver('__cmp', 'getConsentData', 'consentData');
	}

	if (!dataResolver) {
		return Promise.resolve({});
	}

	const timeout = new Promise((resolve) => {
		const id = setTimeout(() => {
			clearTimeout(id);
			resolve({});
		}, CONSENT_TIMEOUT);
	});

	return Promise.race([dataResolver, timeout]);
}

function createConsentResolver(api, command, consentStringProperty) {
	const isTcfApi = api === '__tcfapi';

	return new Promise((resolve) => {
		window[api](command, isTcfApi ? 2 : null, (result, success) => {
			if (!success) {
				reportError('Failed to resolve consent string.');
				resolve({});
				return;
			}

			resolve({
				consapl: result.gdprApplies ? '1' : '0',
				consstat: result[consentStringProperty],
			});
		});
	});
}

export function hasConsentForCategory(category) {
	return getConsentedCategories().some((c) => c === category);
}

export function getConsentedCategories() {
	return getEnabledCategories(getOptanonCategoryString());
}

function getEnabledCategories(categoryList) {
	return categoryList.split(',').filter((c) => c);
}

/**
 * @param optanonConsentCategory {OptanonConsentCategory}
 * @returns {boolean}
 */
export function isCategoryEnabledByDefault(optanonConsentCategory) {
	return DEFAULT_ENABLED_CATEGORIES.some((category) => category === optanonConsentCategory);
}

/**
 * @param optanonConsentCategory {OptanonConsentCategory}
 * @returns {string}
 */
export const getScriptTypeBasedOnConsentCategory = (optanonConsentCategory) =>
	getScriptTypeBasedOnConsentCategoryFactory(optanonConsentCategory, getOptanonCategoryString());

export function getScriptTypeBasedOnConsentCategoryFactory(
	optanonConsentCategory,
	OptanonActiveGroups
) {
	if (!optanonConsentCategory) return CREATE_SCRIPT_TYPES.JAVASCRIPT;

	if (
		isCategoryEnabledByDefault(optanonConsentCategory) ||
		OptanonActiveGroups.indexOf(optanonConsentCategory) > -1
	) {
		return CREATE_SCRIPT_TYPES.JAVASCRIPT;
	}

	return CREATE_SCRIPT_TYPES.TEXT;
}

function getOptanonCategoryString() {
	return (__CLIENT__ && window.OptanonActiveGroups) || '';
}

export function getConsentClassName(category) {
	if (!category) {
		return null;
	}
	return `optanon-category-${category}`;
}
