import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, generatePath } from 'react-router';
import FullPageMessage from '../../common/fullPageMessage/FullPageMessage';
import { reportCustomError } from '../../../helpers/realUserMonitoring';
import { getImageUrl } from '../../../config';
import { ROUTE_PARTNER_ACTIVATE, ROUTE_ERROR } from '../../../constants/Routes';
import { stateRender } from '../../../helpers/react';

const PartnerLanding = ({ plan, planSlug, entitlementId, isParentLoading, isParentError }) => {
	const history = useHistory();

	return stateRender({
		isPending: isParentLoading,
		isError: isParentError,
		onSuccess: renderLanding,
	});

	function renderLanding() {
		const copy = plan?.copy?.landing?.default;

		if (!copy) {
			reportCustomError({
				name: 'third_party',
				error: planSlug,
				message: 'softlanding is missing copy',
			});
			history.replace({ pathname: ROUTE_ERROR });
		}

		const image = getImageUrl(copy?.image);

		const button = {
			onClick: handleClick,
			text: copy.primaryButton,
		};

		const textLink = {
			onClick: handleClick,
			text: copy.textLink,
		};

		function handleClick() {
			const ActivatePartnerUrl = generatePath(ROUTE_PARTNER_ACTIVATE, { plan: planSlug });
			return history.push(`${ActivatePartnerUrl}?entitlementId=${entitlementId}`);
		}

		function descriptionArrayToText(description) {
			return description.reduce((paragraph, line) => {
				if (!paragraph) return line;
				return `${paragraph} \n ${line}`;
			}, '');
		}

		return (
			<FullPageMessage
				image={image}
				title={copy.title}
				description={descriptionArrayToText(copy.description)}
				button={button}
				textLink={textLink}
			/>
		);
	}
};

PartnerLanding.propTypes = {
	planSlug: PropTypes.string.isRequired,
	plan: PropTypes.object.isRequired,
	entitlementId: PropTypes.string.isRequired,
};

export default PartnerLanding;
