export const OperationNames = {
	USER_LOGIN: 'user_login',
	CANCEL_SUBSCRIPTION: 'cancel_subscription',
	REACTIVATE_SUBSCRIPTION: 'reactivate_subscription',
	CHANGE_TIER: 'change_tier',
	NOTIFICATION: 'notification',
};

export const SpanNames = {
	LOGIN_ATTEMPT: 'login_attempt',
	LOGIN_AUTHENTICATED: 'login_authenticated',
	LOGIN_REDIRECT: 'login_redirect',
	FETCH_SUBSCRIPTION: 'fetch_subscription',
	SUBSCRIPTION_CANCEL_ATTEMPT: 'subscription_cancel_attempt',
	SUBSCRIPTION_CANCELED: 'subscription_cancelled',
	FETCH_SUBSCRIPTION_TIER: 'fetch_subscription_tier',
	SUBSCRIPTION_REACTIVATE_ATTEMPT: 'subscription_reactivate_attempt',
	CHANGE_TIER_ATTEMPT: 'change_tier_attempt',
};

export const SpanCustomAttributesNames = {
	LOGIN_EVENT: 'login.event',
};

export const SpanCustomAttributesValues = {
	LOGIN_REDIRECT_INTERNAL: 'redirect: internal',
	LOGIN_REDIRECT_NO_SUBSCRIPTION: 'redirect: no subscription',
	LOGIN_REDIRECT_BEDROCK: 'redirect: Bedrock',
};
