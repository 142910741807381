import { GTM_ID, staticUrl } from '../common/config';
import { waitForOneTrustScriptReady } from './onetrust';
import getConsentData, {
	CREATE_SCRIPT_TYPES,
	getScriptTypeBasedOnConsentCategory,
	getConsentClassName,
} from '../common/helpers/consent';
import { OptanonConsentCategory } from '../common/constants/OptanonConsentCategory';
import { reportError } from '../common/helpers/realUserMonitoring';

const loadedScripts = {};

/**
 * @param script
 * @returns {Promise<*>}
 */
export const createScript = async (script) => {
	const { src, external, consentCategory, async, onError, skipAwaitOneTrust = false } = script;

	if (!skipAwaitOneTrust) {
		await waitForOneTrustScriptReady();
	}

	const url = external ? src : staticUrl() + '/' + src;
	const scriptType = getScriptTypeBasedOnConsentCategory(consentCategory);

	if (!(url in loadedScripts)) {
		const scriptElement = document.createElement('script');
		scriptElement.className = getConsentClassName(consentCategory);
		scriptElement.type = scriptType;
		scriptElement.src = url;
		if (async) {
			scriptElement.async = true;
		}

		if (scriptType === CREATE_SCRIPT_TYPES.JAVASCRIPT) {
			loadedScripts[url] = createScriptLoadPromise(scriptElement, onError);

			document.body.appendChild(scriptElement);

			return loadedScripts[url];
		} else {
			if (__DEBUG__) {
				// eslint-disable-next-line no-console
				console.log(`Create script: Didn't load blocked script ${src}`);
			}

			document.body.appendChild(scriptElement);

			return Promise.resolve();
		}
	}

	async function createScriptLoadPromise(scriptElement, onError) {
		return new Promise((resolve, reject) => {
			scriptElement.onload = resolve;
			scriptElement.onerror = (error) => {
				onError && onError(error);
				scriptLoadError(error);
			};

			function scriptLoadError(error) {
				delete loadedScripts[url];
				reportError(error);
				reject(`Couldn't load script ${src} for unknown reason`);
			}
		});
	}
};

export const installGoogleTagManager = () => {
	if (__ANALYTICS__) {
		return createScript({
			src: `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`,
			external: true,
			consentCategory: OptanonConsentCategory.FUNCTIONALLY_REQUIRED,
		});
	}
};

export const installFocusVisible = () => {
	return createScript({
		src: 'focus-visible.min.js',
		external: false,
		consentCategory: OptanonConsentCategory.FUNCTIONALLY_REQUIRED,
	});
};

export const installUsabilla = () => {
	return createScript({
		src: 'usabilla.js',
		external: false,
		consentCategory: OptanonConsentCategory.ADVERTISING_AND_TRACKING,
	});
};

export const installSmartX = async () => {
	await waitForOneTrustScriptReady();
	const { consstat } = await getConsentData();
	const rnd = Math.round(Math.random() * 10000000);
	if (consstat) {
		createScript({
			src: `https://ad.sxp.smartclip.net/ads?type=dyn&plc=106864&sz=1x1&consent=${consstat}&rnd=${rnd}`,
			external: true,
			consentCategory: OptanonConsentCategory.ADVERTISING_AND_TRACKING,
			async: true,
		});
	}
};
