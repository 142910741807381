import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { EVT_BALLOON } from '../../../constants/EventTypes';
import {
	USABILLA_CONDITIONS,
	USABILLA_CUSTOMER_SERVICE,
	USABILLA_SUGGESTIONS,
} from '../../../constants/Copy';
import { openUsabilla } from '../../../helpers/usabilla';
import useConsent from '../../../hooks/useConsent';
import { analyticsPush } from '../../../actions/applicationActions';
import { useDispatch } from 'react-redux';
import { OptanonConsentCategory } from '../../../constants/OptanonConsentCategory';

import './Usabilla.scss';
import { CONDITIONS_URL } from '../../../config';

function UsabillaModal({ isModalOpen, toggleModal }) {
	const hasUsabillaConsent = useConsent(OptanonConsentCategory.ADVERTISING_AND_TRACKING);

	if (!isModalOpen) return null;

	const showUsabilla = () => {
		openUsabilla();
		toggleModal();
	};

	return (
		<span className="usabilla__modal" onMouseLeave={toggleModal}>
			<span>
				<p key="billa-p1" className="usabilla__item">
					<a
						className="vl-button vl-button--brand"
						target="_blank"
						href="http://hulp.videoland.com"
						rel="noopener"
					>
						{USABILLA_CUSTOMER_SERVICE}
					</a>
				</p>
				{hasUsabillaConsent ? (
					<p key="billa-p3" className="usabilla__item">
						<button className="vl-button vl-button--secondary usabilla-link" onClick={showUsabilla}>
							{USABILLA_SUGGESTIONS}
						</button>
					</p>
				) : null}
				<p key="billa-p4" className="usabilla__item">
					<a className="usabilla-link" target="_blank" href={CONDITIONS_URL} rel="noopener">
						{USABILLA_CONDITIONS}
					</a>
				</p>
			</span>
		</span>
	);
}

export function Usabilla() {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const dispatch = useDispatch();

	const toggleModal = () => {
		if (!isModalOpen) {
			dispatch(
				analyticsPush({
					event: EVT_BALLOON,
				})
			);
		}
		setIsModalOpen(!isModalOpen);
	};

	return (
		<span>
			<UsabillaModal isModalOpen={isModalOpen} toggleModal={toggleModal} />
			<CSSTransition appear in className="usabilla" classNames="usabilla" timeout={400}>
				<div>
					<button onClick={() => toggleModal()} className="usabilla__balloon">
						Hulp
					</button>
				</div>
			</CSSTransition>
		</span>
	);
}
