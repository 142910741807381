import reduce from '../../helpers/redux';
import { LOCATION_CHANGE } from 'connected-react-router';
import { COOKIE_CONFIG } from '../../constants/ActionTypes';
import { getWebHost } from '../../config';
import { parseQueryString } from '../../helpers/path';

let isFirstLocationChange = true;
const host = getWebHost();

function getHref({ pathname, search, hash }) {
	return `${host}${pathname || ''}${search || ''}${hash || ''}`;
}

export const initialState = {
	device: {},
	path: {
		current: null,
		previous: null,
		query: {},
	},
	cookieConfig: {
		domains: [],
		version: 0,
	},
	search: {
		entryPoint: null,
	},
};

const actionsMap = {
	[LOCATION_CHANGE]: (state, action) => {
		const { path } = state;
		const { pathname, search, hash } = action.payload.location;
		const previous =
			!isFirstLocationChange && path.current !== path.previous ? path.current : path.previous;
		const href = getHref({ pathname, search, hash });
		const { section } = {};

		isFirstLocationChange = false;

		return {
			path: {
				previous,
				current: pathname,
				href,
				query: parseQueryString(search),
				hash: parseQueryString(hash),
				section,
			},
		};
	},
	[COOKIE_CONFIG + '_SUCCESS']: (state, action) => ({
		cookieConfig: action.payload,
	}),
};

export default reduce(initialState, actionsMap);
