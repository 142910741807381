import { API_ACTIONS } from '../constants/ReduxActions';

export default function createReduce(initialState, actions) {
	return function reducer(state = initialState, action) {
		const reduce = actions[action.type];

		if (!reduce) {
			return state;
		}

		return {
			...state,
			...reduce(state, action),
		};
	};
}

// Placeholder for "lazy" reducers, so Redux won't complain about missing store keys,
// and we can always assume they exist (e.g. references in connectors).
export const dummyReducer = (initialState) => initialState || {};

export const defaultState = Object.freeze({ error: false, pending: false, success: false });
export const pendingState = Object.freeze({ error: false, pending: true, success: false });
export const errorState = Object.freeze({ error: true, pending: false, success: false });
export const successState = Object.freeze({ error: false, pending: false, success: true });

export const isDefaultState = ({ error, pending, success }) =>
	error === false && pending === false && success === false;

export const dispatchApiSuccess = ({ dispatch, type, action, payload, cached }) => {
	const successAction = {
		type: `${type}${API_ACTIONS.SUCCESS}`,
		payload,
		originalPayload: action.payload,
		originalMeta: action.meta,
		cached,
	};
	dispatch(successAction);
	return successAction;
};

export const dispatchApiError = ({ dispatch, type, action, err }) => {
	const errorAction = {
		type: `${type}${API_ACTIONS.ERROR}`,
		message: err.message || err.errorMessage || (typeof err === 'string' ? err : 'Unknown'),
		error: err.body || err || null,
		status: (err && err.response && err.response.status) || 0,
		payload: action.payload,
		meta: action.meta,
		stack: err.stack,
	};
	if (__DEBUG__) {
		if (err.stack) console.log(err.stack); // eslint-disable-line
	}
	dispatch(errorAction);
	return errorAction;
};
