import React from 'react';
import loadable from '@loadable/component';
import { addReducer } from '../../../../store';

const ChangeTierComponent = loadable(() => import('./ChangeTier.container'));

export const ChangeTier = (props) => {
	import('../../../../reducers/subscription/subscription').then((reducer) => {
		addReducer('subscription', reducer.default);
	});

	return <ChangeTierComponent {...props} />;
};
