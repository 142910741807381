import { useDispatch, useSelector } from 'react-redux';
import { useVideolandAccountState } from '../../hooks/useVideolandAccountState';
import { useEffect, useState } from 'react';
import { analyticsPush } from '../../actions/applicationActions';
import { EVT_INITIAL_PAGE, EVT_SESSION_START } from '../../constants/EventTypes';
import { getFeatureFlagsArray, isFeatureFlagSessionEnabled } from '../../helpers/featureFlag';
import { FEATURE_FLAG_OPEN_INVOICES_KEY } from '../../constants/WebStorage';
import { FLAG_KEYS } from '../../constants/FlagrABTest';
import { useEvaluateFeatureFlags } from '../../hooks/useEvaluateFeatureFlag';
import { reportError } from '../../helpers/realUserMonitoring';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';

export const AnalyticsStarter = createAnalyticsStarter(
	useDispatch,
	useVideolandAccountState,
	useSelector,
	isFeatureFlagSessionEnabled,
	useEvaluateFeatureFlags,
	useGetUserInfo
);

const flagKeyValues = Object.values(FLAG_KEYS);

export function createAnalyticsStarter(
	useDispatch,
	useVideolandAccountState,
	useSelector,
	isFeatureFlagSessionEnabled,
	useEvaluateFeatureFlags,
	useGetUserInfo
) {
	return function AnalyticsStarter({ children }) {
		const dispatch = useDispatch();

		const {
			isLoading: isVlStateIsLoading,
			isPristine: isVlStateIsPristine,
			isAuthenticatedUser,
		} = useVideolandAccountState();

		const {
			loading: isUserLoading,
			user: { id: userId },
		} = useGetUserInfo();
		const { results: filteredExperimentFlags } = useEvaluateFeatureFlags(flagKeyValues);

		const tier = useSelector((state) => state.subscription.tier);

		const [isPageLoadDispatched, setPageLoadDispatched] = useState(false);
		const [isSessionStartDispatched, setIsSessionStartDispatched] = useState(false);

		useEffect(() => {
			const isSessionLoading = isUserLoading || tier.pending || tier.pristine;

			try {
				if (isAuthenticatedUser && !isSessionStartDispatched && !isSessionLoading) {
					dispatch(
						analyticsPush(
							{
								tier: tier?.data?.currentPlan?.planDescription?.tierId,
								userId,
							},
							EVT_SESSION_START
						)
					);
					setIsSessionStartDispatched(true);
				}
			} catch (err) {
				reportError(err);
			}
		}, [dispatch, isSessionStartDispatched, isAuthenticatedUser, isUserLoading, userId, tier]);

		useEffect(() => {
			const isPageLoading = isVlStateIsPristine || isVlStateIsLoading;

			// Wait for session start dispatch if user is authenticated to ensure session start comes before page load (requirement from Web Analyst)
			const isPageLoadWaitingOnSession = isAuthenticatedUser && !isSessionStartDispatched;
			try {
				if (!isPageLoadDispatched && !isPageLoading && !isPageLoadWaitingOnSession) {
					const featureFlags = getFeatureFlagsArray([
						{
							isEnabled: isFeatureFlagSessionEnabled(FEATURE_FLAG_OPEN_INVOICES_KEY),
							key: FEATURE_FLAG_OPEN_INVOICES_KEY,
						},
					]);

					dispatch(
						analyticsPush(
							{
								featureFlags,
								experimentFlags: filteredExperimentFlags,
							},
							EVT_INITIAL_PAGE
						)
					);
					setPageLoadDispatched(true);
				}
			} catch (err) {
				reportError(err);
			}
		}, [
			filteredExperimentFlags,
			dispatch,
			isPageLoadDispatched,
			isVlStateIsLoading,
			isVlStateIsPristine,
			isAuthenticatedUser,
			isSessionStartDispatched,
		]);

		return children;
	};
}
