import _ from 'lodash';
import { getConfig } from '../apiConfig';

const defaultHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
	'Cache-Control': 'no-cache',
	'Pragma': 'no-cache',
	'videoland-platform': 'videoland.com',
};

const getBody = (method, data, requestHeaders) => {
	if (method === 'GET') return undefined;
	if (requestHeaders['Content-Type'].toLowerCase() === 'application/json') {
		return data ? JSON.stringify(data) : undefined;
	}
	return data;
};

const parseResponse = (response) => {
	if (~[202, 204, 205].indexOf(response.status)) {
		return null;
	} else if (response.status >= 200 && response.status < 300) {
		return response.json();
	} else {
		return response.json().then((body) => {
			let errorMessage;
			let errorBody;
			if (typeof _.get(body, 'error.message.message') === 'string') {
				try {
					errorBody = JSON.parse(body.error.message.message);
					errorMessage = errorBody.errorMessage;
				} catch (err) {
					errorBody = body.error.message.message;
					errorMessage = 'Unknown';
				}
			} else if (typeof _.get(body, 'error.message.error') === 'string') {
				errorBody = body.error;
				errorMessage = body.error.message.error;
			} else {
				errorBody = body.error;
				errorMessage = body.error.message;
			}
			const err = new Error(errorMessage);
			err.body = errorBody;
			err.response = response;
			throw err;
		});
	}
};

export default (
	{ url, method = 'GET', body, headers = {}, credentials = 'include' },
	successCallback,
	errorCallback
) => {
	const { v1_base } = getConfig();
	const requestHeaders = Object.assign({}, defaultHeaders, headers);
	return fetch(v1_base + url, {
		method,
		body: getBody(method, body, requestHeaders),
		headers: requestHeaders,
		credentials,
	})
		.then(parseResponse)
		.then(successCallback)
		.catch(errorCallback);
};
