import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getImageUrl } from '../../config';
import { deviceInformation } from '../../helpers/device';
import { hasSubscription as hasSubscriptionState } from '../../helpers/state';
import { listenForSpacebar } from '../../helpers/accessibility';

import './Header.scss';

const BedrockHeader = () => {
	const { current: pathnameProp } = useSelector((state) => state.application.path);
	const hasSubscription = useSelector((state) => hasSubscriptionState(state));
	const [pathname, setPathname] = useState(false);

	if (pathnameProp === pathname) {
		setPathname(pathnameProp);
	}

	const menuButton = hasSubscription ? (
		<>
			<a
				tabIndex="-1"
				href="https://v2.videoland.com/mijn-account"
				className="header-menu__settings header-menu__action"
				onFocus={() => window.addEventListener('keydown', listenForSpacebar, true)}
			>
				<i className="icon icon-settings" />
			</a>
		</>
	) : null;

	const logo = !deviceInformation.isMobile
		? getImageUrl('/images/vl-logo.svg')
		: getImageUrl('/images/vl-logo-no-rtl.svg');

	return (
		<div>
			<div className="main-header-container main-header-container-bedrock">
				<header className="main-header" role="banner">
					<nav className="navigation">
						<a href="https://v2.videoland.com" className="main-logo--link">
							<img src={logo} className="main-logo" alt="Videoland logo" />
						</a>
						<div className="main-header__right">{menuButton}</div>
					</nav>
				</header>
			</div>
		</div>
	);
};

export default withRouter(BedrockHeader);
