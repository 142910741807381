import React from 'react';
import { getImageUrl } from '../../../config';

import './Header.scss';

export default () => {
	const logo = getImageUrl('/images/vl-logo.svg');
	return (
		<div className="onboarding-header">
			<img className="onboarding-header__logo" src={logo} alt="Videoland logo" />
		</div>
	);
};
