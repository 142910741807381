import reduce, { defaultState, errorState, pendingState, successState } from '../../helpers/redux';
import { TIERS_FETCH, TIERS_SIDEBAR_TOGGLE } from '../../constants/ActionTypes';

export const initialState = {
	...defaultState,
	collection: [],
	userSelectedTier: null,
	sidebarOpen: false,
};

const actionsMap = {
	[TIERS_FETCH + '_PENDING']: (state) => ({ ...state, ...pendingState }),
	[TIERS_FETCH + '_ERROR']: (state) => ({ ...state, ...errorState }),
	[TIERS_FETCH + '_SUCCESS']: (state, { payload }) => {
		return {
			...successState,
			collection: payload.data.tiers,
		};
	},
	[TIERS_SIDEBAR_TOGGLE]: (state, { payload }) => ({
		...state,
		sidebarOpen: payload.isOpen,
	}),
};

export default reduce(initialState, actionsMap);
