import { isFirefox, isChrome } from '../../common/helpers/browser';

/**
 * This is a modified version of https://github.com/brigade/delayed-scroll-restoration-polyfill
 *
 * - replaced Number.isFinite() needed for ie11,
 * - fallback for window.scrollY with window.pageYOffset, needed for ie11
 * - removed horizontal scroll restoration as we don't need this overhead
 * - simplified query for document height (as we use quite modern browsers)
 * - removed measurements for scrollbars as that is only needed if we have horizontal scrollbars on the body
 * - only apply when native scrollRestoration behavior is not supported (and fix the firefox / chrome false positives)
 */

const history = window.history;

if (history.pushState && (!('scrollRestoration' in history) || isFirefox || isChrome)) {
	const SCROLL_RESTORATION_TIMEOUT_MS = 3000;
	const TRY_TO_SCROLL_INTERVAL_MS = 50;

	const originalPushState = history.pushState.bind(history);
	const originalReplaceState = history.replaceState.bind(history);

	let timeoutHandle = null;

	// Store current scroll position in current state when navigating away.
	history.pushState = function () {
		const __scrollY = 'scrollY' in window ? window.scrollY : window.pageYOffset;
		const newStateOfCurrentPage = {
			...history.state,
			__scrollY,
		};

		originalReplaceState(newStateOfCurrentPage, '');
		originalPushState(...arguments);
	};

	// Make sure we don't throw away scroll position when calling "replaceState".
	history.replaceState = function (state, ...otherArgs) {
		const newState = {
			__scrollY: history.state && history.state.__scrollY,
			...state,
		};

		originalReplaceState(newState, ...otherArgs);
	};

	// Try to scroll to the scrollTarget, but only if we can actually scroll there. Otherwise keep trying until we
	// time out, then scroll as far as we can.
	const tryToScrollTo = (scrollTarget) => {
		clearTimeout(timeoutHandle);

		if (
			document.body.scrollHeight - window.innerHeight >= scrollTarget.y ||
			Date.now() > scrollTarget.latestTimeToTry
		) {
			window.scrollTo(0, scrollTarget.y);
		} else {
			timeoutHandle = setTimeout(tryToScrollTo, TRY_TO_SCROLL_INTERVAL_MS, scrollTarget);
		}
	};

	// Try scrolling to the previous scroll position on popstate
	const onPopState = () => {
		const state = history.state;

		if (state && state.__scrollY > 0) {
			setTimeout(tryToScrollTo, 0, {
				y: state.__scrollY,
				latestTimeToTry: Date.now() + SCROLL_RESTORATION_TIMEOUT_MS,
			});
		}
	};

	window.addEventListener('popstate', onPopState, true);
}
