import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Loader from '../../loader/Loader';

import './OnboardingButton.scss';

export const BUTTON_KIND_SECOND = 'second';
export const BUTTON_KIND_LIGHT = 'light';
export const BUTTON_KIND_TEXT = 'text';

const Button = ({
	kind,
	inline,
	adaptive,
	loading,
	disabled,
	onClick,
	children,
	className,
	history,
	testId,
	testValue,
	type,
	href,
	name,
	tabIndex,
}) => {
	const classNames = cx({
		'onboarding-button': kind !== BUTTON_KIND_TEXT,
		[`onboarding-button--${kind}`]: !!kind,
		[className]: !!className,
		'onboarding-button--disabled': disabled,
		'onboarding-button--loading': loading,
		'onboarding-button--inline': inline,
		'onboarding-button--adaptive': adaptive,
	});

	const clickHandler = (onClick) => (evt) => {
		if (!onClick) return;
		return typeof onClick === 'function' ? onClick(evt) : history.push(onClick);
	};

	const BaseElement = href ? 'a' : 'button';
	const baseElementType = href ? null : type;

	return (
		<BaseElement
			tabIndex={tabIndex}
			name={name}
			type={baseElementType}
			className={classNames}
			onClick={clickHandler(onClick)}
			data-test={testId}
			data-value={testValue}
			href={href}
			disabled={disabled}
		>
			{loading && <Loader />}
			<span>{children}</span>
		</BaseElement>
	);
};

Button.propTypes = {
	type: PropTypes.oneOf(['button', 'submit']),
	kind: PropTypes.string,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	testId: PropTypes.string,
	testValue: PropTypes.string,
	href: PropTypes.string,
	name: PropTypes.string,
};

Button.defaultProps = {
	type: 'button',
	disabled: false,
	loading: false,
	inline: false,
	adaptive: false,
	kind: null,
	className: null,
	testId: null,
	testValue: null,
	onClick: null,
	name: null,
};

export default withRouter(Button);
