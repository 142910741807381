import { HELP_OPEN, HELP_CLOSE } from '../constants/ActionTypes';

export const openHelp = () => ({
	type: HELP_OPEN,
});

export const closeHelp = () => ({
	type: HELP_CLOSE,
});

export const toggleHelp = (isOpen) => (dispatch) => {
	if (isOpen) {
		return dispatch(openHelp());
	} else {
		return dispatch(closeHelp());
	}
};
