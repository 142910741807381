import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, matchPath } from 'react-router';
import { usePromiseIfMounted } from './usePromiseIfMounted';
import { geoCheck } from '../actions/applicationActions';
import { reportCustomError } from '../helpers/realUserMonitoring';
import {
	ROUTE_ACTIVATED_PLAN,
	ROUTE_ACTIVATE_PLAN,
	ROUTE_ACTIVATE_PLAN_STATUS,
	ROUTE_ACTIVATE_VOUCHER,
	ROUTE_ERROR,
	ROUTE_GEO_CHECK,
} from '../constants/Routes';

export const useOnboardingGeoBlock = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const promiseIfMounted = usePromiseIfMounted();
	const { success, pending, error, data } = useSelector((state) => state.geo);
	const requiresGeoBlockCheck = hasRouteGeoBlockCheck(location.pathname);

	const [isGeoPristine, setIsGeoPristine] = useState(true);

	useEffect(() => {
		if (requiresGeoBlockCheck && isGeoPristine) {
			setIsGeoPristine(false);
			promiseIfMounted(dispatch(geoCheck())).catch((error) => {
				reportCustomError({
					name: 'useOnboardingGeoBlock ',
					error: {
						error,
						data,
						location,
					},
				});

				history.push(ROUTE_ERROR);
			});
		}
	}, [
		requiresGeoBlockCheck,
		data,
		error,
		pending,
		success,
		dispatch,
		history,
		location,
		promiseIfMounted,
		isGeoPristine,
	]);

	useEffect(() => {
		if (!!data?.isOnboardingGeoBlocked && requiresGeoBlockCheck) {
			history.push(ROUTE_GEO_CHECK);
		}
	}, [location, history, data, requiresGeoBlockCheck]);

	return {
		success: !requiresGeoBlockCheck || success,
		pending,
		error,
		isOnboardingGeoBlocked: data?.isOnboardingGeoBlocked,
	};
};

function hasRouteGeoBlockCheck(route) {
	return !!matchPath(route, {
		path: [
			ROUTE_ACTIVATED_PLAN,
			ROUTE_ACTIVATE_PLAN,
			ROUTE_ACTIVATE_PLAN_STATUS,
			ROUTE_ACTIVATE_VOUCHER,
		],
	});
}
