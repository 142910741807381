const onetrustAlertBoxCookieName = 'OptanonAlertBoxClosed';
let otLoader = null;

export async function waitForOneTrustScriptReady() {
	if (!__ANALYTICS__) return Promise.resolve();

	if (otLoader) {
		return otLoader;
	}

	otLoader = new Promise((resolve) => {
		// See static/optanon-awaiter.js
		document.addEventListener('optanonReady', () => {
			resolve();
		});
	});

	return otLoader;
}

export function showConsentPopup() {
	window.Optanon.ToggleInfoDisplay();
}

export function hasClosedConsentPopup() {
	return document.cookie.split(';').some((item) => item.includes(onetrustAlertBoxCookieName));
}
