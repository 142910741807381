import { useQuery } from '@apollo/client';
import queryGetUserInfo from '../graphql/queryGetUserInfo.graphql';
import { getAccountClient } from '../api/accountService';
import { hasValidVideolandCookie } from '../helpers/authentication';
import { useLocation } from 'react-router';
import { ROUTE_LOGOUT } from '../constants/Routes';

const accountClient = getAccountClient();

export function useGetUserInfo() {
	const isLoggedIn = hasValidVideolandCookie();
	const { pathname } = useLocation();

	const props = useQuery(queryGetUserInfo, {
		client: accountClient,
		fetchPolicy: 'no-cache',
		skip: !isLoggedIn || pathname === ROUTE_LOGOUT,
	});

	return {
		...props,
		user: {
			...props?.data?.me?.user,
		},
	};
}
