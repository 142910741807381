import { reducer as form } from 'redux-form';
import application from './application/application';
import contact from './contact/contact';
import help from './help/help';
import subscription from './subscription/subscription';
import authentication from './authentication/authentication';
import register from './register/register';
import geo from './geo/geo';
import payment from './payment/payment';
import tiers from './tiers/tiers';
import notification from './notification/notification';
import { dummyReducer } from '../helpers/redux';

const reducers = {
	authentication,
	application,
	help,
	register,
	geo,
	payment,
	subscription,
	tiers,
	notification,
};

/* I believe some explanation is needed here to clear up what is happening below.
 * The lines below that load a dummyReducer on the client and the real one on the server have to do with lazy-loading.
 * Normally all reducers are 'concatenated' together which, while building/ bundling, will result in to 1 larger (client) bundle.
 * In order to reduce bundle sizes we lazy load some of these bundles (with Loadable). So when we're building the application, the 'client' reducers are bundled together.
 * Due to the fact that the 'dummyReducer' is appointed as the reducer to use, the 'main' bundle output is a lot smaller.
 * Each component that uses Loadable to load a reducer, will bundle the reducer and it component files together. For an example see Meister.loadable.js or Play.loadable.js
 *
 * tl;dr
 * Code below is used to reduce the main bundle size;
 */

if (__CLIENT__) {
	Object.assign(reducers, {
		contact: dummyReducer,
		form: dummyReducer,
		play: dummyReducer,
		player: dummyReducer,
		playerLive: dummyReducer,
		search: dummyReducer,
	});
}

if (__SERVER__) {
	Object.assign(reducers, {
		contact,
		form,
	});
}

export default reducers;
