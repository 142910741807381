import { useEffect, useReducer } from 'react';
import { waitForGigyaReady } from '../helpers/gigya';
import { useIsMounted } from './useIsMounted';

const initialState = { isLoading: true, isReady: false, isError: false };

function reducer(state, action) {
	switch (action.type) {
		case 'ready':
			return { isLoading: false, isReady: true, isError: false };
		case 'error':
			return { isLoading: false, isReady: false, isError: true };
		default:
			throw new Error();
	}
}

export const useGigya = () => {
	const isMounted = useIsMounted();
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		function setupGigyaForCypress() {
			dispatch({ type: 'ready' });
			console.log('CYPRESS | Running with stubbed Gigya', window.gigya);
		}

		function setupGigya() {
			waitForGigyaReady({
				onCreateScriptError: () => {
					dispatch({ type: 'error' });
				},
			})
				.then(() => {
					dispatch({ type: 'ready' });
				})
				.catch(() => {
					dispatch({ type: 'error' });
				});
		}

		if (window.gigya && window.gigya.cypressMock) {
			setupGigyaForCypress();
		} else if (isMounted()) {
			setupGigya();
		}
	}, [isMounted]);

	return state;
};
