import queryString from 'query-string';

const charsRE = /^[a-zA-Z]+$/;
const xssRE = /[<>()]/;

export function parseQueryString(search) {
	const unsafeQuery = queryString.parse(search);
	const query = Object.create(null);
	for (const key in unsafeQuery) {
		if (charsRE.test(key) && !xssRE.test(unsafeQuery[key])) {
			query[key] = unsafeQuery[key];
		}
	}
	return query;
}
