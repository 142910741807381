import { CREDITCARD, IDEAL } from '../constants/Payment';

export const DYNAMIC_FILE_ERRORS = {
	WRONG_FORMAT: `ERR_WRONG_FORMAT`,
	NOT_FOUND: `ERR_NOT_FOUND`,
};

export function getPaymentPlan(slug) {
	return new Promise((resolve, reject) => {
		const importFn = () => import(`../constants/plans/${slug}.json`);
		const postLoad = (result) => {
			const plan = result?.default;
			if (plan) {
				resolve(plan);
			} else {
				reject(DYNAMIC_FILE_ERRORS.WRONG_FORMAT);
			}
		};

		importFn().then(postLoad).catch(createCatchDynamicImportError(reject));
	});
}

export function getSlugByRateplan(rateplanCode) {
	return new Promise((resolve, reject) => {
		const importFn = () => import(`../constants/plans/rateplanToSlugMap.json`);
		const postLoad = (result) => {
			const rateplanToSlugMap = result?.default;
			if (rateplanToSlugMap) {
				const foundSlug = rateplanToSlugMap[rateplanCode];
				if (foundSlug) {
					resolve(foundSlug);
				} else {
					reject(DYNAMIC_FILE_ERRORS.NOT_FOUND);
				}
			} else {
				reject(DYNAMIC_FILE_ERRORS.WRONG_FORMAT);
			}
		};

		importFn().then(postLoad).catch(createCatchDynamicImportError(reject));
	});
}

function createCatchDynamicImportError(reject) {
	return function catchDynamicImportError(e) {
		console.error(e);
		if (e.message.indexOf('Cannot find module') > -1) {
			reject(DYNAMIC_FILE_ERRORS.NOT_FOUND);
		} else {
			reject(e.message);
		}
	};
}

export function createPaymentRequest(paymentMethod, paymentOption, returnUrl, isPurchase = false) {
	return {
		...(paymentMethod?.id === IDEAL && { bankBic: paymentOption.bic }),
		...(paymentMethod?.id === CREDITCARD &&
			isPurchase && { creditCardType: paymentOption.idPurchase }),
		...(paymentMethod?.id === CREDITCARD &&
			!isPurchase && { creditCardType: paymentOption.idChangePaymentDetail }),
		returnUrl,
		type: paymentMethod?.id,
	};
}
