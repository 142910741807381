import _ from 'lodash';
import { PENDING_DELAY } from '../config';
import {
	dispatchApiError as dispatchError,
	dispatchApiSuccess as dispatchSuccess,
} from '../helpers/redux';
import { API_ACTIONS } from '../constants/ReduxActions';

const cache = {};
const currentActionOfType = {};

const hasCache = (action) => {
	return __CLIENT__ && action.meta && action.meta.cache;
};

export default (clients) => () => (dispatch) => (action) => {
	if (!action.payload || (!action.payload.url && !action.payload.client)) {
		return dispatch(action);
	}

	const { type, payload, meta = {} } = action;
	const { pending = {} } = meta;
	const {
		client,
		url,
		method,
		data,
		headers = {},
		credentials,
		query,
		variables,
		mutation,
		fetchPolicy,
	} = payload;

	if (meta) {
		if (meta.cancel === type) {
			currentActionOfType[type] = action;
		}

		if (meta.invalidate && cache[meta.invalidate]) {
			delete cache[meta.invalidate];
		}
	}

	if (hasCache(action) && url in cache) {
		return cache[url]
			.then((successAction) =>
				dispatchSuccess({
					dispatch,
					type,
					action,
					payload: successAction.payload,
					cached: meta.cache,
				})
			)
			.catch((err) =>
				dispatchError({
					dispatch,
					type,
					action,
					err,
				})
			);
	}

	const response = new Promise((resolve, reject) => {
		const pendingAction = {
			...action,
			type: `${type}${API_ACTIONS.PENDING}`,
		};

		let delayId;

		// To avoid showing a spinner the *_PENDING action is not fired when the action takes
		// less than 250ms.
		//
		// To always fire a *_PENDING action add `meta: { pending: { delay: false } }` to your action.
		// To never fire a *_PENDING action add `meta: { pending: false }` to your action.
		if (!pending.disabled) {
			if (__CLIENT__ && (typeof pending.delay === 'undefined' || pending.delay)) {
				delayId = setTimeout(() => dispatch(pendingAction), pending.delay || PENDING_DELAY);
			} else {
				dispatch(pendingAction);
			}
		}

		const request = clients[client] || clients.default;

		return request(
			{
				url,
				method,
				body: data,
				headers,
				credentials,
				query,
				mutation,
				variables,
				fetchPolicy,
			},
			(payload) => {
				if (meta && meta.cancel === type) {
					const currentAction = currentActionOfType[type];
					if (!_.isEqual(currentAction, action)) {
						return reject(
							dispatch({
								...currentAction,
								error: 'Aborted',
								type: `${type}${API_ACTIONS.ABORTED}`,
							})
						);
					}
				}
				clearInterval(delayId);
				return resolve(dispatchSuccess({ dispatch, type, action, payload }));
			},
			(err) => {
				clearInterval(delayId);
				return reject(dispatchError({ dispatch, type, action, err }));
			}
		);
	});

	if (hasCache(action) && !cache[url]) {
		cache[url] = response;
		setTimeout(() => delete cache[url], meta.cache);
	}

	return response;
};
