import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmailVerified from './EmailVerified';
import { fetchTiers, toggleTieringSidebar } from '../../../../actions/tierActions';
import { useHandleEmailVerification } from '../../../../hooks/useHandleEmailVerification';
import { analyticsPush } from '../../../../actions/applicationActions';
import { subscribeNewsletter } from '../../../../actions/profileActions';
import { FLAG_KEYS, FLAG_VARIANTS } from '../../../../constants/FlagrABTest';
import { useEvaluateFeatureFlag } from '../../../../hooks/useEvaluateFeatureFlag';
import {
	FEATURE_FLAG_BROWSER_VALUES,
	FEATURE_FLAG_PRIVACY_STEP_NEWSLETTER,
} from '../../../../constants/WebStorage';
import { useVideolandAccountState } from '../../../../hooks/useVideolandAccountState';

const EmailVerifiedContainer = () => {
	const tierCollection = useSelector((state) => state.tiers.collection || []);
	const isTiersPending = useSelector((state) => state.tiers.pending);
	const isTiersError = useSelector((state) => state.tiers.error);
	const dispatch = useDispatch();
	const gigyaUser = useSelector((state) => state.authentication.gigyaUser);
	const dispatchFetchTiers = () => dispatch(fetchTiers());
	const dispatchAnalyticsPush = (payload, eventType) => dispatch(analyticsPush(payload, eventType));
	const dispatchToggleTieringSidebar = (isOpened) => dispatch(toggleTieringSidebar(isOpened));
	const { value: variantKey } = useEvaluateFeatureFlag(FLAG_KEYS.PRIVACY_STEP_NEWSLETTER);
	const isVariantWithNewsletter = variantKey === FLAG_VARIANTS.VARIANT_A;

	useEffect(() => {
		if (
			gigyaUser &&
			isVariantWithNewsletter &&
			window.localStorage.getItem(FEATURE_FLAG_PRIVACY_STEP_NEWSLETTER) ===
				FEATURE_FLAG_BROWSER_VALUES.ON
		) {
			dispatch(subscribeNewsletter());
			window.localStorage.removeItem(FEATURE_FLAG_PRIVACY_STEP_NEWSLETTER);
		}
	}, [gigyaUser, isVariantWithNewsletter, dispatch]);

	return (
		<EmailVerified
			gigyaUser={gigyaUser}
			tierCollection={tierCollection}
			isTiersPending={isTiersPending}
			isTiersError={isTiersError}
			dispatchFetchTiers={dispatchFetchTiers}
			useHandleEmailVerification={useHandleEmailVerification}
			dispatchAnalyticsPush={dispatchAnalyticsPush}
			toggleTieringSidebar={dispatchToggleTieringSidebar}
			useVideolandAccountState={useVideolandAccountState}
		/>
	);
};

export default EmailVerifiedContainer;
