const isAndroid = (userAgent) => /Android/i.test(userAgent);

const isAppleMobileDevice = (userAgent) => /(iPhone|iPad|iPod)/i.test(userAgent);

const isFirefox = (userAgent) => /Firefox/i.test(userAgent);

export const deviceInformation = __CLIENT__
	? (() => {
			const { userAgent } = navigator;
			const type = isAndroid(userAgent) ? 'android' : isAppleMobileDevice(userAgent) ? 'ios' : null;
			return {
				type,
				isMobile: !!type,
				isFirefox: isFirefox(userAgent),
			};
	  })()
	: {};

export const supportsTouchEvents =
	__CLIENT__ &&
	('ontouchstart' in window || (window.DocumentTouch && document instanceof window.DocumentTouch));
