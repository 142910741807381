import { useSelector } from 'react-redux';
import { getCurrrentPlan } from '../../helpers/state';
import { getTimestamp } from '../../helpers/datetime';
import { isUsabillaLoaded } from '../../helpers/usabilla';
import { reportError } from '../../helpers/realUserMonitoring';
import { getPresentationForTier } from '../../helpers/tiers';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';

/* When allowed (consent) we try and load the Usabilla script and directly after loading we send some
 * custom data to Usabilla so each campaign has this default data present.
 */
function UsabillaStarter() {
	const {
		user: { id },
	} = useGetUserInfo();
	const currentPlan = useSelector((state) => getCurrrentPlan(state));
	const tierId = currentPlan?.planDescription.tierId;

	if (tierId && id && isUsabillaLoaded()) {
		try {
			window.usabilla_live('data', {
				custom: {
					userId: id,
					tier: getPresentationForTier(tierId).title,
					timestampStartSession: getTimestamp(),
				},
			});
		} catch (err) {
			reportError(err);
		}
	}

	return null;
}

export default UsabillaStarter;
